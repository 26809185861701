import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import constants from "../utils/constants";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";
import "../sass/assets/css/style.css";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import Loading from "./Loading";

//estilos del filter
const TextFields = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

//estilos del boton FIlter
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
`;

const Error = styled.div`
  background-color: #ffb3b3;
  color: white;
  text-align: center;
  padding: 3px;
  margin-top: 0px;
  margin-bottom: 5px;
`;
//componente del filtro
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextFields
      id="search"
      type="text"
      placeholder="Filtrar por nombre"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const Roles = () => {
  // Loading
  const [loading, setLoading] = useState(true);

  const ConfirmAlert = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
        Swal.fire("Eliminado!", "El Rol fue Eliminado.", "success");
      }
    });
  };

  const ConfirmAlert2 = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
      customClass: "zindexswall",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeletePrivileges(id);
        Swal.fire("Eliminado!", "El Privilegio fue Eliminado.", "success");
      }
    });
  };

  //Snackbar alerta
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  /* //Snackbar alerta
  const [states, setStates] = React.useState({
    vertical1: "top",
    horizontal1: "center",
    message1: "",
  });

    //Alerta Snackbar
    const { vertical1, horizontal1, message1 } = states;
    const handleClick1 = (newState) => () => {
      setStates({ ...newState });
    }; */

  // Const EDIT
  const [rolID, setID] = useState("");
  const [privilegeID, setPrivilegeID] = useState("");

  const [privilegeID2, setPrivilegeID2] = useState("");

  //Alerta Snackbar
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  const [open, setOpen] = React.useState(false);

  const [open1, setOpen1] = React.useState(false);

  const [open2, setOpen2] = React.useState(false);

  const [open3, setOpen3] = React.useState(false);

  const [openEditPrivilegios, setOpenEditPrivilegios] = React.useState(false);

  //mensaje de crear usuarios
  const [mensage, setMensage] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const [mensage1, setMensage1] = React.useState(false);
  const [openAlert1, setOpenAlert1] = React.useState(false);

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //cerrar alerta al crear
  const handleCloseAlert1 = () => {
    setOpenAlert1(false);
  };

  //Modal formulario abrir/cerrar
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    cleanData();
  };

  //Modal formulario abrir/cerrar Privilegios
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    cleanData1();
  };

  //Modal formulario abrir/cerrar Privilegios
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
    //  cleanData1();
  };

  //Modal formulario abrir/cerrar Privilegios
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => {
    setOpen3(false);
    //  cleanData3();
  };

  //UseState para consultar los roles
  const [roles, setRoles] = useState([]);

  //UseState para formularios
  const [name, setName] = useState("");
  const [read, setRead] = useState("0");
  const [create, setCreate] = useState("0");
  const [update, setUpdate] = useState("0");
  const [deletes, setDeletes] = useState("0");

  const [privilegios, setPrivilegios] = useState([]);

  //UseState para Privilegios
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");

  // eDITAR pRIVILEGE
  const [IDPrivilegio, setIDPrivilegio] = useState("");

  //Set elements on edit ESTATUS
  const setEdit1 = async (role) => {
    handleOpen1();
    setPrivilegeID(role.id);
  };

  //Set elements on edit ESTATUS
  const setEdit2 = async (role) => {
    handleOpen2();
    setPrivilegeID(role.id);
    setPrivilegios(role.privileges);
  };

  //Set elements on edit ESTATUS
  const setEdit3 = async (privilege) => {
    handleOpen3();
    setModel(privilege?.model);
    setRead(privilege?.read);
    setUpdate(privilege?.update);
    setCreate(privilege?.create);
    setDeletes(privilege?.delete);
    setIDPrivilegio(privilege?.role_id);
    setPrivilegeID2(privilege?.id);
  };

  //Crear Privilegio
  const fetchPrivilege = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/privilege/store", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          role_id: privilegeID,
          model: model,
          read: read,
          create: create,
          update: update,
          delete: deletes,
        }),
      });
      const result = await response.json();
      //Alerta al crear rol
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Privilegio se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchRolesRead();
        cleanData1();
        handleClose1();
      } else {
        Swal.fire({
          icon: "error",
          title: "Privilegio ya registrado",
          text: "Intente de nuevo",
        });
        handleClose1();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Consultar Roles
  const fetchRolesRead = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(constants.api + "auth/role", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const result = await response.json();

    if (result?.success) {

      setRoles(result.data);
      if (privilegeID) {
        const privilegiosFilter = result.data.filter(
          (row) => row.id == privilegeID
        );
        setPrivilegios(privilegiosFilter[0].privileges);
      }

    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchRolesRead();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  //set elements on edit users
  const setEdit = async (roles) => {
    handleOpen();
    setName(roles.name);
    setType(roles.type);
    setDescription(roles.description);
    setID(roles.id);
  };

  //limpiar form
  const cleanData = () => {
    setName("");
    setType("");
    setDescription("");
    setID("");
  };

  //limpiar form
  const cleanData1 = () => {
    setRead("0");
    setUpdate("0");
    setModel("");
    setDeletes("0");
    setCreate("0");
  };
  //Delete ROLES
  const onDelete = async (rolID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/role/" + rolID, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const result = await response.json();
      fetchRolesRead();
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Delete PRIVILEGIOS
  const onDeletePrivileges = async (privilegeID2) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/privilege/" + privilegeID2,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchRolesRead();
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Crear Roles
  const fetchRolesCreate = async () => {
    if (name == "") {
      setMensage("Añadir campo Fecha Nombre.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (type == "") {
      setMensage("Añadir campo Tipo.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (description == "") {
      setMensage("Añadir campo Descripción.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/role/store", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          name: name,
          type: type,
          description: description,
        }),
      });
      const result = await response.json();
      //Alerta al crear rol
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Rol se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchRolesRead();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al registrar el Rol",
          text: "Intente de nuevo",
        });
        handleClose();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //PAGINACION
  const columns = [
    {
      name: "Nombre",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Tipo",
      selector: (row) => row.type,
      sortable: true,
    },

    {
      name: "Descripción",
      selector: (row) => row?.description,
      sortable: true,
    },

    {
      name: " Asignar Privilegios",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-success edit-delete-buttons"
          onClick={() => setEdit1(row)}
        >
          <i className="material-icons padding-aling-editdelete">add</i>
        </button>
      ),
    },
    {
      name: "Editar Privilegios",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-warning edit-delete-buttons"
          onClick={() => setEdit2(row)}
        >
          <i className="material-icons padding-aling-editdelete">edit</i>
        </button>
      ),
    },
    {
      name: "Editar Rol",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-info edit-delete-buttons"
          onClick={() => setEdit(row)}
        >
          <i className="material-icons padding-aling-editdelete">edit</i>
        </button>
      ),
    },
    {
      name: "Eliminar",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-danger edit-delete-buttons"
          onClick={() => ConfirmAlert(row.id)}
          value="Eliminar"
        >
          <i className="material-icons padding-aling-editdelete">delete</i>
        </button>
      ),
    },
  ];

  //const para el filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const rolss = roles.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  //SubHeader del filtro
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //Editar Roles
  const fetchEditRol = async () => {
    if (name == "") {
      setMensage("Añadir campo Nombre.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (type == "") {
      setMensage("Añadir campo Tipo.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (description == "") {
      setMensage("Añadir campo Descripción.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/role/" + rolID, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          name: name,
          type: type,
          description: description,
        }),
      });
      const result = await response.json();

      //Alerta de editar usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Rol se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchRolesRead();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar Rol",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //EDITAR PRIVILEGIOS
  const fetchEditPrivileges = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/privilege/" + privilegeID2,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            role_id: privilegeID,
            model: model,
            read: read,
            create: create,
            update: update,
            delete: deletes,
          }),
        }
      );
      const result = await response.json();
      //Alerta de editar el privilegio
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Privilegio se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchRolesRead();
        cleanData();
        handleClose3();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar Privilegio",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //OnChanges
  const handleInputName = (event) => {
    setName(event.target.value);
  };

  const handleInputType = (event) => {
    setType(event.target.value);
  };

  const handleInputDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleInputRead = (event) => {
    setRead(event.target.value);
  };

  const handleInputCreate = (event) => {
    setCreate(event.target.value);
  };

  const handleInputUpdate = (event) => {
    setUpdate(event.target.value);
  };

  const handleInputDeletes = (event) => {
    setDeletes(event.target.value);
  };

  const handleInputModel = (event) => {
    setModel(event.target.value);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="main-panel panel-main">
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
          <div className="container-fluid">
            <div className="navbar-wrapper z-index-card mediaContact">
              <a className="navbar-brand" href="">
                SELFISH Control Roles
              </a>
            </div>
          </div>
        </nav>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">Roles</h4>
                    <p className="card-category button-add-user">
                      <Button
                        type="button"
                        className="btn btn-success buttonaddusers"
                        onClick={handleOpen}
                      >
                        <i className="material-icons padding-aling">add</i>
                        Añadir
                      </Button>
                    </p>
                  </div>

                  <div className="card-body table-responsive">
                    <DataTable
                      columns={columns}
                      data={rolss}
                      noDataComponent={
                        loading ? (
                          <Loading />
                        ) : (
                          <p className="sin-datos">
                            No hay datos para mostrar
                          </p>
                        )
                      }
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      selectableRows
                      persistTableHead
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles responsive-box ">
          <div className="modal-header">
            {rolID ? (
              <h5 className="modal-title" id="exampleModalLabel">
                Editar Rol
              </h5>
            ) : (
              <h5 className="modal-title" id="exampleModalLabel">
                Crear Rol
              </h5>
            )}

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4">
                  <label>Nombre</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="name"
                    required
                    className=" tipoUser"
                    name="name"
                    value={name}
                    onChange={handleInputName}
                  />
                </div>

                <div className="col-md-4">
                  <label>Tipo</label>
                </div>
                <div className="col-md-8">
                  <select
                    type="text"
                    id="type"
                    required
                    className=" tipoUser"
                    name="type"
                    value={type}
                    onChange={handleInputType}
                    multiple={false}

                  >
                    <option value="DEFAULT" disabled>
                      Elije Un Tipo
                    </option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </div>

                <div className="col-md-4">
                  <label>Descripción</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="description"
                    required
                    className=" tipoUser"
                    name="description"
                    value={description}
                    onChange={handleInputDescription}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div id="resultadocrear"></div>
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {rolID ? (
              <button
                onClick={fetchEditRol}
                type="button"
                className="button btn-primary"
                data-dismiss="modal"
                id="actionEditarEmpresa"
              >
                Editar
              </button>
            ) : (
              <button
                onClick={fetchRolesCreate}
                type="button"
                className="button btn-primary"
                data-dismiss="modal"
                id="actionCrearUsuario"
              >
                CREAR
              </button>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={open1}
        onClose={handleClose1}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles responsive-box">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Asignar Privilegios
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form className="rol-inter">
              <div className="row">
                <div className="col-md-4">
                  <label>Asignar Privilegio</label>
                </div>
                <div className="col-md-8">
                  <select
                    type="text"
                    id="model"
                    required
                    className=" tipoUser"
                    name="model"
                    value={model}
                    onChange={handleInputModel}
                    multiple={false}

                  >
                    <option value="DEFAULT" disabled>
                      Elije Un Tipo
                    </option>
                    <option value="App\Models\Proyecto\Proyecto">
                      Proyecto
                    </option>
                    <option value="App\Models\Empresa\Empresa">
                      Empresa
                    </option>
                    <option value="App\Models\Contacto\Contacto">
                      Contacto
                    </option>
                    <option value="App\Models\Operacion\Operacion">
                      Operacion
                    </option>
                    <option value="App\Models\Parametro\Parametro">
                      Parametro
                    </option>
                    <option value="App\Models\Renovacion\Renovacion">
                      Renovacion
                    </option>
                    <option value="App\Models\User\User">Usuario</option>
                    <option value="App\Models\Role\Role">Role</option>
                  </select>
                </div>

                <div className="col-md-4">
                  <label>Permiso de Lectura</label>
                </div>
                <div className="col-md-2">
                  <select
                    type="text"
                    id="lectura"
                    required
                    className=" tipoUser"
                    name="lectura"
                    value={read}
                    onChange={handleInputRead}
                    multiple={false}

                  >
                    <option value="DEFAULT" disabled>
                      ¿Asignar Rol de Lectura?
                    </option>
                    <option value="0">No.</option>
                    <option value="1">Sí.</option>
                  </select>
                </div>

                <div className="col-md-3">
                  <label>Permiso de Escritura</label>
                </div>
                <div className="col-md-2">
                  <select
                    type="text"
                    id="escritura"
                    required
                    className=" tipoUser"
                    name="escritura"
                    value={create}
                    onChange={handleInputCreate}
                    multiple={false}
                  >
                    <option value="DEFAULT" disabled>
                      ¿Asignar Rol de Escritura?
                    </option>
                    <option value="0">No.</option>
                    <option value="1">Sí.</option>
                  </select>
                </div>

                <div className="col-md-4">
                  <label>Permiso de Actualizar</label>
                </div>
                <div className="col-md-2">
                  <select
                    type="text"
                    id="actualizar"
                    required
                    className=" tipoUser"
                    name="actualizar"
                    value={update}
                    onChange={handleInputUpdate}
                    multiple={false}

                  >
                    <option value="DEFAULT" disabled>
                      ¿Asignar Rol de Actualizar?
                    </option>
                    <option value="0">No.</option>
                    <option value="1">Sí.</option>
                  </select>
                </div>

                <div className="col-md-3">
                  <label>Permiso de Borrar</label>
                </div>
                <div className="col-md-2">
                  <select
                    type="text"
                    id="lectura"
                    required
                    className=" tipoUser"
                    name="lectura"
                    value={deletes}
                    onChange={handleInputDeletes}
                    multiple={false}

                  >
                    <option value="DEFAULT" disabled>
                      ¿Asignar Rol de Borrar?
                    </option>
                    <option value="0">No.</option>
                    <option value="1">Sí.</option>
                  </select>
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div id="resultadocrear"></div>
            <button
              onClick={handleClose1}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>

            <button
              onClick={fetchPrivilege}
              type="button"
              className="button btn-primary"
              data-dismiss="modal"
              id="actionCrearUsuario"
            >
              CREAR
            </button>
          </div>
        </Box>
      </Modal>

      {/* // Editar Privilegios */}
      <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles responsive-box-proyectos2">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Editar Privilegios
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="card-body table-responsive">
              {loading ? (
                <div className="loading-style col-auto">
                  <Loading />
                </div>
              ) : (
                <table className="table table-hover">
                  <thead id="headResu">
                    <tr>
                      <th>Modelo</th>
                      <th>Escritura</th>
                      <th>Actualizar</th>
                      <th>Lectura</th>
                      <th>Borrar</th>
                      <th>Editar Permisos</th>
                      <th>Eliminar Permisos</th>
                    </tr>
                  </thead>
                  <tbody id="resultado">
                    {privilegios
                      ? privilegios.map((privilegio, index) => (
                        <tr key={privilegio.id}>
                          <td>{privilegio?.model}</td>
                          {privilegio?.read == "1" ? <td>Sí </td> : <td>No </td>}
                          {privilegio?.update == "1" ? <td>Sí </td> : <td>No </td>}
                          {privilegio?.read == "1" ? <td>Sí </td> : <td>No </td>}
                          {privilegio?.delete == "1" ? <td>Sí </td> : <td>No </td>}
                          <td>
                            <button
                              type="button"
                              className="btn btn-info edit-delete-buttons"
                              onClick={() => setEdit3(privilegio)}
                            >
                              <i className="material-icons padding-aling-editdelete">
                                edit
                              </i>
                            </button>{" "}
                          </td>

                          <td>
                            <button
                              type="button"
                              className="btn btn-danger edit-delete-buttons"
                              onClick={() => ConfirmAlert2(privilegio.id)}
                              value="Eliminar"
                            >
                              <i className="material-icons padding-aling-editdelete">
                                delete
                              </i>
                            </button>
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <div id="resultadocrear"></div>
            <button
              onClick={handleClose2}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>

            {/* <button
                  onClick={fetchEditPrivileges}
                  type="button"
                  className="button btn-primary"
                  data-dismiss="modal"
                  id="actionCrearUsuario"
                >
                  CREAR
                </button> */}
          </div>
        </Box>
      </Modal>

      {/* Privilegios Editar update */}
      <Modal
        keepMounted
        open={open3}
        onClose={handleClose3}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles responsive-box">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Editar Privilegios
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form className="rol-inter">
              <div className="row">
                <div className="col-md-4">
                  <label>Asignar Privilegio</label>
                </div>
                <div className="col-md-8">
                  <select
                    type="text"
                    id="model"
                    required
                    className=" tipoUser"
                    name="model"
                    value={model}
                    onChange={handleInputModel}
                    multiple={false}
                    disabled

                  >
                    <option value="DEFAULT" disabled>
                      Elije Un Tipo
                    </option>
                    <option value="App\Models\Proyecto\Proyecto">
                      Proyecto
                    </option>
                    <option value="App\Models\Empresa\Empresa">
                      Empresa
                    </option>
                    <option value="App\Models\Contacto\Contacto">
                      Contacto
                    </option>
                    <option value="App\Models\Operacion\Operacion">
                      Operacion
                    </option>
                    <option value="App\Models\Parametro\Parametro">
                      Parametro
                    </option>
                    <option value="App\Models\Renovacion\Renovacion">
                      Renovacion
                    </option>
                    <option value="App\Models\User\User">Usuario</option>
                    <option value="App\Models\Role\Role">Role</option>
                  </select>
                </div>

                <div className="col-md-4">
                  <label>Permiso de Lectura</label>
                </div>
                <div className="col-md-2">
                  <select
                    type="text"
                    id="lectura"
                    required
                    className=" tipoUser"
                    name="lectura"
                    value={read}
                    onChange={handleInputRead}
                    multiple={false}

                  >
                    <option value="DEFAULT" disabled>
                      ¿Asignar Rol de Lectura?
                    </option>
                    <option value="0">No.</option>
                    <option value="1">Sí.</option>
                  </select>
                </div>

                <div className="col-md-3">
                  <label>Permiso de Escritura</label>
                </div>
                <div className="col-md-2">
                  <select
                    type="text"
                    id="escritura"
                    required
                    className=" tipoUser"
                    name="escritura"
                    value={create}
                    onChange={handleInputCreate}
                    multiple={false}

                  >
                    <option value="DEFAULT" disabled>
                      ¿Asignar Rol de Escritura?
                    </option>
                    <option value="0">No.</option>
                    <option value="1">Sí.</option>
                  </select>
                </div>

                <div className="col-md-4">
                  <label>Permiso de Actualizar</label>
                </div>
                <div className="col-md-2">
                  <select
                    type="text"
                    id="actualizar"
                    required
                    className=" tipoUser"
                    name="actualizar"
                    value={update}
                    onChange={handleInputUpdate}
                    multiple={false}

                  >
                    <option value="DEFAULT" disabled>
                      ¿Asignar Rol de Actualizar?
                    </option>
                    <option value="0">No.</option>
                    <option value="1">Sí.</option>
                  </select>
                </div>

                <div className="col-md-3">
                  <label>Permiso de Borrar</label>
                </div>
                <div className="col-md-2">
                  <select
                    type="text"
                    id="borrar"
                    required
                    className=" tipoUser"
                    name="borrar"
                    value={deletes}
                    onChange={handleInputDeletes}
                    multiple={false}

                  >
                    <option value="DEFAULT" disabled>
                      ¿Asignar Rol de Borrar?
                    </option>
                    <option value="0">No.</option>
                    <option value="1">Sí.</option>
                  </select>
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div id="resultadocrear"></div>
            <button
              onClick={handleClose3}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>

            <button
              onClick={fetchEditPrivileges}
              type="button"
              className="button btn-primary"
              data-dismiss="modal"
              id="actionCrearUsuario"
            >
              EDITAR
            </button>
          </div>
        </Box>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        message={mensage}
        key={vertical + horizontal}
      />
      {/*   <Snackbar
            anchorOrigin={{ vertical1, horizontal1 }}
            open={openAlert1}
            onClose={handleCloseAlert1}
            message1={mensage1}
            key={vertical1 + horizontal1}
          /> */}
    </>
  );
};

export default Roles;
