import './button.css'

export const IconButton = ({
  icon,
  estilo,
  accion,
  disabled
}) => {

  const clases = `icon-btn--sf`

  return (
    <button
      type="button"
      className={clases}
      style={estilo}
      onClick={accion}
      disabled={disabled}
    >
			<i className='material-icons'>{icon}</i>
    </button>
  )

}
