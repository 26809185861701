import { Field } from "formik"
import './select.css'

export const Select = ({ children, name, label, required, horizontal }) => {

  return (
    <div className={`select--sf ${horizontal ? 'horizontal' : ''}`}>
      <label>{label}</label>
      <span>
        <Field
          as="select"
          name={name}
          required={required}
        >
          {children}
        </Field>
      </span>
    </div>
  )

}
