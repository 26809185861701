import { useNavigate, Link } from 'react-router-dom'
import { Formik, Form } from "formik"
import notify from "../../utils/notification"
import { Button } from './../core/button'
import { Input } from './../core/input'
import { axiosInstance } from '../../utils/instances'

const submit = async (values, navigate) => {
  // Show notification
  const idNotification = notify.loading('Validando Datos...')

  const response = await fetch(`${process.env.REACT_APP_URI}/login`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: new URLSearchParams({
      email: values.user,
      password: values.password
    })
  })

  const result = await response.json()
  if (result.success) {
    // save data
    localStorage.setItem('token', result.access_token)
    localStorage.setItem('data', JSON.stringify(result.data))
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${result.access_token}`

    notify.success(idNotification, 'Login Correcto')
    navigate('/dashboard', { replace: true })
  } else
    notify.error(idNotification, 'Datos Erroneos')
}

const LoginForm = () => {
  const navigate = useNavigate()

  return (
    <div className="login-form">
      <div className="title">
        <img alt="Logo" src="/assets/Selfish-logo.svg" width={150} />
        <h4>ADMINISTRACIÓN</h4>
      </div>

      <div className="welcome">
        <h3>Bienvenido</h3>
        <h5>Inicia Sesión</h5>
      </div>

      <Formik
        initialValues={{ user: '', password: '' }}
        onSubmit={values => submit(values, navigate)}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <Input
              name="user"
              label="USUARIO"
              placeholder="Usuario Selfish"
              required
              autocomplete
            />
            <Input
              name="password"
              type="password"
              label="CONTRASEÑA"
              placeholder="Contraseña"
              required
              ispassword
            />
            <Button texto="INICIAR SESIÓN" formulario noFocus disabled={isSubmitting} />
          </Form>
        )}
      </Formik>

      <Link to="/correopassword">Olvidé mi contraseña</Link>
    </div>
  )

}

export default LoginForm