import { chartColours } from "./constants"
import { currencyFormat } from "./format"
import notification from "./notification"

export const logOut = () => {
	localStorage.clear()
	window.location.href = '/login'
}

export const getYears = () => {
	const currentYear = new Date().getFullYear()
	const years = []
	for (let i = currentYear; i > 2016; i--)
		years.push(i)

	return years
}


/* CHARTS FUNCTIONS */
const generateLabels = chart => {
	const { data } = chart
	if (data.labels.length && data.datasets.length) {
		const { labels: { color, borderRadius } } = chart.legend.options

		return data.labels.map((label, i) => {
			const meta = chart.getDatasetMeta(0)
			const style = meta.controller.getStyle(i)

			return {
				text: label,
				fillStyle: style.backgroundColor,
				fontColor: color,
				strokeStyle: style.borderColor,
				borderRadius: borderRadius,
				hidden: !chart.getDataVisibility(i),
				index: i
			}
		})
	}
}

const getRandomColors = n => {
	const shuffled = [...chartColours].sort(() => 0.5 - Math.random())
	return shuffled.slice(0, n)
}

export const getDoughnutChartOptions = title => ({
	responsive: true,
	plugins: {
		legend: {
			labels: {
				generateLabels,
				useBorderRadius: true,
				borderRadius: 6,
				font: {
					size: 14,
					family: 'Poppins'
				}
			}
		},
		title: {
			display: true,
			text: title,
			color: '#000000',
			font: {
				size: 16,
				family: 'Poppins'
			}
		}
	}
})

export const createDoughnutChartData = (labels, data, tooltipLabel = '') => ({
	labels,
	datasets: [
		{
			label: tooltipLabel,
			data,
			backgroundColor: getRandomColors(labels.length),
			borderRadius: 6
		}
	]
})

export const getBarChartOptions = title => ({
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			labels: {
				useBorderRadius: true,
				borderRadius: 6,
				font: {
					size: 14,
					family: 'Poppins'
				}
			}
		},
		title: {
			display: true,
			text: title,
			color: '#000000',
			font: {
				size: 16,
				family: 'Poppins'
			}
		}
	},
	scales: {
		y: {
			ticks: {
				callback: currencyFormat
			}
		}
	}
})

export const createBarChartData = (xAxisLabels, datasetsNames, datasets) => {
	const colours = getRandomColors(2)
	return {
		labels: xAxisLabels,
		datasets: [
			{
				label: datasetsNames[0],
				data: datasets[datasetsNames[0]],
				backgroundColor: colours[0],
				borderRadius: 6
			},
			{
				label: datasetsNames[1],
				data: datasets[datasetsNames[1]],
				backgroundColor: colours[1],
				borderRadius: 6
			}
		]
	}
}

export const getPolarChartOptions = title => ({
	responsive: true,
	scales: {
		r: {
			ticks: {
				callback: currencyFormat
			}
		}
	},
	plugins: {
		legend: {
			labels: {
				generateLabels,
				useBorderRadius: true,
				borderRadius: 6,
				font: {
					size: 14,
					family: 'Poppins'
				}
			}
		},
		title: {
			display: true,
			text: title,
			color: '#000000',
			font: {
				size: 16,
				family: 'Poppins'
			}
		}
	}
})

export const createPolarChartData = (labels, data, tooltipLabel = '') => ({
	labels,
	datasets: [
		{
			label: tooltipLabel,
			data,
			backgroundColor: getRandomColors(labels.length).map(color => `${color}A0`),
			borderColor: 'transparent',
			borderRadius: 6
		}
	]
})


/* MUTATION NOTIFICATIONS FUNCTIONS */
export const getMutationOptions = ({ loadingMessage, successMessage, errorMessage }) => ({
	onMutate: (variables) => {
		const id = notification.loading(loadingMessage)
		return { idNotification: id }
	},
	onSuccess: (data, variables, context) => {
		notification.success(context.idNotification, successMessage)
	},
	onError: (error, variables, context) => {
		notification.error(context.idNotification, errorMessage)
	}
})
