import React, { useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import constants from "../utils/constants";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import "../sass/assets/css/style.css";
import Loading from "./Loading";

//estilos del filter
const TextFields = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

//estilos del boton FIlter
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada !important;

  &:hover {
    background-color: white !important;
  }
`;
//componente del filtro
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextFields
      id="search"
      type="text"
      placeholder="Filtrar por nombre"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const Privilege = () => {
  // Loading
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);

  //Modal form cerrar/abrir
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    cleanData();
    setOpen(false);
  };

  const [role_id, setRoleid] = useState("");
  const [model, setModel] = useState("");
  const [read, setRead] = useState("");
  const [create, setCreate] = useState("");
  const [deletee, setDelete] = useState("");

  //Edit
  const [contactid, setID] = useState("");

  //Snackbar alerta
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //map a contactos
  const [privilege, setPrivilege] = useState([]);

  //mensaje de crear usuarios
  const [mensage, setMensage] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  //Alerta Snackbar
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
    handleClose();
  };

  //limpiar form
  const cleanData = () => {
    setRoleid("");
    setModel("");
    setRead("");
    setCreate("");
    setDelete("");
    setID("");
  };

  //alerta para eliminar
  const ConfirmAlert = async (id) => {
    var mensaje = window.confirm("¿Desea eliminar este mensaje?");
    if (mensaje) {
      onDelete(id);
    } else {
      alert("Cancelado satisfactoriamente!");
    }
  };

  //PAGINACION
  const columns = [
    {
      name: "ID",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Model",
      selector: (row) => row?.model,
      sortable: true,
    },
    {
      name: "read",
      selector: (row) => row?.read,
      sortable: true,
    },
    {
      name: "role_id",
      selector: (row) => row?.role_id,
      sortable: true,
    },
  ];

  //const para el filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const privilegess = privilege.filter(
    (item) =>
      item?.model &&
      item?.model.toLowerCase().includes(filterText.toLowerCase())
  );
  //SubHeader del filtro
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //Crear privilege
  const fetchPrivileges = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/privilege/store", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          role_id: role_id,
          model: model,
          read: read,
          create: create,
          delete: deletee,
        }),
      });
      const result = await response.json();

      //Alerta de crear usuario
      if (result?.success) {
        setMensage("El contacto se ha creado con exito.");
        setOpenAlert(true);
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        fetchPrivilegesRead();
        cleanData();
        handleClose();
      } else {
        setMensage(
          "Ocurrio un error al registrar el usuario intente de nuevo."
        );
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        handleClose();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Editar contactos
  const fetchEditContact = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/contacto/" + contactid,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            role_id: role_id,
            model: model,
            read: read,
            create: create,
            delete: deletee,
          }),
        }
      );
      const result = await response.json();

      //Alerta de crear usuario
      if (result?.success) {
        setMensage("El contacto se ha editado con exito.");
        setOpenAlert(true);
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        fetchPrivilegesRead();
        cleanData();
        handleClose();
      } else {
        setMensage("Ocurrio un error al editar el contacto intente de nuevo.");
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        handleClose();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Set elements on edit contact
  const setEdit = async (privilege) => {
    handleOpen();
    setRoleid(privilege?.role_id);
    setModel(privilege?.model);
    setRead(privilege?.read);
    setCreate(privilege?.create);
    setDelete(privilege?.update);
    setID(privilege.id);
  };

  //Consultar Privileges
  const fetchPrivilegesRead = async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(constants.api + "auth/privilege", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const result = await response.json();

    if (result?.success) {
      setPrivilege(result.data);
    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchPrivilegesRead();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  //delete contactos
  const onDelete = async (contactoID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/contacto/" + contactoID,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchPrivilegesRead();
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //onClick´s
  const handleInputRoleid = (event) => {
    setRoleid(event.target.value);
  };

  const handleInputModel = (event) => {
    setModel(event.target.value);
  };

  const handleInputRead = (event) => {
    setRead(event.target.value);
  };

  const handleInputCreate = (event) => {
    setCreate(event.target.value);
  };

  const handleInputDelete = (event) => {
    setDelete(event.target.value);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="wrapper-hidden ">
      <SideNav />
      <div className="main-panel">
        <div className="wrapper wrapper-style ">
          <div className="main-panel panel-main">
            <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
              <div className="container-fluid">
                <div className="navbar-wrapper z-index-card">
                  <p className="navbar-brand">SELFISH Control Privilegios</p>
                </div>
              </div>
            </nav>

            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="card z-index-card">
                      <div className="card-header card-header-warning header-card">
                        <h4 className="card-title inline-style">Privilegios</h4>
                      </div>

                      <div className="card-body table-responsive">
                        <DataTable
                          columns={columns}
                          data={privilegess}
                          noDataComponent={<Loading />}
                          pagination
                          paginationResetDefaultPage={resetPaginationToggle}
                          subHeader
                          subHeaderComponent={subHeaderComponentMemo}
                          selectableRows
                          persistTableHead
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box className="boxstyles responsive-box">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Crear Contacto
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <label>role id</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="role_id"
                        required
                        className=" tipoUser"
                        name="role_id"
                        value={role_id}
                        onChange={handleInputRoleid}
                      />
                    </div>

                    <div className="col-md-4">
                      <label>Model</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        value={model}
                        type="text"
                        id="model"
                        required
                        className=" tipoUser"
                        name="model"
                        onChange={handleInputModel}
                      />
                    </div>

                    <div className="col-md-4">
                      <label>Read</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        value={read}
                        type="tel"
                        id="read"
                        required
                        className=" tipoUser"
                        name="read"
                        onChange={handleInputRead}
                      />
                    </div>

                    <div className="col-md-4">
                      <label>create</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        value={create}
                        type="create"
                        id="create"
                        name="create"
                        required
                        className=" tipoUser"
                        onChange={handleInputCreate}
                      />
                    </div>

                    <div className="col-md-4">
                      <label>Delete</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        value={deletee}
                        type="text"
                        id="deletee"
                        name="deletee"
                        required
                        className=" tipoUser"
                        onChange={handleInputDelete}
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <div id="resultadocrear"></div>
                <button
                  onClick={handleClose}
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                {contactid ? (
                  <button
                    onClick={fetchEditContact}
                    type="button"
                    className="button btn-primary"
                    data-dismiss="modal"
                    id="actionCrearUsuario"
                  >
                    Editar
                  </button>
                ) : (
                  <button
                    onClick={fetchPrivileges}
                    type="button"
                    className="button btn-primary"
                    data-dismiss="modal"
                    id="actionCrearUsuario"
                  >
                    CREAR
                  </button>
                )}
              </div>
            </Box>
          </Modal>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openAlert}
            onClose={handleCloseAlert}
            message={mensage}
            key={vertical + horizontal}
          />
        </div>
      </div>
    </div>
  );
};

export default Privilege;

if (document.getElementById("privilege")) {
  ReactDOM.render(<Privilege />, document.getElementById("privilege"));
}
