import './button.css'

export const Button = ({
  texto,
  estilo,
  pequeño,
  cancelar,
  formulario,
  idFormulario,
  accion,
  noFocus,
  disabled
}) => {

  const clases = `btn--sf ${pequeño ? 'boton-pequeño' : ''} ${cancelar ? 'boton-cancelar' : ''} ${noFocus ? 'no-focus' : ''}`

  if (formulario || idFormulario) {
    return (
      <button
        type="submit"
        className={clases}
        style={estilo}
        form={idFormulario}
        disabled={disabled}
      >
        {texto}
      </button>
    )
  }

  return (
    <button
      type="button"
      className={clases}
      style={estilo}
      onClick={accion}
      disabled={disabled}
    >
      {texto}
    </button>
  )

}
