import { Field } from "formik"
import { useState } from "react"
import "./input.css"
import { OpenEye, CloseEye } from "./../icons"

export const Input = ({ name, type = "text", label, placeholder, required, autocomplete, ispassword, readonly, horizontal, className, ...props }) => {
  const [inputType, setInputType] = useState(type)

  const togglePass = () => {
    setInputType(inputType === 'text' ? 'password' : 'text')
  }

  return (
    <div className={`input--sf ${horizontal ? 'horizontal' : ''} ${className}`} {...props} >
      <label>{label}</label>
      <span>
        <Field
          name={name}
          type={inputType}
          placeholder={placeholder}
          required={required}
          autoComplete={autocomplete ? 'on' : 'off'}
          readOnly={readonly ? true : false}
        />
        {
          ispassword ?
            <i onClick={togglePass}>
              {
                inputType === 'text' ? <OpenEye /> : <CloseEye />
              }
            </i>
            : null
        }
      </span>
    </div>
  )

}
