import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import constants from "../utils/constants";
import { useParams } from "react-router";
import { Autocomplete, TextField } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import Swal from "sweetalert2";
import { Fragment } from "react";
import Loading from "./Loading";
import styled from "styled-components";
import { ViewArraySharp } from "@material-ui/icons";
//estilos del filter
const TextFields = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#c1c1c1",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#c1c1c1",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#c1c1c1",
    },
    "&:hover fieldset": {
      borderColor: "#c1c1c1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#c1c1c1",
    },
  },
});

var moment = require("moment");

const DetailProjects = () => {
  // Loading
  const [loading, setLoading] = useState(true);

  const changeLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const [conceptosExtraID, setConceptoExtraID] = useState(0);
  const [abonosID, setAbonoID] = useState("");
  const [proyectos, setProyectos] = useState([]);

  const { proyect_id } = useParams();

  // Const EDIT/Clean Data
  const [detailID, setID] = useState("");

  //UseState Para Form Conceptos
  const [tipo_id, setTipo_id] = useState(null);
  const [importe, setImporte] = useState("");
  const [descuento, setDescuento] = useState(null);
  const [aplica_iva, setAplica_iva] = useState("");
  const [moneda, setMoneda] = useState("");
  const [autocompleteTiposExtra, setAutocompleteTiposExtra] = useState([]);
  const [autocompleteTiposAbonos, setAutocompleteTiposAbonos] = useState([]);
  const [autocompleteTiposAbonosMetodo, setAutocompleteTiposAbonosMetodo] =
    useState([]);

  const [autocompleteDescuento, setAutocompleteDescuento] = useState([]);

  //UseState Para Form Abonos
  const [tipo_idd, setTipo_idd] = useState(null);
  const [metodo_id, setMetodo_id] = useState(null);
  const [monto, setMonto] = useState("");
  const [aplica_ivaa, setAplica_ivaa] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [fecha_movimiento, setFecha] = useState("");
  const [concepto_nombre, setConcepto_nombre] = useState("");

  // Alerta
  const [openAlert, setOpenAlert] = React.useState(false);
  const [mensage, setMensage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //Alerta
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //MODAL DEL FORMULARIO editar
  const [open2, setOpen2] = React.useState(false);

  //modal formulario abrir/cerrar EDITAR
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    cleanData();
    setOpen2(false);
  };

  //MODAL DEL FORMULARIO editar Abono
  const [open, setOpen] = React.useState(false);

  //modal formulario abrir/cerrar EDITAR
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    cleanData();
    setOpen(false);
  };

  //limpiar forms
  const cleanData = () => {
    setTipo_id(null);
    setImporte("");
    setDescuento(null);
    setAplica_iva("");
    setMoneda("");
    setConcepto_nombre("");

    setTipo_idd(null);
    setMetodo_id(null);
    setMonto("");
    setAplica_ivaa("");
    setComentarios("");
    setFecha("");
    setID("");
  };

  // Editar concepto extra
  const fetchEditConceptoExtra = async () => {
    if (tipo_id == null) {
      setMensage("Añadir campo Tipo.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (importe == "") {
      setMensage("Añadir campo Importe.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (concepto_nombre == "") {
      setMensage("Añadir campo Nombre.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (descuento == null) {
      setMensage("Añadir campo Descuento.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (aplica_iva == "") {
      setMensage("Añadir campo IVA.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/proyecto/" +
        proyect_id +
        "/concepto/" +
        conceptosExtraID,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            tipo_id: tipo_id.id,
            importe: importe,
            concepto_nombre: concepto_nombre,
            descuento: descuento.label,
            aplica_iva: aplica_iva,
            moneda: "MXN",
          }),
        }
      );
      const result = await response.json();
      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu Concepto extra se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchProyectos();
        cleanData();
        handleClose2();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al ingresar Concepto extra",
          text: "Intente de nuevo",
        });
        handleClose2();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Set elements on edit concepto extra
  const setEdit = async (conceptoEdit) => {
    handleOpen2();

    setTipo_id({ label: conceptoEdit.tipo.valor, id: conceptoEdit.tipo.id });
    setImporte(conceptoEdit?.importe);
    setDescuento({
      label: conceptoEdit.descuento == null ? 0 : conceptoEdit.descuento,
      id: conceptoEdit.descuento?.id,
    });
    setAplica_iva(conceptoEdit?.aplica_iva);
    setMoneda(conceptoEdit?.moneda);
    setConcepto_nombre(conceptoEdit?.concepto_nombre);
    setConceptoExtraID(conceptoEdit?.id);
  };

  // aLERT DELETE
  const ConfirmAlert = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
        Swal.fire("Eliminado!", "El Concepto Extra fue Eliminado.", "success");
      }
    });
  };

  // Delete concepto extra
  const onDelete = async (conceptoExtraID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/proyecto/" +
        proyect_id +
        "/concepto/" +
        conceptoExtraID,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchProyectos();
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  // --------------- Edit Abonos y Delete ------------------  //

  // Editar Abonos
  const fetchEditAbono = async () => {
    if (metodo_id == null) {
      setMensage("Añadir campo Método.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (monto == "") {
      setMensage("Añadir campo Monto.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (aplica_ivaa == "") {
      setMensage("Añadir campo IVA.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (fecha_movimiento == "") {
      setMensage("Añadir campo Fecha.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const fecha = moment(fecha_movimiento).format("YYYY-MM-DD HH:mm:ss");
      const response = await fetch(
        constants.api +
        "auth/proyecto/" +
        proyect_id +
        "/operacion/" +
        abonosID,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            tipo_id: 30,
            metodo_id: metodo_id.id,
            monto: monto,
            aplica_iva: aplica_ivaa,
            comentarios: comentarios,
            fecha_movimiento: fecha,
          }),
        }
      );
      const result = await response.json();
      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu Abono se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchProyectos();
        // cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar Abono",
          text: "Intente de nuevo",
        });
        // });
        handleClose();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Set elements on edit Abono
  const setEdit2 = async (abonoEdit) => {
    console.log(abonoEdit)
    const fechaMoment = moment(abonoEdit?.fecha_movimiento).format(
      "YYYY-MM-DDThh:mm"
    );
    handleOpen();
    setMetodo_id({ label: abonoEdit?.metodo?.valor, id: abonoEdit.metodo.id });
    setMonto(abonoEdit?.monto);
    setAplica_ivaa(abonoEdit?.aplica_iva);
    setComentarios(abonoEdit?.comentarios);
    setFecha(fechaMoment);
    setAbonoID(abonoEdit?.id);
  };

  // aLERT DELETE
  const ConfirmAlert2 = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete2(id);
        Swal.fire("Eliminado!", "El Abono fue Eliminado.", "success");
      }
    });
  };

  // Delete concepto extra
  const onDelete2 = async (abonoID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/proyecto/" + proyect_id + "/operacion/" + abonoID,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchProyectos();
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //  Termina aquui

  //CONSULTAR Tipos Conceptos Extras para el FORM
  const fetchTiposExtras = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=Proyectos&search[sub_grupo]=Categorias",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteTiposExtra(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR Tipos Abonos  para el FORM
  const fetchTiposAbonos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/parametro?search[grupo]=Ingresos",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteTiposAbonos(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR Metodo Abonos  para el FORM
  const fetchMetodoAbonos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=Operaciones&search[sub_grupo]=Medios de Pago",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteTiposAbonosMetodo(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR Descuento para el FORM
  const fetchDescuento = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=proyectos&search[sub_grupo]=Descuentos",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      let arrayTipo = [];
      arrayTipo.push({ label: "0", id: 1 });
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteDescuento(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR USUARIOS
  const fetchProyectos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/proyecto/" + proyect_id,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      setProyectos(result.data);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchProyectos();
        fetchTiposExtras();
        fetchTiposAbonos();
        fetchMetodoAbonos();
        fetchDescuento();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        changeLoading();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  //Crear Nuevo concepto
  const fetchConcepto = async () => {
    if (tipo_id == null) {
      setMensage("Añadir campo Tipo.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (importe == "") {
      setMensage("Añadir campo Importe.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (aplica_iva == "") {
      setMensage("Añadir campo IVA.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/proyecto/" + proyect_id + "/concepto/store",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            tipo_id: tipo_id.id,
            importe: importe,
            concepto_nombre: concepto_nombre,
            descuento: descuento == null ? "0" : descuento.label,
            aplica_iva: aplica_iva,
            moneda: "MXN",
          }),
        }
      );
      const result = await response.json();

      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu Concepto extra se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchProyectos();
        cleanData();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al ingresar Concepto",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Crear Nuevo Abono
  const fetchAbono = async () => {
    if (metodo_id == null) {
      setMensage("Añadir campo Método.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (monto == "") {
      setMensage("Añadir campo Monto.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (aplica_ivaa == "") {
      setMensage("Añadir campo IVA.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (fecha_movimiento == "") {
      setMensage("Añadir campo Fecha.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const fecha = moment(fecha_movimiento).format("YYYY-MM-DD HH:mm:ss");
      const response = await fetch(
        constants.api + "auth/proyecto/" + proyect_id + "/operacion/store",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            tipo_id: 30,
            metodo_id: metodo_id.id,
            monto: monto,
            aplica_iva: aplica_ivaa,
            comentarios: comentarios,
            fecha_movimiento: fecha,
          }),
        }
      );
      const result = await response.json();

      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu Abono se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchProyectos();
        cleanData();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al ingresar abono",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };
  const handleInputTipoId = (event, values) => {
    setTipo_id(values);
  };

  const handleInputImporte = (event) => {
    setImporte(event.target.value);
  };

  const handleInputDescuento = (event, values) => {
    setDescuento(values);
  };

  const handleInputIva = (event) => {
    setAplica_iva(event.target.value);
  };

  const handleInputMoneda = (event) => {
    setMoneda(event.target.value);
  };

  const handleInputConcepto_nombre = (event) => {
    setConcepto_nombre(event.target.value);
  };

  const handleInputTipoIdd = (event, values) => {
    setTipo_idd(values);
  };

  const handleInputMetodoId = (event, values) => {
    setMetodo_id(values);
  };

  const handleInputMonto = (event) => {
    setMonto(event.target.value);
  };

  const handleInputAplicaIva = (event) => {
    setAplica_ivaa(event.target.value);
  };

  const handleInputComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const handleInputFecha = (event) => {
    setFecha(event.target.value);
  };

  const ordenararray = proyectos[0]?.operaciones;
  const arrayOrdenado = ordenararray?.sort(
    (a, b) =>
      moment(a.fecha_movimiento, "YYYY-MM-DD HH:mm:ss").unix() -
      moment(b.fecha_movimiento, "YYYY-MM-DD HH:mm:ss").unix()
  );

  return (
    <>
      <div className="main-panel panel-main">
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
          <div className="container-fluid">
            <div className="navbar-wrapper z-index-card mediaContact">
              <a className="navbar-brand" href="">
                SELFISH Control Detalles Proyectos
              </a>
            </div>
          </div>
        </nav>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Información General
                    </h4>
                  </div>

                  <div className="card-body table-responsive">
                    {loading ? (
                      <div className="loading-style col-auto">
                        <Loading />
                      </div>
                    ) : (
                      <table className="table table-hover">
                        <thead id="headResul">
                          <tr>
                            <th>Empresa</th>
                            <th>Proyecto</th>
                            <th>Industria</th>
                            <th>Contacto</th>
                            <th>E-mail</th>
                          </tr>
                        </thead>

                        <tbody id="resu">
                          {proyectos
                            ? proyectos.map((proyecto, pos1) => (
                              <tr key={pos1}>
                                <td>
                                  {proyecto?.empresa?.nombre_comercial}
                                </td>
                                <td>{proyecto?.proyecto_nombre}</td>

                                <td>{proyecto?.empresa?.industria}</td>

                                <td>
                                  {
                                    proyecto?.empresa?.contactos[0]
                                      ?.person?.nombre
                                  }{" "}
                                  {
                                    proyecto.empresa.contactos[0]?.person
                                      .apellido
                                  }
                                </td>

                                <td>
                                  {
                                    proyecto?.empresa?.contactos[0]
                                      ?.person?.email
                                  }
                                </td>
                              </tr>
                            ))
                            : null}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Total Cliente
                    </h4>
                  </div>

                  <div className="card-body table-responsive">
                    {loading ? (
                      <div className="loading-style col-auto">
                        <Loading />
                      </div>
                    ) : (
                      <table className="table table-hover">
                        <thead id="headResu">
                          <tr>
                            <th>Total</th>
                            <th>Pagado</th>
                            <th>Restante</th>
                          </tr>
                        </thead>
                        <tbody id="resul">
                          {proyectos
                            ? proyectos.map((proyectoc, pos2) => (
                              <tr key={pos2}>
                                <td>{proyectoc?.total}</td>

                                <td>{proyectoc?.sum_pagos}</td>

                                <td>{proyectoc?.saldo_pendiente}</td>
                              </tr>
                            ))
                            : null}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Conceptos
                    </h4>
                  </div>

                  <div className="card-body table-responsive  overflow-scrool">
                    <table className="table table-hover">
                      <thead id="headRe">
                        <tr>
                          <th>Nombre del Concepto</th>
                          <th>Tipo</th>
                          <th>SubTotal</th>
                          <th>DSC</th>
                          <th>IVA</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody id="resulta">
                        {proyectos
                          ? proyectos.map((proyecto, post11) => (
                            <Fragment key={post11}>
                              {proyecto.conceptos
                                ? proyecto.conceptos.map(
                                  (concepto, pos5) => (
                                    <tr key={pos5}>
                                      <td>
                                        {concepto?.concepto_nombre}
                                      </td>
                                      <td>{concepto?.tipo?.valor}</td>
                                      <td>{concepto?.subtotal}</td>
                                      <td>{concepto?.descuento}</td>
                                      <td>{concepto?.iva}</td>
                                      <td>{concepto?.total}</td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-info edit-delete-buttons"
                                          onClick={() =>
                                            setEdit(concepto)
                                          }
                                        >
                                          <i className="material-icons padding-aling-editdelete">
                                            edit
                                          </i>
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger edit-delete-buttons"
                                          onClick={() =>
                                            ConfirmAlert(concepto.id)
                                          }
                                          value="Eliminar"
                                        >
                                          <i className="material-icons padding-aling-editdelete">
                                            delete
                                          </i>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )
                                : null}
                            </Fragment>
                          ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">Abonos</h4>
                  </div>

                  <div className="card-body table-responsive overflow-scrool">
                    <table className="table table-hover">
                      <thead id="headResult">
                        <tr>
                          <th>Monto</th>
                          <th>Método</th>
                          <th>Fecha</th>
                        </tr>
                      </thead>
                      <tbody id="resultad" className="">
                        {arrayOrdenado
                          ? arrayOrdenado.map((operacion, pos6) => (
                            <tr key={pos6}>
                              <td>{operacion.total}</td>
                              <td>{operacion.metodo?.valor}</td>
                              <td>
                                {moment(operacion.fecha_movimiento)
                                  .utc().format('DD-MM-YYYY')}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-info edit-delete-buttons"
                                  onClick={() => setEdit2(operacion)}
                                >
                                  <i className="material-icons padding-aling-editdelete">
                                    edit
                                  </i>
                                </button>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger edit-delete-buttons"
                                  onClick={() =>
                                    ConfirmAlert2(operacion.id)
                                  }
                                  value="Eliminar"
                                >
                                  <i className="material-icons padding-aling-editdelete">
                                    delete
                                  </i>
                                </button>
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Agregar Concepto Extra
                    </h4>
                  </div>

                  <div className="card-body table-responsive">
                    <div className="modal-body">
                      <form>
                        <div className="row">
                          <div className="col-md-4 conceptoExtra">
                            <label>Nombre del Concepto</label>
                          </div>

                          <div className="col-md-8 conceptoExtra">
                            <input
                              type="text"
                              id="concepto_nombre"
                              required
                              className="  tipoUser"
                              name="concepto_nombre"
                              value={concepto_nombre}
                              onChange={handleInputConcepto_nombre}
                            />
                          </div>

                          <div className="col-md-4 conceptoExtra">
                            <label>Tipo</label>
                          </div>

                          <div className="col-md-8">
                            <Autocomplete
                              className="style-roles"
                              disablePortal
                              id="auto-complete"
                              options={autocompleteTiposExtra}
                              getOptionLabel={(options) => options.label}
                              value={tipo_id}
                              sx={{ width: 300 }}
                              onChange={handleInputTipoId}
                              name="descuento"
                              includeInputInList
                              required
                              renderInput={(params) => (
                                <TextField {...params} variant="standard" />
                              )}
                            />
                          </div>

                          <div className="col-md-4 conceptoExtra">
                            <label>Importe</label>
                          </div>

                          <div className="col-md-8 conceptoExtra">
                            <input
                              type="number"
                              id="importe"
                              required
                              className="  tipoUser"
                              name="importe"
                              value={importe}
                              onChange={handleInputImporte}
                            />
                          </div>

                          <div className="col-md-4 conceptoExtra">
                            <label>Descuento</label>
                          </div>

                          <div className="col-md-8 conceptoExtra">
                            <Autocomplete
                              className="style-roles"
                              disablePortal
                              id="combo-box-demo"
                              options={autocompleteDescuento}
                              getOptionLabel={(options) => options.label}
                              defaultValue={{ label: "0", id: 1 }}
                              sx={{ width: 300 }}
                              onChange={handleInputDescuento}
                              name="descuento"
                              required
                              renderInput={(params) => (
                                <CssTextField
                                  {...params}
                                  id="standard-basic"
                                  variant="standard"
                                  label="Descuento %"
                                />
                              )}
                            />
                          </div>

                          <div className="col-md-4 conceptoExtra">
                            <label>IVA</label>
                          </div>
                          <div className="col-md-8 conceptoExtra">
                            <select
                              type="text"
                              id="iva"
                              required
                              className="  tipoUser"
                              name="iva"
                              value={aplica_iva}
                              onChange={handleInputIva}
                            >
                              <option value="" defaultValue disabled>
                                Eliga Una Opción
                              </option>
                              <option value="Si">Sí</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          {/* 
                                  <div className="col-md-4 conceptoExtra">
                                    <label>Moneda</label>
                                  </div>

                                  <div className="col-md-8 conceptoExtra">
                                    <select
                                      type="text"
                                      id="moneda"
                                      required
                                      className="  tipoUser"
                                      name="moneda"
                                      value={moneda}
                                      onChange={handleInputMoneda}
                                    >
                                      <option value="MXN" defaultValue>
                                        MXN
                                      </option>
                                    </select>
                                  </div> */}

                          <div className="conceptoExtra-boton">
                            <button
                              onClick={fetchConcepto}
                              type="button"
                              className="button btn-primary "
                              data-dismiss="modal"
                              id="actionCrearEmpresa"
                            >
                              CREAR
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Añadir Abono
                    </h4>
                  </div>

                  <div className="card-body table-responsive">
                    <div className="modal-body">
                      <form>
                        <div className="row">
                          <div className="col-md-4 conceptoExtra">
                            <label>Método</label>
                          </div>
                          <div className="col-md-8 ">
                            <Autocomplete
                              className="style-roles"
                              disablePortal
                              id="combo-box-demo"
                              options={autocompleteTiposAbonosMetodo}
                              getOptionLabel={(options) => options.label}
                              value={metodo_id}
                              sx={{ width: 300 }}
                              onChange={handleInputMetodoId}
                              name="descuento"
                              required
                              renderInput={(params) => (
                                <CssTextField
                                  id="standard-basic"
                                  variant="standard"
                                  {...params}
                                />
                              )}
                            />
                          </div>

                          <div className="col-md-4 conceptoExtra">
                            <label>Monto</label>
                          </div>
                          <div className="col-md-8 conceptoExtra">
                            <input
                              type="number"
                              id="monto"
                              required
                              className="  tipoUser"
                              name="monto"
                              value={Number(monto)}
                              onChange={handleInputMonto}
                            />
                          </div>

                          <div className="col-md-4 conceptoExtra">
                            <label>IVA</label>
                          </div>
                          <div className="col-md-8 conceptoExtra">
                            <select
                              type="text"
                              id="ivaa"
                              required
                              className="  tipoUser"
                              name="ivaa"
                              value={aplica_ivaa}
                              onChange={handleInputAplicaIva}
                            >
                              <option value="" defaultValue disabled>
                                Eliga Una Opción
                              </option>
                              <option value="Si">Sí</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          <br />

                          <div className="col-md-4 conceptoExtra">
                            <label>Comentarios</label>
                          </div>
                          <div className="col-md-8 style-roles ">
                            <textarea
                              value={comentarios}
                              onChange={handleInputComentarios}
                              name="comentarios"
                              type="textarea"
                              id="comentarios"
                              placeholder="Escribe tus comentarios aquí..."
                              rows="5"
                              cols="100"
                              className="size-Textare"
                            ></textarea>
                          </div>
                          <br />
                          <br />

                          <div className="col-md-4 conceptoExtra">
                            <label>Fecha del Movimiento</label>
                          </div>
                          <div className="col-md-8 conceptoExtra">
                            <input
                              type="datetime-local"
                              id="fecha"
                              required
                              className="  tipoUser"
                              name="fecha"
                              value={fecha_movimiento}
                              onChange={handleInputFecha}
                            />
                          </div>
                          <div className="conceptoExtra-boton">
                            <button
                              onClick={fetchAbono}
                              type="button"
                              className="button btn-primary "
                              data-dismiss="modal"
                              id="actionCrearEmpresa"
                            >
                              CREAR
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box scrollModal">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Editar Conceptos Extra
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4 conceptoExtra">
                  <label>Nombre del Concepto</label>
                </div>

                <div className="col-md-8 conceptoExtra">
                  <TextField
                    type="text"
                    id="concepto_nombre"
                    required
                    className="tipoUser"
                    name="concepto_nombre"
                    value={concepto_nombre}
                    onChange={handleInputConcepto_nombre}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Tipo</label>
                </div>

                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteTiposExtra}
                    getOptionLabel={(options) => options.label}
                    value={tipo_id}
                    sx={{ width: 300 }}
                    onChange={handleInputTipoId}
                    name="descuento"
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="Tipos" />
                    )}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Importe</label>
                </div>

                <div className="col-md-8 conceptoExtra">
                  <input
                    type="number"
                    id="importe"
                    required
                    className="  tipoUser"
                    name="importe"
                    value={importe}
                    onChange={handleInputImporte}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Descuento</label>
                </div>

                <div className="col-md-8 conceptoExtra">
                  <Autocomplete
                    className="style-roles"
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteDescuento}
                    getOptionLabel={(options) => options.label}
                    value={descuento}
                    sx={{ width: 300 }}
                    onChange={handleInputDescuento}
                    name="descuento"
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="Descuento %" />
                    )}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>IVA</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <select
                    type="text"
                    id="iva"
                    required
                    className="  tipoUser"
                    name="iva"
                    value={aplica_iva}
                    onChange={handleInputIva}
                  >
                    <option value="DEFAULT" defaultValue disabled>
                      Eliga Una Opción
                    </option>
                    <option value="Si">Sí</option>
                    <option value="No">No</option>
                  </select>
                </div>

                {/*  <div className="col-md-4 conceptoExtra">
                      <label>Moneda</label>
                    </div>

                    <div className="col-md-8 conceptoExtra">
                      <select
                        type="text"
                        id="moneda"
                        required
                        className="  tipoUser"
                        name="moneda"
                        value={moneda}
                        onChange={handleInputMoneda}
                      >
                        <option value="MXN" defaultValue disabled>
                          MXN
                        </option>
                      </select>
                    </div> */}
              </div>
              <div className="modal-footer">
                <div id="resultadocrear"></div>
                <button
                  onClick={handleClose2}
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>

                <button
                  onClick={fetchEditConceptoExtra}
                  type="button"
                  className="button btn-primary"
                  data-dismiss="modal"
                  id="actionCrearUsuario"
                >
                  Editar
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {/* Modal Abonos */}
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box scrollModal">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Editar Abono
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4 conceptoExtra">
                  <label>Método</label>
                </div>
                <div className="col-md-8 ">
                  <Autocomplete
                    className="style-roles"
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteTiposAbonosMetodo}
                    getOptionLabel={(options) => options.label}
                    value={metodo_id}
                    sx={{ width: 300 }}
                    onChange={handleInputMetodoId}
                    name="descuento"
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="Método" />
                    )}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Monto</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="number"
                    id="monto"
                    required
                    className="  tipoUser"
                    name="monto"
                    value={monto}
                    onChange={handleInputMonto}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>IVA</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <select
                    type="text"
                    id="ivaa"
                    required
                    className="  tipoUser"
                    name="ivaa"
                    value={aplica_ivaa}
                    onChange={handleInputAplicaIva}
                  >
                    <option value="DEFAULT" defaultValue disabled>
                      Eliga Una Opción
                    </option>
                    <option value="Si">Sí</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <br />

                <div className="col-md-4 conceptoExtra">
                  <label>Comentarios</label>
                </div>
                <div className="col-md-8 style-roles ">
                  <textarea
                    value={comentarios}
                    onChange={handleInputComentarios}
                    name="comentarios"
                    type="textarea"
                    id="comentarios"
                    placeholder="Escribe tus comentarios aquí..."
                    rows="5"
                    cols="100"
                    className="size-Textare"
                  ></textarea>
                </div>
                <br />
                <br />

                <div className="col-md-4 conceptoExtra">
                  <label>Fecha del Movimiento</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="datetime-local"
                    id="fecha"
                    required
                    className="  tipoUser"
                    name="fecha"
                    value={fecha_movimiento}
                    onChange={handleInputFecha}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <div id="resultadocrear"></div>
                <button
                  onClick={handleClose}
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>

                <button
                  onClick={fetchEditAbono}
                  type="button"
                  className="button btn-primary"
                  data-dismiss="modal"
                  id="actionCrearUsuario1"
                >
                  Editar
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        message={mensage}
        key={vertical + horizontal}
      />
    </>
  );
};

export default DetailProjects;

if (document.getElementById("detailProjects")) {
  ReactDOM.render(
    <DetailProjects />,
    document.getElementById("detailProjects")
  );
}
