import './dashboard.css'
import Row from './../core/layout/Row'
import Card from '../core/ui/Card'
import DashboardCard from './DashboardCard'
import { useDateExpenditure, useDateRevenue } from '../../hooks/statistic'
import CreationForms from './CreationForms'
import Table from '../core/ui/Table'
import { useRecentRevenue, useRecentExpenditure } from '../../hooks/operation'
import { format } from 'date-fns'
import { currencyFormat } from '../../utils/format'


const recentExpenditureColumns = [
  { header: 'Receptor', accessorKey: 'receptor.nombre_comercial' },
  { header: 'Fecha', accessorFn: ({ fecha_movimiento }) => format(new Date(fecha_movimiento), 'dd-MM-y') },
  { header: 'Monto', accessorFn: ({ total }) => currencyFormat(total) }
]

const recentRevenueColumns = [
  { header: 'Emisor', accessorKey: 'emisor.nombre_comercial' },
  { header: 'Fecha', accessorFn: ({ fecha_movimiento }) => format(new Date(fecha_movimiento), 'dd-MM-y') },
  { header: 'Monto', accessorFn: ({ total }) => currencyFormat(total) }
]


const Dashboard = () => {

  const today = new Date()
  const dateExpenditureQuery = useDateExpenditure(today.getFullYear(), today.getMonth() + 1)
  const dateRevenueQuery = useDateRevenue(today.getFullYear(), today.getMonth() + 1)
  const recentRevenueQuery = useRecentRevenue()
  const recentExpenditureQuery = useRecentExpenditure()

  return (
    <div id='dashboard--sf'>

      <section id='info'>
        <Row columns={3} levelGap={3}>
          <DashboardCard
            title="Ingresos del Mes"
            data={dateRevenueQuery.data}
            materialIcon="payments"
            green
          />

          <DashboardCard
            title="Egresos del Mes"
            data={dateExpenditureQuery.data}
            materialIcon="money_off"
            red
          />

          <DashboardCard
            title="Total del Mes"
            data={dateRevenueQuery.data - dateExpenditureQuery.data}
            materialIcon="monetization_on"
            primary
          />
        </Row>

        <Row columns={2} levelGap={2}>
          <CreationForms />
        </Row>

      </section>

      <section id='last_movements'>
        <Row columns={1} levelGap={3}>
          <Card title="Últimos Egresos">
            <Table
              columns={recentExpenditureColumns}
              data={recentExpenditureQuery.data || []}
              loading={recentExpenditureQuery.isLoading}
              resultsInfo={false}
              pageSize={5}
            />
          </Card>

          <Card title="Últimos Ingresos">
            <Table
              columns={recentRevenueColumns}
              data={recentRevenueQuery.data || []}
              loading={recentRevenueQuery.isLoading}
              resultsInfo={false}
              pageSize={5}
            />
          </Card>
        </Row>
      </section>
    </div>
  )

}

export default Dashboard