import { IconButton } from "../button"
import './confirm.css'

const Confirm = ({ message, action, closeToast }) => {

	const doAction = () => {
		action()
		closeToast()
	}

	return (
		<div className="confirm-toast--sf">
			<p>{message}</p>
			<div className="confirm-toast-buttons--sf">
				<IconButton icon="cancel" accion={closeToast} />
				<IconButton icon="check_circle" accion={doAction} />
			</div>
		</div>
	)

}

export default Confirm