import axios from "axios"
import { logOut } from "./functions"

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_URI,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		'Authorization': `Bearer ${localStorage.getItem('token')}`
	}
})

axiosInstance.interceptors.response.use(response => response, error => {
	if (error.response.status === 401)
		return logOut()

	return Promise.reject(error)
})