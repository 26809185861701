import './dashboardCard.css'
import { currencyFormat } from '../../utils/format'

const DashboardCard = ({ title, data, materialIcon, primary, red = false, green = false }) => {

  return (
    <div className={`dashboard-card--sf ${primary ? 'primary' : ''}`}>
      <div className="title">
        <h3>{title}</h3>
        <i className="material-icons">{materialIcon}</i>
      </div>
      <div className="content">
        <p className={`${red ? 'red' : ''} ${green ? 'green' : ''}`}>
          {currencyFormat(data)}
        </p>
      </div>
    </div>
  )

}

export default DashboardCard