import React, { Fragment, useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import constants from "../utils/constants";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import DataTable from "react-data-table-component";
import { Rowing } from "@material-ui/icons";
import "../sass/assets/css/style.css";
import styled from "styled-components";
import Swal from 'sweetalert2';
import Autocomplete from "@mui/material/Autocomplete";
import Loading from "./Loading";
import TextField from "@mui/material/TextField";

var moment = require("moment");

//estilos del filter
const TextFields = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

//estilos del boton FIlter
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
`;

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#c1c1c1',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#c1c1c1',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#c1c1c1',
    },
    '&:hover fieldset': {
      borderColor: '#c1c1c1',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#c1c1c1',
    },
  },
});


//componente del filtro
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextFields
      id="search"
      type="text"
      placeholder="Filtrar por Usuario"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      x
    </ClearButton>
  </>
);

const Usuarios = () => {

  // Loading
  const [loading, setLoading] = useState(true);



  const [role_id, setRole_id] = useState(null);
  const [username, setUsername] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [genero, setGenero] = useState("1");
  const [birthdate, setBirthdate] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");


  const [autocompleteRole, setAutocompleteRole] = useState([]);
  const [rows, setRows] = useState(10);

  //Pagination
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  //UseState para consultar los roles
  const [roles, setRoles] = useState([]);



  const role_id1 = JSON.parse(localStorage.getItem("data"));
  const roll = role_id1.role_id;




  //Consultar usuarios
  const fetchUser1 = async () => {
    // setLoading(true);
    const token = localStorage.getItem("token");


    const response = await fetch(constants.api + "auth/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const result = await response.json();


  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchUser1();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);


  //CONSULTAR Estatus para el FORM
  const fetchRoles = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/role",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      let arrayRole = [];
      for (const key in result.data) {
        arrayRole.push({
          label: result.data[key].name,
          id: result.data[key].id,
        });
      }
      setAutocompleteRole(arrayRole);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchRoles();
      }
    }, 3000);
    return () => {
      unmounted = true;
    };
  }, []);
  //Pagination
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Usuario",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row?.person?.nombre,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.person?.email,
      sortable: true,
    },
    {
      name: "Fecha alta",
      selector: (row) =>
        moment(row?.person?.created_at).utc().format('DD-MM-YYYY'),
      sortable: true,
    },
    {
      name: "Rol",
      selector: (row) => row?.role?.name,
      sortable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-info edit-delete-buttons"
          onClick={() => setEdit(row)}
        >
          <i className="material-icons padding-aling-editdelete">edit</i>
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-danger edit-delete-buttons"
          onClick={() => ConfirmAlert(row.id)}
          value="Eliminar"

        >
          <i className="material-icons padding-aling-editdelete">delete</i>
        </button>
      ),
    },
  ];

  //EDIT
  const [userrId, setID] = useState("");

  //useStato para el .map
  const [users, setUsers] = useState([]);

  //const para el filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const userss = users;

  //SubHeader del filtro
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [mensage, setMensage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //alerta para eliminar
  const ConfirmAlert = async (id) => {

    Swal.fire({
      title: '¿Estás seguro?',
      text: "Este cambio será permanente!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Eliminar!',
      cancelButtonText: 'Cancelar',
      iconColor: '#d33'

    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
        Swal.fire(
          'Eliminado!',
          'El usuario fue Eliminado.',
          'success'
        )
      }
    })
  };

  //modal formulario abrir/cerrar
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    cleanData();
    setOpen(false);
  };

  //Alerta
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //delete users
  const onDelete = async (userID) => {
    const token = localStorage.getItem("token");
    const response = await fetch(constants.api + "auth/user/" + userID, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const result = await response.json();
    fetchUser();
  };

  //limpiar form
  const cleanData = () => {
    setFirst_name("");
    setUsername("");
    setLast_name("");
    setGenero("");
    setEmail("");
    setBirthdate("");
    setRole_id(null);
    setPassword("");
    setPassword_confirmation("");
    setID("");
  };

  useEffect(() => {
    fetchUser();
  }, [rows])

  //Consultar usuarios
  const fetchUser = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(constants.api + "auth/user?search[value]=" + filterText, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        start: (page - 1) * rows,
        length: rows,
      }),
    });
    const result = await response.json();

    if (result?.success) {
      setUsers(result.data);
      setTotalData(result.recordsTotal);
    } else if (response.status === 401) {
      Swal.fire({
        icon: 'error',
        title: 'No autenticado, favor de volverse a ingresar.',
        text: 'Intente de nuevo'
      })
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchUser();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, [page, filterText]);


  //crear usuario
  const submitCreateUser = async () => {
    if (first_name == "") {
      setMensage("Añadir campo nombre.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (last_name == "") {
      setMensage("Añadir campo Apellido.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (username == "") {
      setMensage("Añadir campo  Nombre de Usuario.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (genero == "") {
      setMensage("Añadir campo Género.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (email == "") {
      setMensage("Añadir campo E-mail.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (password == "") {
      setMensage("Añadir campo Contraseña.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (password_confirmation == "") {
      setMensage("Añadir campo Confirmar Contraseña.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    const token = localStorage.getItem("token");
    const dateSend = moment(birthdate).format("YYYY/MM/DD");

    if (password === password_confirmation) {
      const response = await fetch(constants.api + "auth/user/store", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          role_id: role_id.id,
          username: username,
          nombre: first_name,
          apellido: last_name,
          email: email,
          fecha_nacimiento: dateSend,
          genero: genero,
          password: password,
          password_confirmation: password_confirmation,
        }),
      });
      const result = await response.json();

      if (result?.success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'El Usuario se ha creado con exito',
          showConfirmButton: false,
          timer: 3000
        })
        fetchUser();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Ocurrio un error al registrar el Usuario.',
          text: 'Intente de nuevo'
        })

        handleClose();
      }
    } else {
      setOpenAlert(true);
      setMensage("Las contraseñas no coinciden.");
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  //editar Usuarios
  const fetchEditUser = async () => {
    if (first_name == "") {
      setMensage("Añadir campo nombre.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (last_name == "") {
      setMensage("Añadir campo Apellido.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (username == "") {
      setMensage("Añadir campo  Nombre de Usuario.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;

    } else if (genero == "") {
      setMensage("Añadir campo Género.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (email == "") {
      setMensage("Añadir campo E-mail.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    const token = localStorage.getItem("token");
    const response = await fetch(constants.api + "auth/user/" + userrId, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        role_id: role_id.id,
        username: username,
        nombre: first_name,
        apellido: last_name,
        email: email,
        birthdate,
        genero: genero,
        password: password,
        password_confirmation: password_confirmation,
      }),
    });
    const result = await response.json();
    //Alerta de editar usuario
    if (result?.success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'El Usuario se ha editado con exito',
        showConfirmButton: false,
        timer: 3000
      })
      fetchUser();
      cleanData();
      handleClose();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error al editar Usuario',
        text: 'Intente de nuevo'
      })
      handleClose();
    }
  };

  //set elements on edit users
  const setEdit = async (user) => {
    const fechaMoment = moment(user.person.fecha_nacimiento).format(
      "YYYY-MM-DD"
    );
    handleOpen();
    setFirst_name(user.person.nombre);
    setUsername(user.username);
    setLast_name(user.person.apellido);
    setGenero(user.person.genero);
    setRole_id({ label: user.role.name, id: user.role_id })
    setEmail(user.person.email);
    setBirthdate(fechaMoment);
    setPassword("");
    setPassword_confirmation("");
    setID(user.id);
  };

  // Onchange Para guardar variables
  const handleInputFirstName = (event) => {
    setFirst_name(event.target.value);
  };

  const handleInputLastName = (event) => {
    setLast_name(event.target.value);
  };

  const handleInputGenero = (event) => {
    setGenero(event.target.value);
  };

  const handleInputUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleInputBirthdate = (event) => {
    setBirthdate(event.target.value);
  };

  const handleInputEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleInputPassword = (event) => {
    setPassword(event.target.value);
  };

  const handleInputPasswordConfirmation = (event) => {
    setPassword_confirmation(event.target.value);
  };

  // const handleInputRole = (event) => {
  //   setRole_id(event.target.value);
  // };

  const handleInputRole = (event, values) => {
    setRole_id(values);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {

      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // const [ asigRol, setAsigRol] = useState(false);

  // const otherPage = () => {
  //   if(roll !== "1")
  //   {window.location.replace("/contactos");}

  // }
  // useEffect(() => {
  //   otherPage();
  //   // if (asigRol == true) {
  //   //   otherPage();
  //   // }
  // }, []);

  return (


    <div className="main-panel panel-main">
      <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
        <div className="container-fluid">
          <div className="navbar-wrapper z-index-card">
            <a className="navbar-brand" href="">
              SELFISH Control Usuarios
            </a>
          </div>
        </div>
      </nav>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 marggin-top">
            <div className="card">
              <div className="card-header-warning mystyles-header z-index-card">
                <h4 className="card-title inline-style">
                  Usuarios
                </h4>
                <p className="card-category button-add-user">
                  <Button
                    type="button"
                    className="btn btn-success buttonaddusers"
                    onClick={handleOpen}
                  >
                    Añadir
                  </Button>
                </p>
              </div>

              <div className="card-body table-responsive">

                <DataTable
                  columns={columns}
                  data={userss}
                  paginationTotalRows={totalData}
                  noDataComponent={
                    loading ? (
                      <Loading />
                    ) : (
                      <p className="sin-datos">No hay datos para mostrar</p>
                    )
                  }
                  paginationServer
                  paginationPerPage={10}
                  onChangeRowsPerPage={(row) => setRows(row)}
                  onChangePage={page => setPage(page)}
                  paginationComponentOptions={{
                    rowsPerPageText: 'Filas por página',
                    rangeSeparatorText: 'de',
                    selectAllRowsItem: true,
                    selectAllRowsItemText: 'Todos',
                  }}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  selectableRows
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Usuario
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="row">

              <div className="col-md-4 label-forms">
                <label className="labels-forms">Rol del Usuario</label>
              </div>
              <div className="col-md-8">
                <Autocomplete
                  className="style-roles margin-forms"
                  onChange={handleInputRole}
                  name="estatus"
                  required
                  disablePortal
                  id="combo-box-demo"
                  getOptionLabel={(options) => options.label}
                  value={role_id}
                  options={autocompleteRole}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <CssTextField id="standard-basic" variant="standard" {...params} />
                  )}
                />
              </div>

              <div className="col-md-4">
                <label>Nombre *</label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={first_name}
                  required
                  className=" width-form"
                  onChange={handleInputFirstName}
                />
              </div>

              <div className="col-md-4">
                <label>Apellido *</label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={last_name}
                  required
                  className=" width-form"
                  onChange={handleInputLastName}
                />
              </div>

              <div className="col-md-4">
                <label>Nombre de Usuario *</label>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  required
                  className=" width-form"
                  onChange={handleInputUsername}
                />
              </div>
              <div className="col-md-4">
                <label>Género *</label>
              </div>
              <div className="col-md-8">
                <select
                  type="text"
                  id="genero"
                  name="genero"
                  value={genero}
                  required
                  className=" width-form"
                  onChange={handleInputGenero}
                >
                  <option>
                    Elije un género
                  </option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                </select>
              </div>

              <div className="col-md-4">
                <label>Fecha de Nacimiento *</label>
              </div>
              <div className="col-md-8">
                <input
                  type="date"
                  id="birthdate"
                  name="birthdate"
                  value={birthdate}
                  required
                  className=" width-form"
                  onChange={handleInputBirthdate}
                />
              </div>

              <div className="col-md-4">
                <label>E-mail *</label>
              </div>
              <div className="col-md-8">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}

                  className=" width-form"
                  onChange={handleInputEmail}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="pass1">Contraseña *</label>
              </div>
              <div className="col-md-8">
                <input
                  type="password"
                  id="pass1"
                  name="password"
                  value={password}
                  placeholder=" Minimo 8 Caracteres "
                  required
                  className=" width-form "
                  onChange={handleInputPassword}
                  minLength={8}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="pass2">Confirmar Contraseña *</label>
              </div>
              <div className="col-md-8">
                <input
                  type="password"
                  id="pass2"
                  name="password_confirmation"
                  placeholder="Minimo 8 Caracteres "
                  value={password_confirmation}
                  required
                  className=" width-form"
                  onChange={handleInputPasswordConfirmation}
                  minLength={8}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div id="resultadocrear"></div>
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>

            {userrId ? (
              <button
                onClick={fetchEditUser}
                type="button"
                className="button btn-primary"
                data-dismiss="modal"
                id="actionCrearUsuario"
              >
                Editar
              </button>
            ) : (
              <button
                onClick={submitCreateUser}
                type="button"
                className="button btn-primary"
                data-dismiss="modal"
                id="actionCrearUsuario"
              >
                CREAR
              </button>
            )}
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        message={mensage}
        key={vertical + horizontal}
      />
    </div>

  );
};

export default Usuarios;

if (document.getElementById("usuarios")) {
  ReactDOM.render(<Usuarios />, document.getElementById("usuarios"));
}
