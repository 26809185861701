import { toast, Slide } from "react-toastify"
import Confirm from "../components/core/ui/Confirm"

const properties = {
	isLoading: false,
	autoClose: 3000,
	closeOnClick: true,
	draggable: false
}

const notification = {
	loading: text => toast.loading(text, { transition: Slide }),
	success: (id, texto) => {
		toast.update(id, {
			render: texto,
			type: toast.TYPE.SUCCESS,
			...properties
		})
	},
	error: (id, text, callback) => {
		toast.update(id, {
			render: text,
			type: toast.TYPE.ERROR,
			...properties,
			onClose: callback
		})
	},
	confirm: (message, action) => {
		toast.warning(<Confirm {...{ message, action }} />, {
			isLoading: false,
			autoClose: 6000,
			closeOnClick: false,
			closeButton: false,
			draggable: false,
			icon: false
		})
	},

	successMessage: (text, props = {}) => (
		toast.success(text, { ...properties, ...props })
	)
}

export default notification