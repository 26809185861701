import './modal.css'
import ReactModal from 'react-modal'
ReactModal.setAppElement('#root')

const body = document.querySelector('body')

const Modal = ({ open, setModal, title, children }) => {

	const afterOpen = () => {
		if (body.clientHeight > window.innerHeight)
			body.style.paddingRight = '17px'

		/* 		const firstInput = document.querySelector(`#${idFormulario} input`)
				firstInput.focus() */
	}

	const afterClose = () => body.style.paddingRight = '0px'

	return (
		<ReactModal
			isOpen={open}
			contentLabel="Selfish Modal"
			onRequestClose={() => setModal(false)}
			onAfterOpen={afterOpen}
			onAfterClose={afterClose}
			className="modal--sf"
			bodyOpenClassName="modal-open--sf"
			overlayClassName="modal-background--sf"
			closeTimeoutMS={300}
		>
			<h3>{title}</h3>
			{children}
		</ReactModal>
	)

}

export default Modal