import SideNav from '../sidenav/SideNav'
import './layout.css'

const Layout = ({ children, header }) => {

  return (
    <div className="layout--sf">
      <SideNav />
      <section className="content--sf">
        <h1>{header}</h1>
        {children}
      </section>
    </div>
  )

}

export default Layout