import './table.css'
import { useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { IconButton } from '../button'

const DEFAULT_FILTERS = {}

const ControlledTable = ({
  columns,
  useQueryFetch,
  customPageSize,
  filters = DEFAULT_FILTERS
}) => {

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: customPageSize })

  const fetchDataOptions = {
    ...pagination, filters
  }

  const dataQuery = useQueryFetch(fetchDataOptions)


  const table = useReactTable({
    data: dataQuery.data?.data ?? [],
    columns,
    pageCount: Math.ceil(dataQuery.data?.recordsFiltered / pagination.pageSize),
    state: { pagination },
    onPaginationChange: setPagination,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    if (table)
      setPagination({ ...pagination, pageIndex: 0 })
  }, [filters])

  return (
    <div className='table--sf'>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {
                    header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {
            table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          }

          <tr className='pagination--sf'>
            <td colSpan={1000}>
              <div>
                {dataQuery.isFetching ? <p>Cargando...</p> : (
                  <>
                    <p>Mostrando {table.getRowModel().rows.length} de {dataQuery.data?.recordsFiltered} filas</p>
                    <p>Página {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}</p>
                  </>
                )}
                <EnterInput
                  initialValue={table.getState().pagination.pageIndex + 1}
                  onEnter={page => table.setPageIndex(page - 1)}
                  disabled={dataQuery.isFetching}
                  min="1"
                  step="1"
                  max={table.getPageCount() || 0}
                  className="input-pagination--sf"
                />
                <IconButton
                  accion={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage() || dataQuery.isFetching}
                  icon="keyboard_double_arrow_left"
                />
                <IconButton
                  accion={table.previousPage}
                  disabled={!table.getCanPreviousPage() || dataQuery.isFetching}
                  icon="keyboard_arrow_left"
                />
                <IconButton
                  accion={table.nextPage}
                  disabled={!table.getCanNextPage() || dataQuery.isFetching}
                  icon="keyboard_arrow_right"
                />
                <IconButton
                  accion={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage() || dataQuery.isFetching}
                  icon="keyboard_double_arrow_right"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )

}


const EnterInput = ({ initialValue, onEnter, ...props }) => {
  const [value, setValue] = useState(initialValue)

  const handleKeyPress = (event) => {
    if (event.key === 'Enter')
      onEnter(value)
  }

  const handleChange = (event) => setValue(event.target.value)

  useEffect(() => setValue(initialValue), [initialValue])

  return (
    <input
      type="number"
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      {...props}
    />
  )
}


export default ControlledTable