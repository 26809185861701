import Modal from "../core/ui/Modal"
import { Form, Formik } from "formik"
import { Input } from "../core/input"
import { Select } from "../core/select"
import { Button } from "../core/button/Button"
import { format } from "date-fns"
import { TextArea } from "../core/textarea"
import CheckBox from "../core/checkbox/CheckBox"
import { useCompanies } from "../../hooks/company"
import { usePaymentMethods, useRevenueTypes } from "../../hooks/parameters"
import { useEditRevenue } from "../../hooks/operation"
import { useQueryClient } from "@tanstack/react-query"
import { getMutationOptions } from "../../utils/functions"


const EditRevenue = ({ modal, setModal, revenue }) => {

	const companiesQuery = useCompanies()
	const revenueTypesQuery = useRevenueTypes()
	const paymentMethodsQuery = usePaymentMethods()
	const queryClient = useQueryClient()
	const editRevenue = useEditRevenue(getMutationOptions({
		loadingMessage: 'Modificando Ingreso',
		successMessage: 'Ingreso Modificado',
		errorMessage: 'Error al Modificar el Ingreso'
	}))

	const updateRevenue = async data => {
		editRevenue.mutate({ revenueId: revenue.id, data }, {
			onSuccess: () => {
				// Update active query
				queryClient.refetchQueries(['revenue'], { type: 'active' })
				setModal(false)
			}
		})
	}

	return (
		<Modal
			open={modal}
			setModal={setModal}
			title="Editar Ingreso"
		>
			<Formik
				initialValues={{
					emisor_id: revenue?.emisor.id,
					tipo_id: revenue?.tipo.id,
					metodo_id: revenue?.metodo.id,
					comentarios: revenue?.comentarios || '',
					monto: revenue?.monto,
					fecha_movimiento: format(new Date(revenue?.fecha_movimiento || '2000-12-25'), 'y-MM-dd'),
					aplica_iva: revenue?.aplica_iva === 'Si' ? true : false
				}}
				onSubmit={updateRevenue}
			>
				<Form>
					<Select
						label="Emisor"
						name="emisor_id"
						horizontal
						required
					>
						<option value="">Seleccione Emisor</option>
						{
							companiesQuery.data.map(company => (
								<option key={company.id} value={company.id}>{company.name}</option>
							))
						}
					</Select>

					<Select
						label="Tipo"
						name="tipo_id"
						horizontal
						required
					>
						<option value="">Seleccione Tipo</option>
						{
							revenueTypesQuery.data.map(type => (
								<option key={type.id} value={type.id}>{type.value}</option>
							))
						}
					</Select>

					<Select
						label="Método"
						name="metodo_id"
						horizontal
						required
					>
						<option value="">Seleccione Método</option>
						{
							paymentMethodsQuery.data.map(method => (
								<option key={method.id} value={method.id}>{method.value}</option>
							))
						}
					</Select>

					<TextArea
						label="Comentarios"
						name="comentarios"
						placeholder="Escribe tus comentarios"
						horizontal
					/>

					<Input
						label="Monto"
						name="monto"
						type="number"
						placeholder="Monto del ingreso"
						horizontal
						required
					/>

					<Input
						label="Fecha"
						name="fecha_movimiento"
						type="date"
						placeholder="Fecha"
						horizontal
						required
					/>

					<CheckBox
						label="IVA"
						name="aplica_iva"
						horizontal
					/>

					<Button
						texto="Guardar Cambios"
						formulario
						disabled={editRevenue.isLoading}
						estilo={{ margin: '40px auto 0px auto' }}
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default EditRevenue