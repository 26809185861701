import Circle from "./Circle"
import Card from "./Card"
import LoginImage from "./LoginImage"
import LoginForm from "./Form"
import "./login.css"

const Login = () => {
	return (
		<div className="login--sf">
			<Circle position={{ top: '-55%', left: '60%' }} />
			<Circle position={{ top: '55%', left: '-15%' }} />

			<Card>
				<LoginImage />
				<LoginForm />
			</Card>
		</div>
	)
}

export default Login