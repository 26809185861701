import { axiosInstance } from '../utils/instances'
import { useMutation, useQuery } from '@tanstack/react-query'


/* API requests */
const getRecentOperation = async type => {
  const { data: { data } } = await axiosInstance.post(`/auth/operacion/${type}`, {
    'columns[0][name]': 'id',
    'order[0][column]': '0',
    'order[0][dir]': 'desc',
    'start': 0,
    'length': 5
  })

  return data
}

const createRevenue = async revenue => {
  const { data: { data } } = await axiosInstance.post('/auth/operacion/ingresos/store', {
    ...revenue,
    receptor_id: 1,  // Selfish
    aplica_iva: revenue.aplica_iva ? 'Si' : 'No'
  })

  return data
}

const createExpenditure = async expenditure => {
  const { data: { data } } = await axiosInstance.post('/auth/operacion/egresos/store', {
    ...expenditure,
    emisor_id: 1,  // Selfish
    aplica_iva: expenditure.aplica_iva ? 'Si' : 'No'
  })

  return data
}

const getOperation = async (type, { pageSize, pageIndex, filters }) => {
  const url = `/auth/operacion/${[type, filters.year, filters.month].filter(Boolean).join('/')}`
  const { data: { data, recordsFiltered } } = await axiosInstance.post(url, {
    'columns[0][name]': 'fecha_movimiento',
    'order[0][column]': '0',
    'order[0][dir]': 'desc',
    'start': pageIndex * pageSize,
    'length': pageSize,
    'search[value]': filters.searchValue || '',
    'tipo_id': filters.revenueTypeId
  })

  return {
    data,
    recordsFiltered
  }
}

const getRevenue = async queryData => {
  return await getOperation('ingresos', queryData)
}

const getExpenditure = async queryData => {
  return await getOperation('egresos', queryData)
}

const deleteRevenue = async revenueId => {
  const { data: { success } } = await axiosInstance.delete(`/auth/operacion/${revenueId}`)
  return success
}

const deleteExpenditure = async expenditureId => {
  const { data: { success } } = await axiosInstance.delete(`/auth/operacion/${expenditureId}`)
  return success
}

const editRevenue = async (revenueId, data) => {
  const { data: { success } } = await axiosInstance.put(`auth/operacion/${revenueId}`, {
    ...data,
    aplica_iva: data.aplica_iva ? 'Si' : 'No'
  })
  return success
}

const editExpenditure = async (expenditureId, data) => {
  const { data: { success } } = await axiosInstance.put(`auth/operacion/${expenditureId}`, {
    ...data,
    aplica_iva: data.aplica_iva ? 'Si' : 'No'
  })
  return success
}


/* Operation custom hooks */
export const useRecentExpenditure = () => {
  return useQuery(
    ['recentExpenditure'],
    () => getRecentOperation('egresos')
  )
}

export const useRecentRevenue = () => {
  return useQuery(
    ['recentRevenue'],
    () => getRecentOperation('ingresos')
  )
}

export const useCreateRevenue = (options = {}) => {
  return useMutation(
    ['createRevenue'],
    createRevenue,
    options
  )
}

export const useCreateExpenditure = (options = {}) => {
  return useMutation(
    ['createExpenditure'],
    createExpenditure,
    options
  )
}

export const useGetRevenue = requestOptions => {
  return useQuery(
    ['revenue', requestOptions],
    () => getRevenue(requestOptions),
    { keepPreviousData: true }
  )
}

export const useGetExpenditure = requestOptions => {
  return useQuery(
    ['expenditure', requestOptions],
    () => getExpenditure(requestOptions),
    { keepPreviousData: true }
  )
}

export const useDeleteRevenue = (options = {}) => {
  return useMutation(
    ['deleteRevenue'],
    deleteRevenue,
    options
  )
}

export const useDeleteExpenditure = (options = {}) => {
  return useMutation(
    ['deleteExpenditure'],
    deleteExpenditure,
    options
  )
}

export const useEditRevenue = (options = {}) => {
  return useMutation(
    ['editRevenue'],
    async data => await editRevenue(data.revenueId, data.data),
    options
  )
}

export const useEditExpenditure = (options = {}) => {
  return useMutation(
    ['editExpenditure'],
    async data => await editExpenditure(data.expenditureId, data.data),
    options
  )
}
