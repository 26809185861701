import React, { useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import constants from "../utils/constants";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import "../sass/assets/css/style.css";
import Swal from "sweetalert2";
import Loading from "./Loading";

//estilos del filter
const TextFields = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

//estilos del boton FIlter
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
`;
//componente del filtro
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextFields
      id="search"
      type="text"
      placeholder="Filtrar por nombre"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const Module = () => {
  // Loading
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);

  //Modal form cerrar/abrir
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    cleanData();
    setOpen(false);
  };

  const [name, setName] = useState("");
  const [modules, setModules] = useState("");

  const [moduleid, setID] = useState("");

  //Snackbar alerta
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //map a contactos
  const [module, setModule] = useState([]);

  //mensaje de crear usuarios
  const [mensage, setMensage] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  //Alerta Snackbar
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
    handleClose();
  };

  //limpiar form
  const cleanData = () => {
    setName("");
    setModules("");
    setID("");
  };

  //alerta para eliminar
  const ConfirmAlert = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
        Swal.fire("Eliminado!", "El Modulo fue Eliminado.", "success");
      }
    });
  };

  //PAGINACION
  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Modulo",
      selector: (row) => row?.model,
      sortable: true,
    },

    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-info edit-delete-buttons"
          onClick={() => setEdit(row)}
        >
          <i className="material-icons padding-aling-editdelete">edit</i>
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <input
          className="delete-style"
          type="button"
          onClick={() => ConfirmAlert(row.id)}
          value="Eliminar"
        />
      ),
    },
  ];

  //const para el filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const moduless = module.filter(
    (item) =>
      item?.name && item?.name.toLowerCase().includes(filterText.toLowerCase())
  );
  //SubHeader del filtro
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //Crear Modulo
  const fetchModule = async () => {
    if (name == "") {
      setMensage("Añadir campo Nombre.");
      return;
    } else if (modules == "") {
      alert("Añadir campo Modulo");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/module/store", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          name: name,
          model: modules,
        }),
      });
      const result = await response.json();

      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El modulo se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchModuleRead();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al registrar el Modulo",
          text: "Intente de nuevo",
        });
        handleClose();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Editar contactos
  const fetchEditContact = async () => {
    if (name == "") {
      setMensage("Añadir campo Nombre.");
      return;
    } else if (modules == "") {
      alert("Añadir campo Modulo");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/module/" + moduleid, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          name: name,
          model: modules,
        }),
      });
      const result = await response.json();

      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Modulo se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchModuleRead();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar el Modulo",
          text: "Intente de nuevo",
        });
        handleClose();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Set elements on edit contact
  const setEdit = async (module) => {
    handleOpen();
    setName(module?.name);
    setModule(module?.model);
    setID(module.id);
  };

  //Consultar Modulos
  const fetchModuleRead = async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(constants.api + "auth/module", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const result = await response.json();
    if (result?.success) {
      setModule(result.data);
    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchModuleRead();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  //delete contactos
  const onDelete = async (moduleID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/module/" + moduleID, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const result = await response.json();
      fetchModuleRead();
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //onClick´s
  const handleInputName = (event) => {
    setName(event.target.value);
  };

  const handleInputModule = (event) => {
    setModules(event.target.value);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="wrapper-hidden ">
      <SideNav />
      <div className="main-panel">
        <div className="wrapper wrapper-style ">
          <div className="main-panel panel-main">
            <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
              <div className="container-fluid">
                <div className="navbar-wrapper z-index-card">
                  <a className="navbar-brand" href="">
                    SELFISH Control Modulos
                  </a>
                </div>
              </div>
            </nav>

            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="card z-index-card">
                      <div className="card-header card-header-warning header-card">
                        <h4 className="card-title inline-style">Modulos</h4>
                        <p className="card-category button-add-user">
                          <Button
                            type="button"
                            className="btn btn-success buttonaddusers"
                            onClick={handleOpen}
                          >
                            <i className="material-icons padding-aling">add</i>
                            Añadir
                          </Button>
                        </p>
                      </div>

                      <div className="card-body table-responsive">
                        <DataTable
                          columns={columns}
                          data={moduless}
                          noDataComponent={<Loading />}
                          pagination
                          paginationResetDefaultPage={resetPaginationToggle}
                          subHeader
                          subHeaderComponent={subHeaderComponentMemo}
                          selectableRows
                          persistTableHead
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box className="boxstyles responsive-box">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Crear Contacto
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Nombre</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="name"
                        required
                        className=" tipoUser"
                        name="name"
                        value={name}
                        onChange={handleInputName}
                      />
                    </div>

                    <div className="col-md-4">
                      <label>Modulo</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        value={modules}
                        type="text"
                        id="modules"
                        required
                        className=" tipoUser"
                        name="modules"
                        onChange={handleInputModule}
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <div id="resultadocrear"></div>
                <button
                  onClick={handleClose}
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                {moduleid ? (
                  <button
                    onClick={fetchEditContact}
                    type="button"
                    className="button btn-primary"
                    data-dismiss="modal"
                    id="actionCrearUsuario"
                  >
                    Editar
                  </button>
                ) : (
                  <button
                    onClick={fetchModule}
                    type="button"
                    className="button btn-primary"
                    data-dismiss="modal"
                    id="actionCrearUsuario"
                  >
                    CREAR
                  </button>
                )}
              </div>
            </Box>
          </Modal>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openAlert}
            onClose={handleCloseAlert}
            message={mensage}
            key={vertical + horizontal}
          />
        </div>
      </div>
    </div>
  );
};

export default Module;

if (document.getElementById("module")) {
  ReactDOM.render(<Module />, document.getElementById("module"));
}
