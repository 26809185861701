import './card.css'

const Card = ({ title, children, className = '' }) => {

  return (
    <div className={`card--sf ${className}`}>
      {title ?
        <div className="title">
          <h2>{title}</h2>
        </div>
        : null}
      <div className="content">
        {children}
      </div>
    </div>
  )

}

export default Card