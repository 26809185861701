import "./statistic.css"
import Row from "../core/layout/Row"
import Card from "../core/ui/Card"
import { Form, Formik } from "formik"
import { Button } from "../core/button"
import { Select } from "../core/select"
import { getYears } from "../../utils/functions"
import { months } from "../../utils/constants"
import { useExpenditureTypes, useRevenueTypes } from "../../hooks/parameters"
import RevenueList from "./RevenueList"
import ExpenditureList from "./ExpenditureList"
import StatisticCharts from "./StatisticCharts"
import { useStatisticFilters } from "../../store/statisticStore"
import notification from "../../utils/notification"


const Statistic = () => {

	const expenditureTypesQuery = useExpenditureTypes()
	const revenueTypesQuery = useRevenueTypes()
	const filters = useStatisticFilters()

	const changeFilters = values => {
		filters.setFilters(values)
		notification.successMessage('Filtros Aplicados', { autoClose: 1500 })
	}

	return (
		<>
			<Row>
				<Card>
					<Formik
						initialValues={filters}
						onSubmit={changeFilters}
					>
						<Form className="statistic_search--sf">
							<Select
								horizontal
								label="Año:"
								name="year"
								required
							>
								<option value="">Selecciona el año</option>
								{getYears().map(year => (
									<option value={year} key={`y-${year}`}>{year}</option>
								))}
							</Select>

							<Select
								horizontal
								label="Mes:"
								name="month"
							>
								<option value="">Todos</option>
								{months.map((month, i) => (
									<option value={i + 1} key={`m-${i}`}>{month}</option>
								))}
							</Select>

							<Select
								horizontal
								label="Tipo de Ingreso:"
								name="revenueTypeId"
							>
								<option value="">Todos</option>
								{revenueTypesQuery.data.map(type => (
									<option value={type.id} key={type.id}>{type.value}</option>
								))}
							</Select>

							<Select
								horizontal
								label="Tipo de Egreso:"
								name="expenditureTypeId"
							>
								<option value="">Todos</option>
								{expenditureTypesQuery.data.map(type => (
									<option value={type.id} key={type.id}>{type.value}</option>
								))}
							</Select>
							<Button texto="Filtrar" formulario />
						</Form>
					</Formik>
				</Card>
			</Row>

			<Row>
				<StatisticCharts />
			</Row>

			<Row>
				<RevenueList />
				<ExpenditureList />
			</Row>
		</>
	)

}

export default Statistic