import { queryOptions } from '../utils/constants'
import { axiosInstance } from '../utils/instances'
import { useQuery } from '@tanstack/react-query'


const getParameter = async body => {
  const { data: { data } } = await axiosInstance.post('/auth/parametro', body)

  return data.map(type => ({
    id: type.id,
    value: type.valor
  }))
}

const getRevenueTypes = async () => {
  return await getParameter({ 'search[grupo]': 'Ingresos' })
}

const getPaymentMethods = async () => {
  return await getParameter({
    'search[grupo]': 'Operaciones',
    'search[sub_grupo]': 'Medios de Pago'
  })
}

const getExpenditureTypes = async () => {
  return await getParameter({ 'search[grupo]': 'Egresos' })
}


export const useRevenueTypes = () => {
  return useQuery(
    ['revenueTypes'],
    getRevenueTypes,
    queryOptions
  )
}

export const usePaymentMethods = () => {
  return useQuery(
    ['paymentMethods'],
    getPaymentMethods,
    queryOptions
  )
}

export const useExpenditureTypes = () => {
  return useQuery(
    ['expenditureTypes'],
    getExpenditureTypes,
    queryOptions
  )
}