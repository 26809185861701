import { Field } from "formik"
import './textarea.css'

export const TextArea = ({ name, label, placeholder, required, horizontal }) => {

  return (
    <div className={`textarea--sf ${horizontal ? 'horizontal' : ''}`}>
      <label>{label}</label>
      <span>
        <Field as="textarea" name={name} placeholder={placeholder} required={required} />
      </span>
    </div>
  )

}