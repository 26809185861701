import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../utils/instances'
import { queryOptions } from '../utils/constants'


const getCompanies = async () => {
  const { data: { data } } = await axiosInstance.post('/auth/empresa')

  return data.map(company => ({
    id: company.id,
    name: company.nombre_comercial
  }))
}


export const useCompanies = () => {
  return useQuery(
    ['companies'],
    getCompanies,
    queryOptions
  )
}