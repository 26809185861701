import React from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";

const People = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="wrapper-hidden ">
      <SideNav />
      <div className="main-panel">
        <div className="wrapper wrapper-style ">
          <div className="main-panel panel-main">
            <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
              <div className="container-fluid">
                <div className="navbar-wrapper z-index-card">
                  <a className="navbar-brand" href="">
                    SELFISH Control Personas
                  </a>
                </div>
                <div className="collapse navbar-collapse justify-content-end margin-users-search">
                  <form className="navbar-form">
                    <div className="input-group no-border">
                      <input
                        type="text"
                        defaultValue="search"
                        id="search"
                        className=""
                        placeholder="Buscar"
                      />
                      <button
                        type="submit"
                        className="btn btn-white btn-round btn-just-icon"
                        id="actionbuscar"
                      >
                        <i className="material-icons">search</i>
                        <div className="ripple-container"></div>
                      </button>
                    </div>
                  </form>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a id="todos">Todos</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="card z-index-card">
                      <div className="card-header card-header-warning header-card">
                        <h4 className="card-title inline-style">Personas</h4>
                        <p className="card-category button-add-user">
                          <Button
                            type="button"
                            className="btn btn-success buttonaddusers"
                            onClick={handleOpen}
                          >
                            <i className="material-icons padding-aling">add</i>
                            Añadir
                          </Button>
                        </p>
                      </div>

                      <div className="card-body table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>E-mail</th>
                              <th>Teléfono</th>
                              <th>Teléfono Móvil</th>
                              <th>Código del país</th>
                            </tr>
                          </thead>
                          <tbody id="resultado">
                            <tr>
                              <td>Juan Alberto</td>
                              <td>adamtmaxfield@gmail.com</td>
                              <td>3334556456</td>
                              <td>3334556456</td>
                              <td>+52</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-info edit-delete-buttons"
                                  /* onClick={()=>onDelete()}  */
                                >
                                  <i className="material-icons padding-aling-editdelete">
                                    edit
                                  </i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger edit-delete-buttons"
                                  /* onClick={()=>onDelete()}  */
                                >
                                  <i className="material-icons padding-aling-editdelete">
                                    delete
                                  </i>
                                </button>
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>Ivan Iñigues</td>
                              <td>ivan@gmail.com</td>
                              <td>3366998855</td>
                              <td>3366554422</td>
                              <td>+52</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-info edit-delete-buttons"
                                  /* onClick={()=>onDelete()}  */
                                >
                                  <i className="material-icons padding-aling-editdelete">
                                    edit
                                  </i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger edit-delete-buttons"
                                  /* onClick={()=>onDelete(user.id)}  */
                                >
                                  <i className="material-icons padding-aling-editdelete">
                                    delete
                                  </i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box className="boxstyles responsive-box">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Crear Personas
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <form>
                  <div className="row">
                  
                    <div className="col-md-4">
                      <label>Nombre</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="first_name"
                        required
                        className=" tipoUser"
                      />
                    </div>

                    <div className="col-md-4">
                      <label>Apellido</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="last_name"
                        required
                        className=" tipoUser"
                      />
                    </div>

                    <div className="col-md-4">
                      <label>E-mail</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="email"
                        id="email"
                        required
                        className=" tipoUser"
                      />
                    </div>

                    <div className="col-md-4">
                      <label>Teléfono</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="tel"
                        id="phone"
                        required
                        className=" tipoUser"
                      />
                    </div>

                    <div className="col-md-4">
                      <label>Teléfono Móvil</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="tel"
                        id="mobile_phone"
                        required
                        className=" tipoUser"
                      />
                    </div>

                    <div className="col-md-4">
                      <label>Código del País</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="country_code"
                        required
                        className=" tipoUser"
                      />
                    </div>

                    {/*  <div className="col-md-4">
                                            <label>Industria</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input
                                                type="text"
                                                id="industriaUser"
                                                required
                                                className=" tipoUser"
                                            />
                                        </div> */}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <div id="resultadocrear"></div>
                <button
                  onClick={() => handleClose}
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  /* onClick={submitCreateUser} */ type="button"
                  className="button btn-primary"
                  data-dismiss="modal"
                  id="actionCrearUsuario"
                >
                  CREAR
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default People;

if (document.getElementById("people")) {
  ReactDOM.render(<People />, document.getElementById("people"));
}
