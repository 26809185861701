import React, { useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import constants from "../utils/constants";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import "../sass/assets/css/style.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Loading from "./Loading";

//estilos del filter
const TextFields = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

//estilos del boton FIlter
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
`;

const Error = styled.div`
  background-color: #ffb3b3;
  color: white;
  text-align: center;
  padding: 3px;
  margin-top: 0px;
  margin-bottom: 5px;
`;
//componente del filtro
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextFields
      id="search"
      type="text"
      placeholder="Filtrar por grupo"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const Parametros = () => {
  // Loading
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = React.useState(false);

  const [error, setError] = useState(false);

  //Modal form cerrar/abrir
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    cleanData();
    setOpen(false);
  };

  const [grupo, setGrupo] = useState("");
  const [sub_grupo, setSub_grupo] = useState("");
  const [valor, setValor] = useState("");

  //Edit
  const [parametroid, setID] = useState("");

  //Snackbar alerta
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //map a Parametros
  const [parametro, setParametro] = useState([]);

  //mensaje de crear usuarios
  const [mensage, setMensage] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  //Alerta Snackbar
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
    handleClose();
  };

  //limpiar form
  const cleanData = () => {
    setGrupo("");
    setSub_grupo("");
    setValor("");
    setID("");
  };

  //alert 2
  const [openAlert1, setOpenAlert1] = React.useState(false);
  const [mensage1, setMensage1] = React.useState(false);
  const [state1, setState1] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //Alerta1
  const { vertical1, horizontal1, message1 } = state1;
  const handleClick1 = (newState) => () => {
    setState1({ ...newState });
  };

  //cerrar alerta al crear1
  const handleCloseAlert1 = () => {
    setOpenAlert1(false);
  };

  //alerta para eliminar
  const ConfirmAlert = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
        Swal.fire("Eliminado!", "El Parametro fue Eliminado.", "success");
      }
    });
  };

  //PAGINACION
  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Grupo",
      selector: (row) => row?.grupo,
      sortable: true,
    },
    {
      name: "Sub Grupo",
      selector: (row) => row?.sub_grupo,
      sortable: true,
    },
    {
      name: "Valor",
      selector: (row) => row?.valor,
      sortable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-info edit-delete-buttons"
          onClick={() => setEdit(row)}
        >
          <i className="material-icons padding-aling-editdelete">edit</i>
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-danger edit-delete-buttons"
          onClick={() => ConfirmAlert(row.id)}
          value="Eliminar"
        >
          <i className="material-icons padding-aling-editdelete">delete</i>
        </button>
      ),
    },
  ];

  //const para el filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const parameterss = parametro.filter(
    (item) =>
      item?.grupo &&
      item?.grupo.toLowerCase().includes(filterText.toLowerCase())
  );
  //SubHeader del filtro
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //Crear Parametros
  const fetchParametro = async () => {
    if (grupo == "") {
      setMensage1("Añadir campo Grupo.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (sub_grupo == "") {
      setMensage1("Añadir campo Sub Grupo.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (valor == "") {
      setMensage1("Añadir campo Valor.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (!grupo.trim() || !sub_grupo.trim() || !valor.trim()) {
      setError(true);
      return;
    }
    setError(false);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/parametro/store", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          grupo: grupo,
          sub_grupo: sub_grupo,
          valor: valor,
        }),
      });
      const result = await response.json();

      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Parametro se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchParametrosRead();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al registrar el Parametro",
          text: "Intente de nuevo",
        });
        handleClose();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Editar Parametros
  const fetchEditContact = async () => {
    if (grupo == "") {
      setMensage1("Añadir campo Grupo.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (sub_grupo == "") {
      setMensage1("Añadir campo Sub Grupo.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (valor == "") {
      setMensage1("Añadir campo Valor.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/parametro/" + parametroid,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            grupo: grupo,
            sub_grupo: sub_grupo,
            valor: valor,
          }),
        }
      );
      const result = await response.json();

      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Parametro se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchParametrosRead();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar el Parametro",
          text: "Intente de nuevo",
        });
        handleClose();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Set elements on edit contact
  const setEdit = async (parametro) => {
    handleOpen();
    setGrupo(parametro?.grupo);
    setSub_grupo(parametro?.sub_grupo);
    setValor(parametro?.valor);
    setID(parametro.id);
  };

  //Consultar Contactos
  const fetchParametrosRead = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(constants.api + "auth/parametro", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const result = await response.json();

    if (result?.success) {
      setParametro(result.data);
    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchParametrosRead();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  //delete Parametro
  const onDelete = async (parametroID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/parametro/" + parametroID,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchParametrosRead();
    } catch (error) {
      alert("Error al eliminar parametro, intentelo de nuevo", error);
    }
  };

  //onClick´s
  const handleInputGrupo = (event) => {
    setGrupo(event.target.value);
  };

  const handleInputSubgrupo = (event) => {
    setSub_grupo(event.target.value);
  };

  const handleInputValor = (event) => {
    setValor(event.target.value);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="main-panel panel-main">
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
          <div className="container-fluid">
            <div className="navbar-wrapper z-index-card mediaContact">
              <a className="navbar-brand" href="">
                SELFISH Control Parametros
              </a>
            </div>
          </div>
        </nav>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">Parametros</h4>
                    <p className="card-category button-add-user">
                      <Button
                        type="button"
                        className="btn btn-success buttonaddusers"
                        onClick={handleOpen}
                      >
                        <i className="material-icons padding-aling">add</i>
                        Añadir
                      </Button>
                    </p>
                  </div>

                  <div className="card-body table-responsive">
                    <DataTable
                      columns={columns}
                      data={parameterss}
                      noDataComponent={
                        loading ? (
                          <Loading />
                        ) : (
                          <p className="sin-datos">
                            No hay datos para mostrar
                          </p>
                        )
                      }
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      selectableRows
                      persistTableHead
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles responsive-box">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Crear Privilegio
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12">
                  {error ? (
                    <Error>Todos los campos son obligatorios</Error>
                  ) : null}
                </div>
                <div className="col-md-4">
                  <label>Grupo</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="grupo"
                    required
                    className=" tipoUser"
                    name="grupo"
                    value={grupo}
                    onChange={handleInputGrupo}
                  />
                </div>

                <div className="col-md-4">
                  <label>Sub Grupo</label>
                </div>
                <div className="col-md-8">
                  <input
                    value={sub_grupo}
                    type="text"
                    id="sub_grupo"
                    required
                    className=" tipoUser"
                    name="sub_grupo"
                    onChange={handleInputSubgrupo}
                  />
                </div>

                <div className="col-md-4">
                  <label>Valor</label>
                </div>
                <div className="col-md-8">
                  <input
                    value={valor}
                    type="text"
                    id="valor"
                    required
                    className=" tipoUser"
                    name="valor"
                    onChange={handleInputValor}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div id="resultadocrear"></div>
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {parametroid ? (
              <button
                onClick={fetchEditContact}
                type="button"
                className="button btn-primary"
                data-dismiss="modal"
                id="actionCrearUsuario"
              >
                Editar
              </button>
            ) : (
              <button
                onClick={fetchParametro}
                type="button"
                className="button btn-primary"
                data-dismiss="modal"
                id="actionCrearUsuario"
              >
                CREAR
              </button>
            )}
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        message={mensage}
        key={vertical + horizontal}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert1}
        onClose={handleCloseAlert1}
        message={mensage1}
        key={vertical1 + horizontal1}
      />
    </>
  );
};

export default Parametros;

if (document.getElementById("parametros")) {
  ReactDOM.render(<Parametros />, document.getElementById("parametros"));
}
