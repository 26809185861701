import React, { useEffect, useState, useAsync } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import constants from "../utils/constants";
import styled from "styled-components";
import "../sass/assets/css/style.css";
import DataTable from "react-data-table-component";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from "@mui/material/Snackbar";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import Loading from "./Loading";

var moment = require("moment");

//estilos del filter
const TextFields = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

//estilos del boton FIlter
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
`;

const Error = styled.div`
  background-color: #ffb3b3;
  color: white;
  text-align: center;
  padding: 3px;
  margin-top: 0px;
  margin-bottom: 5px;
`;

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#c1c1c1",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#c1c1c1",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#c1c1c1",
    },
    "&:hover fieldset": {
      borderColor: "#c1c1c1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#c1c1c1",
    },
  },
});

//componente del filtro
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextFields
      id="search"
      type="text"
      placeholder="Filtrar por Nombre"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const Renovaciones = () => {
  // Loading
  const [loading, setLoading] = useState(true);

  function useAsync(asyncFn, onSuccess) {
    useEffect(() => {
      let isActive = true;
      asyncFn().then((data) => {
        if (isActive) onSuccess(data);
      });
      return () => {
        isActive = false;
      };
    }, [asyncFn, onSuccess]);
  }

  //Validar delete
  const ConfirmAlert = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
        Swal.fire("Eliminada!", "La Renovación fue Eliminada.", "success");
      }
    });
  };
  const [open3, setOpen3] = React.useState(false);

  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => {
    cleanData();
    setOpen3(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    cleanData();
    setOpen(false);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [mensage, setMensage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  const [openAlert1, setOpenAlert1] = React.useState(false);
  const [mensage1, setMensage1] = React.useState(false);
  const [state1, setState1] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });
  //const para el filter
  const [filterText, setFilterText] = React.useState("");
  //MODAL DEL FORMULARIO editar
  const [open2, setOpen2] = React.useState(false);

  //modal formulario abrir/cerrar EDITAR
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    cleanData();
    setOpen2(false);
  };

  //Alerta
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  //Alerta1
  const { vertical1, horizontal1, message1 } = state1;
  const handleClick1 = (newState) => () => {
    setState1({ ...newState });
  };

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
    handleClose();
  };

  //cerrar alerta al crear1
  const handleCloseAlert1 = () => {
    setOpenAlert1(false);
  };

  const [nombreVigencia, setNombreVigencia] = useState([]);
  const [renovaciones, setRenovaciones] = useState([]);
  // Autocmplete Vigenccia
  const [autocompleteVigencia, setAutocompleteVigencia] = useState([]);
  //useState de los campos del form
  const [empresa, setEmpresa] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [responsable, setResponsable] = useState(null);
  const [renovacion_nombre, setRenovacion_nombre] = useState("");

  //useState de los campos del form
  const [tipo_id, setTipoId] = useState(null);
  const [estatus_id, setEstatus] = useState(null);
  const [importe, setImporte] = useState("");
  const [aplica_iva, setAplicaIva] = useState("Si");
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [concepto_nombre, setConcepto_nombre] = useState("");
  const [notificar_vigencia, setNotificar_Vigencia] = useState("");

  const { renovaciones_id } = useParams();

  //AUTOCOMPLETES FORMS
  const [autocompleteEmpresas, setAutocompleteEmpresas] = useState([]);
  const [autocompleteCliente, setAutocompleteCliente] = useState([]);
  const [autocompleteResponsable, setAutocompleteResponsable] = useState([]);

  const [autocompleteTipo, setAutocompleteTipo] = useState([]);
  const [autocompleteEstatus, setAutocompleteEstatus] = useState([]);

  //no necesatios
  const [empresas, setEmpresas] = useState([]);

  const [renovaid, setID] = useState("");

  //Pagination
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  //UseState para consultar los Renovaciones
  const [renova, setRenova] = useState([]);

  //limpiar form
  const cleanData = () => {
    setEmpresa(null);
    setCliente(null);
    setResponsable(null);
    /* setConcepto(""); */
    setRenovacion_nombre("");
    setConcepto_nombre("");
    setTipoId(null);
    setEstatus(null);
    setImporte("");
    setAplicaIva("");
    setFechaInicio("");
    setFechaFin("");
    setComentarios("");
    setNotificar_Vigencia("");
  };

  //Editar EDit 1er Form
  const fetchEditRenova = async () => {
    if (empresa == null) {
      setMensage1("Añadir campo Empresa.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (cliente == null) {
      setMensage1("Añadir campo Cliente.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (responsable == null) {
      setMensage1("Añadir campo Responsable.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/renovacion/" + renovaid,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            empresa_id: empresa.id,
            cliente_id: cliente.id,
            responsable_id: responsable.id,
            concepto: "DefaultValue",
            renovacion_nombre: renovacion_nombre,
          }),
        }
      );
      const result = await response.json();

      //Alerta de editar renovaciones
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "La Renovación se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchRenovaRead();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar la Renovación",
          text: "Intente de nuevo",
        });
        handleClose();
      }
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //Set elements on edit renovacion
  const setEdit = async (renovaEdit) => {
    handleOpen();
    setEmpresa({
      label: renovaEdit?.empresa?.nombre_comercial,
      id: renovaEdit.empresa.id,
    });
    setCliente({
      label:
        renovaEdit.cliente.person.nombre +
        " " +
        renovaEdit.cliente.person.apellido,
      id: renovaEdit.cliente.id,
    });
    setResponsable({
      label:
        renovaEdit?.responsable?.person?.nombre +
        " " +
        renovaEdit?.responsable?.person?.apellido,
      id: renovaEdit.responsable.id,
    });
    setRenovacion_nombre(renovaEdit?.renovacion_nombre);
    setID(renovaEdit.id);
  };

  //Crear Renovaciones
  const fetchRenovaCreate = async () => {
    if (empresa == null) {
      setMensage1("Añadir campo Empresa.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (cliente == null) {
      setMensage1("Añadir campo Cliente.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (responsable == null) {
      setMensage1("Añadir campo Responsable.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (tipo_id == null) {
      setMensage1("Añadir campo Tipo.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (estatus_id == null) {
      setMensage1("Añadir campo Estatus.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (importe == "") {
      setMensage1("Añadir campo Importe.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (aplica_iva == "") {
      setMensage1("Añadir campo IVA.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (fecha_inicio == "") {
      setMensage1("Añadir campo Fecha Inicio.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (fecha_fin == "") {
      setMensage1("Añadir campo Fecha Fin.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/renovacion/store", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          empresa_id: empresa.id,
          cliente_id: cliente.id,
          responsable_id: responsable.id,
          concepto: "DefaultValue",
          renovacion_nombre: renovacion_nombre,
        }),
      });
      const result = await response.json();
      //Alerta de crear renovacion
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "La Renovación se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchRenovaRead();

        setTimeout(() => {
          fetchCrearConcepto(result.data.id);
        }, 2000);

        cleanData();
        handleClose2();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al registrar la Renovación",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //2do FORM
  const fetchCrearConcepto = async (renovaciones_id) => {
    try {
      const token = localStorage.getItem("token");
      const fechaInicio = moment(fecha_inicio).format("YYYY-MM-DD HH:mm:ss");
      const fechaFin = moment(fecha_fin).format("YYYY-MM-DD HH:mm:ss");
      const response = await fetch(
        constants.api +
        "auth/renovacion/" +
        renovaciones_id +
        "/concepto/store",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            tipo_id: tipo_id.id,
            concepto_nombre: renovacion_nombre,
            estatus_id: estatus_id.id,
            importe: importe,
            aplica_iva: aplica_iva,
            fecha_inicio: fechaInicio,
            fecha_fin: fechaFin,
            comentarios: comentarios,
            notificar_vigencia: "Si",
          }),
        }
      );
      const result = await response.json();

      //Alerta de crear Concepto
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Concepto se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchRenovaRead();
        cleanData();
        handleClose2();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al registrar el Concepto",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //CONSULTAR clientes para el FORM
  const fetchClientes = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/contacto?search[value]=",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      setEmpresas(result.data);
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label:
            result.data[key].person.nombre +
            " " +
            result.data[key].person.apellido,
          id: result.data[key].id,
        });
      }
      setAutocompleteCliente(arrayTipo);
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };
  useEffect(() => { }, [renovaciones]);
  //CONSULTAR Tipos para el FORM
  const fetchTipos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=Renovaciones&search[sub_grupo]=Categoria",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      /* setEmpresas(result.data); */
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteTipo(arrayTipo);
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //CONSULTAR estatus para el FORM
  const fetchEstatus = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=Renovaciones&search[sub_grupo]=Estatus",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      /* setEmpresas(result.data); */
      let arrayEstatus = [];
      for (const key in result.data) {
        arrayEstatus.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteEstatus(arrayEstatus);
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //Editar VIGENCIA
  const fetchEditVigencia = async () => {
    try {
      const token = localStorage.getItem("token");
      var urlencoded = new URLSearchParams();
      urlencoded.append("renovaciones[0]", renovaciones[0].id);

      const response = await fetch(
        constants.api + "auth/renovacion/notificar",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          body: urlencoded,
          redirect: "follow",
        }
      );
      const result = await response.json();
      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Se notificó a los clientes seleccionados.",
          showConfirmButton: false,
          timer: 3000,
        });
        // fetchProyectos();
        cleanData();
        handleClose3();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar Proyecto",
          text: "Intente de nuevo",
        });
        handleClose3();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR empresas para el FORM
  const fetchEmpresas = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/empresa", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const result = await response.json();
      setEmpresas(result.data);
      let arrayEmpresa = [];
      for (const key in result.data) {
        arrayEmpresa.push({
          label: result.data[key].nombre_comercial,
          id: result.data[key].id,
        });
      }
      setAutocompleteEmpresas(arrayEmpresa);
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //CONSULTAR responsables para el FORM
  const fetchResponsables = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/contacto?search[tipo]=equipo",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      setEmpresas(result.data);
      let arrayResponsable = [];
      for (const key in result.data) {
        arrayResponsable.push({
          label:
            result.data[key].person.nombre +
            " " +
            result.data[key].person.apellido,
          id: result.data[key].id,
        });
      }
      setAutocompleteResponsable(arrayResponsable);
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  const fetchRenovaReadVigencia = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(constants.api + "auth/renovacion", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const result = await response.json();
    setNombreVigencia(result.data);
    let arrayVigencia = [];
    for (const key in result.data) {
      arrayVigencia.push({
        label: result.data[key].renovacion_nombre,
        id: result.data[key].id,
      });
    }
    setAutocompleteVigencia(arrayVigencia);
    if (result?.success) {
      setRenova(result.data);
      setTotalData(result.recordsTotal);
    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
    setLoading(false);
  };
  //Consultar Renovaciones
  const fetchRenovaRead = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      constants.api + "auth/renovacion?search[value]=" + filterText,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        /*   body: JSON.stringify({
          start: (page - 1) * 10,
          length: 10,
        }),  */
      }
    );
    const result = await response.json();

    if (result?.success) {
      setRenova(result.data);
      setTotalData(result.recordsTotal);
    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchEmpresas();
        fetchResponsables();
        fetchTipos();
        fetchEstatus();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, [open, open2]);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchRenovaReadVigencia();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, [open3]);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchClientes();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, [empresa]);


  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchRenovaRead();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, [page, filterText]);

  // useEffect(() => {
  //   let unmounted = false;
  //   setTimeout(() => {
  //     if (!unmounted) {
  //   changeLoading();
  //     }
  //   }, 500);
  //   return () => {
  //     unmounted = true;
  //   };
  // }, []);

  //delete contactos
  const onDelete = async (RenovacionID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/renovacion/" + RenovacionID,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchRenovaRead();
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //PAGINACION
  const columns = [
    {
      name: "Cliente",
      selector: (row) =>
        row?.cliente?.person?.nombre + " " + row?.cliente?.person?.apellido,
      sortable: true,
    },
    {
      name: "Empresa",
      selector: (row) => row?.empresa?.nombre_comercial,
      sortable: true,
    },
    {
      name: "Fecha Vencimiento",
      selector: (row) =>
        moment(row?.conceptos[0]?.fecha_fin).utc().format('DD-MM-YYYY'),
      sortable: true,
    },
    {
      name: "Nombre de Renovación",
      selector: (row) => row?.renovacion_nombre,
      sortable: true,
    },
    {
      name: "Dominio",
      selector: (row) => row?.dominio,
      sortable: true,
    },
    {
      name: "Hosting",
      selector: (row) => row?.hosting,
      sortable: true,
    },
    {
      name: "Saldo Pendiente",
      selector: (row) => row?.saldo_pendiente,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) => row?.total,
      sortable: true,
    },
    {
      name: "Estatus",
      selector: (row) => row?.conceptos[0]?.estatus?.valor,
      sortable: true,
    },
    {
      name: "E-Mail",
      button: true,
      cell: (row) => (
        <button type="button" className="btn btn-secondary edit-delete-buttons" onClick={() => window.location = `mailto:${row?.cliente?.person?.email}`}>
          email
        </button>
      ),
    },
    {
      name: "Ver",
      button: true,
      cell: (row) => (
        <button type="button" className="btn btn-secondary edit-delete-buttons">
          <a href={`/detailRenovaciones/${row?.id}`}>
            <i className="material-icons padding-aling-editdelete">
              visibility
            </i>
          </a>
        </button>
      ),
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-info edit-delete-buttons"
          onClick={() => setEdit(row)}
        >
          <i className="material-icons padding-aling-editdelete">edit</i>
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-danger edit-delete-buttons"
          onClick={() => ConfirmAlert(row.id)}
          value="Eliminar"
        >
          <i className="material-icons padding-aling-editdelete">delete</i>
        </button>
      ),
    },
  ];

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const renovas = renova;
  //SubHeader del filtro
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //onChanges
  const handleInputEmpresa = (event, values) => {
    setEmpresa(values);
  };

  const handleInputCliente = (event, values) => {
    setCliente(values);
  };

  const handleInputResponsable = (event, values) => {
    setResponsable(values);
  };

  const handleInputRenovacion_nombre = (event) => {
    setRenovacion_nombre(event.target.value);
  };

  //2do form
  const handleInputTipo = (event, values) => {
    setTipoId(values);
  };

  const handleInputEstatus = (event, values) => {
    setEstatus(values);
  };

  const handleInputImporte = (event) => {
    setImporte(event.target.value);
  };

  const handleInputIva = (event) => {
    setAplicaIva(event.target.value);
  };

  const handleInputConcepto_nombre = (event) => {
    setConcepto_nombre(event.target.value);
  };

  const handleInputFechaInicio = (event) => {
    setFechaInicio(event.target.value);
    var start = new Date(event.target.value);
    start.setFullYear(start.getFullYear() + 1);
    var startf = start.toISOString().slice(0, 10).replace(/-/g, "/");
    setFechaFin(moment(startf).format("YYYY-MM-DDThh:mm"));
  };

  const handleInputFechaFin = (event) => {
    setFechaFin(event.target.value);
  };

  const handleInputComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const handleInputNotificarVigencia = (event) => {
    setNotificar_Vigencia(event.target.value);
  };

  // const handleInputVigencia = (event, values) => {
  //   setEstatus(values);
  // };

  const handleInputVigencia = (event, values) => {
    setRenovaciones(values);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="main-panel panel-main">
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
          <div className="container-fluid">
            <div className="navbar-wrapper z-index-card mediaContact">
              <a className="navbar-brand" href="">
                SELFISH Control Renovaciones
              </a>
            </div>
          </div>
        </nav>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Renovaciones
                    </h4>
                    <p className="card-category button-add-user">
                      <Button
                        type="button"
                        className="btn btn-success buttonaddusers"
                        onClick={handleOpen2}
                      >
                        <i className="material-icons padding-aling">add</i>
                        Añadir
                      </Button>
                    </p>
                    <p className="card-category button-add-user1">
                      <Button
                        type="button"
                        className="btn btn-success buttonaddusers1"
                        onClick={handleOpen3}
                      >
                        Notificar Vigencia
                      </Button>
                    </p>
                  </div>
                  <div className="card-body table-responsive">
                    <DataTable
                      columns={columns}
                      data={renovas}
                      pagination
                      noDataComponent={
                        loading ? (
                          <Loading />
                        ) : (
                          <p className="sin-datos">
                            No hay datos para mostrar
                          </p>
                        )
                      }
                      paginationResetDefaultPage={resetPaginationToggle}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box scrollModal">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Crear Renovación
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4 label-forms">
                  <label className="labels-formss">Empresa</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    onChange={handleInputEmpresa}
                    getOptionLabel={(options) => options.label}
                    value={empresa}
                    name="empresa"
                    required
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteEmpresas}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basic"
                        variant="standard"
                      />
                    )}
                  />
                </div>

                <div className="col-md-4 label-forms">
                  <label className="labels-formss">Cliente</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    onChange={handleInputCliente}
                    getOptionLabel={(options) => options.label}
                    value={cliente}
                    name="cliente"
                    required
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteCliente}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basic"
                        variant="standard"
                      />
                    )}
                  />
                </div>

                <div className="col-md-4 label-forms">
                  <label className="labels-formss">Responsable</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    onChange={handleInputResponsable}
                    name="responsable"
                    required
                    getOptionLabel={(options) => options.label}
                    value={responsable}
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteResponsable}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basic"
                        variant="standard"
                      />
                    )}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Nombre Renovación</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="text"
                    id="renovacion_nombre"
                    name="renovacion_nombre"
                    value={renovacion_nombre}
                    required
                    onChange={handleInputRenovacion_nombre}
                    className=" tipoUser"
                  />
                </div>

                {/* CONCEPTOS */}

                <div className="col-md-4 label-forms">
                  <label className="labels-formss">Tipo</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    onChange={handleInputTipo}
                    getOptionLabel={(options) => options.label}
                    value={tipo_id}
                    name="tipo"
                    required
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteTipo}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basic"
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 label-forms">
                  <label className="labels-formss">Estatus</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    onChange={handleInputEstatus}
                    name="estatus"
                    required
                    getOptionLabel={(options) => options.label}
                    value={estatus_id}
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteEstatus}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basic"
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 label-forms">
                  <label className="labels-formss">Importe</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="number"
                    id="Importe"
                    name="Importe"
                    value={importe}
                    required
                    onChange={handleInputImporte}
                    className=" tipoUser padding-importe"
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>IVA</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <select
                    type="text"
                    id="iva"
                    required
                    className=" tipoUser"
                    name="iva"
                    value={aplica_iva}
                    onChange={handleInputIva}
                  >
                    <option value="">Elija una opción</option>
                    <option value="Si">Sí</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Fecha de Inicio</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="datetime-local"
                    id="fecha"
                    required
                    className=" tipoUser"
                    name="fecha"
                    value={fecha_inicio}
                    onChange={handleInputFechaInicio}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Fecha de Fin</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="datetime-local"
                    id="fechaFin"
                    required
                    className=" tipoUser"
                    name="fechaFin"
                    value={fecha_fin}
                    onChange={handleInputFechaFin}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Notificar Vigencia</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <select
                    type="text"
                    id="vigencia"
                    required
                    className=" tipoUser"
                    name="vigencia"
                    value={notificar_vigencia}
                    onChange={handleInputNotificarVigencia}
                  >
                    <option value="" defaultValue disabled>
                      Eliga Una Opción
                    </option>
                    <option value="Sí">Sí</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Comentarios</label>
                </div>
                <div className="col-md-8 style-roles ">
                  <textarea
                    value={comentarios}
                    onChange={handleInputComentarios}
                    name="comentarios"
                    type="textarea"
                    id="comentarios"
                    placeholder="Escribe tus comentarios aquí..."
                    rows="5"
                    cols="100"
                    className="size-Textare"
                  ></textarea>
                </div>
                <br />
                <br />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div id="resultadocrear"></div>
            <button
              onClick={handleClose2}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>

            <button
              onClick={fetchRenovaCreate}
              type="button"
              className="button btn-primary"
              data-dismiss="modal"
              id="actionCrearUsuario"
            >
              CREAR
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box-proyectos3">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Editar Renovación
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4">
                  <label>Empresa</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    onChange={handleInputEmpresa}
                    getOptionLabel={(options) => options.label}
                    value={empresa}
                    name="empresa"
                    required
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteEmpresas}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basic"
                        variant="standard"
                      />
                    )}
                  />
                </div>

                <div className="col-md-4">
                  <label>Cliente</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    onChange={handleInputCliente}
                    getOptionLabel={(options) => options.label}
                    value={cliente}
                    name="cliente"
                    required
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteCliente}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basic"
                        variant="standard"
                      />
                    )}
                  />
                </div>

                <div className="col-md-4">
                  <label>Responsable</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    onChange={handleInputResponsable}
                    name="responsable"
                    required
                    getOptionLabel={(options) => options.label}
                    value={responsable}
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteResponsable}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basic"
                        variant="standard"
                        className="inputtt"
                      />
                    )}
                  />
                </div>

                <br />
                <br />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div id="resultadocrear"></div>
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>

            <button
              onClick={fetchEditRenova}
              type="button"
              className="button btn-primary"
              data-dismiss="modal"
              id="actionEditarUsuario"
            >
              Editar
            </button>
          </div>
        </Box>
      </Modal>

      {/* Editar Vigencia*/}
      <Modal
        keepMounted
        open={open3}
        onClose={handleClose3}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box-proyectos2">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Notificar Vigencia de Renovaciones
            </h5>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4">
                  <label>Renovaciones Disponibles</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputVigencia}
                    name="Renovaciones"
                    disablePortal
                    multiple
                    id="combo-box-demo"
                    getOptionLabel={(options) => options.label}
                    value={renovaciones}
                    options={autocompleteVigencia}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basic"
                        variant="standard"
                        {...params}
                        label=""
                        placeholder="Selecciona las Renovaciones a notificar"
                        required
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="modal-footer">
                  <div id="resultadocrear"></div>
                  <button
                    onClick={handleClose3}
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>

                  <button
                    onClick={fetchEditVigencia}
                    type="button"
                    className="button btn-primary"
                    data-dismiss="modal"
                    id="actionCrearUsuario"
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        message={mensage}
        key={vertical + horizontal}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert1}
        onClose={handleCloseAlert1}
        message={mensage1}
        key={vertical1 + horizontal1}
      />
    </>
  );
};

export default Renovaciones;

if (document.getElementById("renovaciones")) {
  ReactDOM.render(<Renovaciones />, document.getElementById("renovaciones"));
}
