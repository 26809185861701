import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import constants from "../utils/constants";
import { useParams } from "react-router";
import { Autocomplete, TextField } from "@mui/material";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import Swal from "sweetalert2";
import { Fragment } from "react";
import Loading from "./Loading";
import Snackbar from "@mui/material/Snackbar";

var moment = require("moment");



const DetailRenovaciones = () => {
  // Loading
  const [loading, setLoading] = useState(true);

  const changeLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const [abonosID, setAbonoID] = useState("");
  const [conceptosID, setConceptosID] = useState("");

  const [conceptosID2, setConceptosID2] = useState("");


  //Modal
  //MODAL DEL FORMULARIO editar
  const [open2, setOpen2] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  //modal formulario abrir/cerrar EDITAR
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    cleanData();
    setOpen2(false);
  };

  //modal formulario abrir/cerrar EDITAR
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    cleanData();
    setOpen(false);
  };

  const [proyectos, setProyectos] = useState([]);

  const [metodo_id, setMetodo_id] = useState(null);
  const [tipo_id, setTipo_id] = useState(null);
  const [monto, setMonto] = useState("");
  const [aplica_iva, setAplica_iva] = useState("");
  const [fecha_movimiento, setFecha] = useState("");

  const { renovaciones_id } = useParams();
  const [autocompleteTiposAbonosMetodo, setAutocompleteTiposAbonosMetodo] =
    useState([]);
  const [autocompleteEstatus, setAutocompleteEstatus] = useState([]);

  const [autocompleteTiposExtra, setAutocompleteTiposExtra] = useState([]);

  const [autocompleteTipoConceptos, setAutocompleteTipoConceptos] = useState(
    []
  );

  //useState de los campos del form de concepto
  const [tipos_id, setTipoId] = useState(null);
  const [estatus_id, setEstatus] = useState(null);
  const [importe, setImporte] = useState("");
  const [aplica_ivas, setAplicaIva] = useState("");
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [notificar_vigencia, setNotificar_Vigencia] = useState("");
  const [concepto_nombre, setConcepto_nombre] = useState("");

  //Añadir AÑos de viegencia

  const [numero_anos, setNumero_anos] = useState("");

  // Const EDIT/Clean Data
  const [detailID, setID] = useState("");

  // Alerta
  const [openAlert, setOpenAlert] = React.useState(false);
  const [mensage, setMensage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //Alerta
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //MODAL DEL FORMULARIO editar vigencia
  const [open3, setOpen3] = React.useState(false);
  //modal formulario abrir/cerrar EDITAR
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => {
    cleanData();
    setOpen3(false);
  };

  //limpiar forms
  const cleanData = () => {
    setTipo_id(null);
    setMetodo_id(null);
    setMonto("");
    setAplica_iva("");
    setFecha("");
    setConcepto_nombre("");

    setTipoId(null);
    setEstatus(null);
    setImporte("");
    setAplicaIva("");
    setFechaInicio("");
    setFechaFin("");
    setComentarios("");
    setNotificar_Vigencia("");
    setID("");
  };

  //CONSULTAR Tipos para el FORM
  const fetchTipos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=Renovaciones&search[sub_grupo]=Categoria",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteTipoConceptos(arrayTipo);
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //CONSULTAR estatus para el FORM
  const fetchEstatus = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=Renovaciones&search[sub_grupo]=Estatus",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      /* setEmpresas(result.data); */
      let arrayEstatus = [];
      for (const key in result.data) {
        arrayEstatus.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteEstatus(arrayEstatus);
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //CONSULTAR Metodo Abonos  para el FORM
  const fetchMetodoAbonos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=Operaciones&search[sub_grupo]=Medios de Pago",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteTiposAbonosMetodo(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR Tipos Conceptos Extras para el FORM
  const fetchTiposExtras = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/parametro?search[grupo]=Ingresos",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();

      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteTiposExtra(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR USUARIOS
  const fetchProyectos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/renovacion/" + renovaciones_id,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      setProyectos(result.data);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  // aLERT DELETE
  const ConfirmAlert = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
        Swal.fire("Eliminado!", "El Abono fue Eliminado.", "success");
      }
    });
  };

  // Delete Abonos
  const onDelete = async (AbonoID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/renovacion/" +
        renovaciones_id +
        "/operacion/" +
        AbonoID,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchProyectos();
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  // Alerta 2 Delete
  const ConfirmAlert1 = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete1(id);
        Swal.fire("Eliminado!", "El Concepto fue Eliminado.", "success");
      }
    });
  };

  // Delete Abonos 1
  const onDelete1 = async (ConceptoID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/renovacion/" +
        renovaciones_id +
        "/concepto/" +
        ConceptoID,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchProyectos();
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchProyectos();
        fetchMetodoAbonos();
        fetchTiposExtras();
        fetchTipos();
        fetchEstatus();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        changeLoading();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  // Editar Abono
  const fetchEditAbono = async () => {
    try {
      const token = localStorage.getItem("token");
      const fecha = moment(fecha_movimiento).format("YYYY-MM-DD HH:mm:ss");
      const response = await fetch(
        constants.api +
        "auth/renovacion/" +
        renovaciones_id +
        "/operacion/" +
        abonosID,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            tipo_id: tipo_id.id,
            metodo_id: metodo_id.id,
            monto: monto,
            aplica_iva: aplica_iva,
            fecha_movimiento: fecha,
          }),
        }
      );
      const result = await response.json();
      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu Abono se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchProyectos();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar Abono",
          text: "Intente de nuevo",
        });
        // });
        handleClose();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Set elements on edit projects
  const setEdit = async (abonosEdit) => {
    const fechaMoment = moment(abonosEdit?.fecha_movimiento).format(
      "YYYY-MM-DDThh:mm"
    );
    handleOpen();
    setTipo_id({ label: abonosEdit.tipo.valor, id: abonosEdit.tipo.id });
    setMetodo_id({ label: abonosEdit.metodo.valor, id: abonosEdit.metodo.id });
    setMonto(abonosEdit?.monto);
    setAplica_iva(abonosEdit?.aplica_iva);
    setFecha(fechaMoment);
    setAbonoID(abonosEdit.id);
  };

  // Editar Concepto
  const fetchEditConcepto = async () => {
    try {
      if (concepto_nombre == "") {
        setMensage("Añadir campo Nombre.");
        setOpenAlert(true);
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      const token = localStorage.getItem("token");
      const fechaInicio = moment(fecha_inicio).format("YYYY-MM-DD HH:mm:ss");
      const fechaFin = moment(fecha_fin).format("YYYY-MM-DD HH:mm:ss");
      const response = await fetch(
        constants.api +
        "auth/renovacion/" +
        renovaciones_id +
        "/concepto/" +
        conceptosID,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            tipo_id: tipos_id.id,
            estatus_id: estatus_id.id,
            concepto_nombre: concepto_nombre,
            importe: importe,
            aplica_iva: aplica_ivas,
            fecha_inicio: fechaInicio,
            fecha_fin: fechaFin,
            comentarios: comentarios,
            notificar_vigencia: "Si",
          }),
        }
      );
      const result = await response.json();
      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu Concepto se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchProyectos();
        cleanData();
        handleClose2();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar Concepto",
          text: "Intente de nuevo",
        });
        // });
        handleClose2();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Set elements on edit projects
  const setEdit2 = async (conceptosEdit) => {
    console.log('conceptosEdit?.fecha_inicio', conceptosEdit?.fecha_inicio);
    let fechaMoment1;
    if (conceptosEdit?.fecha_inicio == null) {
      fechaMoment1 = moment(conceptosEdit?.fecha_fin).subtract(1, 'years').format(
        "YYYY-MM-DDThh:mm"
      );
    } else {
      fechaMoment1 = moment(conceptosEdit?.fecha_inicio).format(
        "YYYY-MM-DDThh:mm"
      );
    }

    const fechaMoment2 = moment(conceptosEdit?.fecha_fin).format(
      "YYYY-MM-DDThh:mm"
    );
    handleOpen2();
    setTipoId({ label: conceptosEdit.tipo.valor, id: conceptosEdit.tipo.id });
    setEstatus({
      label: conceptosEdit.estatus.valor,
      id: conceptosEdit.estatus.id,
    });
    setImporte(conceptosEdit?.importe);
    setAplicaIva(conceptosEdit?.aplica_iva);
    setFechaInicio(fechaMoment1);
    setFechaFin(fechaMoment2);
    setComentarios(conceptosEdit?.comentarios);
    setNotificar_Vigencia(conceptosEdit?.notificar_vigencia);
    setConcepto_nombre(conceptosEdit?.concepto_nombre);
    setConceptosID(conceptosEdit.id);
  };

  //Set elements on edit projects
  const setEdit3 = async (conceptosEdit) => {
    handleOpen3();
    setConceptosID2(conceptosEdit.id);
  };

  //Crear Nuevo Abono
  const fetchAbono = async () => {
    if (tipo_id == null) {
      alert("Añadir campo tipo");
      return;
    } else if (metodo_id == null) {
      alert("Añadir campo método");
      return;
    } else if (monto == "") {
      alert("Añadir campo monto");
      return;
    } else if (aplica_iva == "") {
      alert("Añadir campo IVA");
      return;
    } else if (fecha_movimiento == "") {
      alert("Añadir campo fecha inicio");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const fecha = moment(fecha_movimiento).format("YYYY-MM-DD HH:mm:ss");

      const response = await fetch(
        constants.api +
        "auth/renovacion/" +
        renovaciones_id +
        "/operacion/store",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            tipo_id: tipo_id.id,
            metodo_id: metodo_id.id,
            monto: monto,
            aplica_iva: aplica_iva,
            fecha_movimiento: fecha,
          }),
        }
      );
      const result = await response.json();

      if (result?.success) {
        fetchProyectos();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu Abono se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        cleanData();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al ingresar Abono",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };
  //crear conceptos extra
  const fetchCrearConcepto = async (renovacion_id) => {
    if (tipos_id == null) {
      alert("Añadir campo tipo");
      return;
    } else if (estatus_id == null) {
      alert("Añadir campo estatus ");
      return;
    } else if (importe == "") {
      alert("Añadir campo importe");
      return;
    } else if (aplica_ivas == "") {
      alert("Añadir campo IVA");
      return;
    } else if (fecha_inicio == "") {
      alert("Añadir campo fecha inicio");
      return;
    } else if (fecha_fin == "") {
      alert("Añadir campo fecha fin");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const fechaInicio = moment(fecha_inicio).format("YYYY-MM-DD HH:mm:ss");
      const fechaFin = moment(fecha_fin).format("YYYY-MM-DD HH:mm:ss");
      const response = await fetch(
        constants.api +
        "auth/renovacion/" +
        renovaciones_id +
        "/concepto/store",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            tipo_id: tipos_id.id,
            estatus_id: estatus_id.id,
            importe: importe,
            concepto_nombre: concepto_nombre,
            aplica_iva: aplica_ivas,
            fecha_inicio: fechaInicio,
            fecha_fin: fechaFin,
            comentarios: comentarios,
            notificar_vigencia: "Si",
          }),
        }
      );
      const result = await response.json();
      //Alerta de crear usuario

      if (result?.success) {
        fetchProyectos();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu Concepto se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        cleanData();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al ingresar Concepto",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //AÑadir Vigencia en ños
  const fetchNotificarVigencia = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/renovacion/" +
        renovaciones_id +
        "/concepto/" +
        conceptosID2 +
        "/vigencia",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            numero_años: numero_anos,
          }),
        }
      );
      const result = await response.json();
      //Alerta de crear usuario

      if (result?.success) {
        fetchProyectos();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Tu Concepto Ha Renovado La Vigencia Con Éxito",
          showConfirmButton: false,
          timer: 3000,
        });
        cleanData();
        handleClose3();
      } else {
        Swal.fire({
          icon: "error",
          title: "No Es Necesario Renovar La Vigencia De Esté Concepto!",
          text: "El Concepto Esta Vigente.",
        });
        // });
        handleClose3();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  const handleInputMetodoId = (event, values) => {
    setMetodo_id(values);
  };

  const handleInputTipoId = (event, values) => {
    setTipo_id(values);
  };

  const handleInputMonto = (event) => {
    setMonto(event.target.value);
  };

  const handleInputIva = (event) => {
    setAplica_iva(event.target.value);
  };

  const handleInputFecha = (event) => {
    setFecha(event.target.value);
  };

  //conceptos
  const handleInputTipo = (event, values) => {
    setTipoId(values);
  };

  const handleInputConcepto_nombre = (event) => {
    setConcepto_nombre(event.target.value);
  };

  const handleInputEstatus = (event, values) => {
    setEstatus(values);
  };

  const handleInputImporte = (event) => {
    setImporte(event.target.value);
  };

  const handleInputIvas = (event) => {
    setAplicaIva(event.target.value);
  };

  const handleInputFechaInicio = (event) => {
    setFechaInicio(event.target.value);
    setFechaInicio(event.target.value);
    var start = new Date(event.target.value);
    start.setFullYear(start.getFullYear() + 1);
    var startf = start.toISOString().slice(0, 10).replace(/-/g, "/");
    setFechaFin(moment(startf).format("YYYY-MM-DDThh:mm"));
  };

  const handleInputFechaFin = (event) => {
    setFechaFin(event.target.value);
  };

  const handleInputComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const handleInputNotificarvigencia = (event) => {
    setNotificar_Vigencia(event.target.value);
  };

  const handleInputVigenciaAnos = (event) => {
    setNumero_anos(event.target.value);
  };


  const ordenararray = proyectos[0]?.operaciones;
  const arrayOrdenado = ordenararray?.sort((a, b) => moment(a.fecha_movimiento, "YYYY-MM-DD HH:mm:ss").unix() - moment(b.fecha_movimiento, "YYYY-MM-DD HH:mm:ss").unix());

  return (
    <>
      <div className="main-panel panel-main">
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
          <div className="container-fluid">
            <div className="navbar-wrapper z-index-card mediaContact">
              <a className="navbar-brand" href="">
                SELFISH Control Detalles Renovaciones
              </a>
            </div>
          </div>
        </nav>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Información General
                    </h4>
                  </div>

                  <div className="card-body table-responsive">
                    {loading ? (
                      <div className="loading-style col-auto">
                        <Loading />
                      </div>
                    ) : (
                      <table className="table table-hover">
                        <thead id="headResu">
                          <tr>
                            <th>Cliente</th>
                            <th>Responsable</th>
                            <th>Dominio</th>
                            <th>Hosting</th>
                            <th>SSL</th>
                          </tr>
                        </thead>
                        <tbody id="resultado">
                          {proyectos
                            ? proyectos.map((proyecto) => (
                              <tr key={proyecto.id}>
                                <td>
                                  {proyecto?.cliente?.person?.nombre}{" "}
                                  {proyecto?.cliente?.person?.apellido}
                                </td>
                                <td>
                                  {proyecto?.responsable?.person?.nombre}{" "}
                                  {
                                    proyecto?.responsable?.person
                                      ?.apellido
                                  }
                                </td>
                                <td>${proyecto?.dominio} MXN</td>
                                <td>${proyecto?.hosting} MXN</td>
                                <td>${proyecto?.certificado} MXN</td>
                              </tr>
                            ))
                            : null}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <br />
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Total Cliente
                    </h4>
                  </div>

                  <div className="card-body table-responsive">
                    {loading ? (
                      <div className="loading-style col-auto">
                        <Loading />
                      </div>
                    ) : (
                      <table className="table table-hover">
                        <thead id="headResu">
                          <tr>
                            <th>Total</th>
                            <th>Pagado</th>
                            <th>Restante</th>
                          </tr>
                        </thead>
                        <tbody id="resultado">
                          {proyectos
                            ? proyectos.map((proyecto) => (
                              <tr key={proyecto.id}>
                                <td>${proyecto?.total} MXN</td>
                                <td>${proyecto?.sum_pagos} </td>
                                <td>${proyecto?.saldo_pendiente} </td>
                              </tr>
                            ))
                            : null}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>

              {/* Aqui empieza */}
              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">Conceptos</h4>
                  </div>
                  <div className="card-body table-responsive overflow-scrool">
                    {loading ? (
                      <div className="loading-style col-auto">
                        <Loading />
                      </div>
                    ) : (
                      <table className="table table-hover">
                        <thead id="headResu">
                          <tr>
                            <th>Nombre Concepto</th>
                            <th>Total</th>
                            <th>Tipo</th>
                            <th>Estatus</th>
                            <th>Fecha Inicio</th>
                            <th>Fecha Fin</th>
                            <th>Editar Concepto</th>
                            <th>Eliminar</th>
                            <th>Agregar Vigencia</th>
                          </tr>
                        </thead>
                        <tbody id="resultado">
                          {proyectos
                            ? proyectos.map((proyecto, pos13) => (
                              <Fragment key={pos13}>
                                {proyecto.conceptos
                                  ? proyecto.conceptos.map((concepto) => (
                                    <tr key={concepto.id}>
                                      <td>
                                        {concepto?.concepto_nombre}
                                      </td>
                                      <td>{concepto?.total}</td>
                                      <td>{concepto?.tipo?.valor}</td>
                                      <td>
                                        {concepto?.estatus?.valor}
                                      </td>
                                      <td>
                                        {moment(concepto?.fecha_inicio)
                                          .utc().format('DD-MM-YYYY')}
                                      </td>
                                      <td>
                                        {moment(concepto?.fecha_fin)
                                          .utc().format('DD-MM-YYYY')}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-info edit-delete-buttons"
                                          onClick={() =>
                                            setEdit2(concepto)
                                          }
                                        >
                                          <i className="material-icons padding-aling-editdelete">
                                            edit
                                          </i>
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger edit-delete-buttons"
                                          onClick={() =>
                                            ConfirmAlert1(concepto.id)
                                          }
                                          value="Eliminar"
                                        >
                                          <i className="material-icons padding-aling-editdelete">
                                            delete
                                          </i>
                                        </button>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-info edit-delete-buttons back-button"
                                          title="Editar estatus"
                                          onClick={() =>
                                            setEdit3(concepto)
                                          }
                                        >
                                          <i className="material-icons padding-aling-editdelete">
                                            edit
                                          </i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                  : null}
                              </Fragment>
                            ))
                            : null}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
              {/* Aqui termina */}

              {/* Aqui empieza */}
              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card ">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">Abonos</h4>
                  </div>
                  <div className="card-body table-responsive overflow-scrool">
                    {loading ? (
                      <div className="loading-style col-auto">
                        <Loading />
                      </div>
                    ) : (
                      <table className="table table-hover">
                        <thead id="headResu">
                          <tr>
                            <th>Monto</th>
                            <th>Método</th>
                            <th>Fecha</th>
                          </tr>
                        </thead>
                        <tbody id="resultado">
                          {arrayOrdenado
                            ? arrayOrdenado.map((operacion, pos6) => (
                              <tr key={operacion.id}>
                                <td>{operacion?.total}</td>
                                <td>
                                  {operacion?.metodo?.valor}
                                </td>
                                <td>
                                  {moment(
                                    operacion?.fecha_movimiento
                                  )
                                    .utc().format('DD-MM-YYYY')}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-info edit-delete-buttons"
                                    onClick={() =>
                                      setEdit(operacion)
                                    }
                                  >
                                    <i className="material-icons padding-aling-editdelete">
                                      edit
                                    </i>
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger edit-delete-buttons"
                                    onClick={() =>
                                      ConfirmAlert(operacion.id)
                                    }
                                    value="Eliminar"
                                  >
                                    <i className="material-icons padding-aling-editdelete">
                                      delete
                                    </i>
                                  </button>
                                </td>
                              </tr>
                            )
                            )
                            : null}

                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              {/* Aqui termina */}

              {/* Aqui empieza */}
              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Agregar Concepto
                    </h4>
                  </div>
                  <div className="card-body table-responsive">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-4 conceptoExtra">
                          <label>Nombre del Concepto</label>
                        </div>
                        <div className="col-md-8 conceptoExtra">
                          <input
                            type="text"
                            id="concepto_nombre"
                            required
                            className=" tipoUser"
                            name="concepto_nombre"
                            value={concepto_nombre}
                            onChange={handleInputConcepto_nombre}
                          />
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>Tipo</label>
                        </div>

                        <div className="col-md-8">
                          <Autocomplete
                            className="style-roles"
                            disablePortal
                            id="combo-box-demo"
                            options={autocompleteTipoConceptos}
                            sx={{ width: 300 }}
                            onChange={handleInputTipo}
                            getOptionLabel={(options) => options.label}
                            value={tipos_id}
                            name="tipos"
                            required
                            renderInput={(params) => (
                              <TextField
                                id="standard-basic"
                                variant="standard"
                                {...params}
                              />
                            )}
                          />
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>Estatus</label>
                        </div>
                        <div className="col-md-8 ">
                          <Autocomplete
                            className="style-roles"
                            disablePortal
                            id="combo-box-demo"
                            value={estatus_id}
                            options={autocompleteEstatus}
                            getOptionLabel={(options) => options.label}
                            sx={{ width: 300 }}
                            onChange={handleInputEstatus}
                            name="descuento"
                            required
                            renderInput={(params) => (
                              <TextField
                                id="standard-basic"
                                variant="standard"
                                {...params}
                              />
                            )}
                          />
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>Importe</label>
                        </div>
                        <div className="col-md-8 conceptoExtra">
                          <input
                            type="number"
                            id="importe"
                            required
                            className=" tipoUser"
                            name="importe"
                            value={importe}
                            onChange={handleInputImporte}
                          />
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>IVA</label>
                        </div>
                        <div className="col-md-8 conceptoExtra">
                          <select
                            type="text"
                            id="iva"
                            required
                            className=" tipoUser"
                            name="iva"
                            value={aplica_ivas}
                            onChange={handleInputIvas}

                          >
                            <option value="DEFAULT" disabled>
                              Eliga Una Opción
                            </option>
                            <option value="Si">Sí</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>Fecha de Inicio</label>
                        </div>
                        <div className="col-md-8 conceptoExtra">
                          <input
                            type="datetime-local"
                            id="fecha"
                            required
                            className=" tipoUser"
                            name="fecha"
                            value={fecha_inicio}
                            onChange={handleInputFechaInicio}
                          />
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>Fecha de Fin</label>
                        </div>
                        <div className="col-md-8 conceptoExtra">
                          <input
                            type="datetime-local"
                            id="fechaf"
                            required
                            className=" tipoUser"
                            name="fechaf"
                            value={fecha_fin}
                            onChange={handleInputFechaFin}
                          />
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>Comentarios</label>
                        </div>
                        <div className="col-md-8 style-roles ">
                          <textarea
                            value={comentarios}
                            onChange={handleInputComentarios}
                            name="comentarios"
                            type="textarea"
                            id="comentarios"
                            placeholder="Escribe tus comentarios aquí..."
                            rows="5"
                            cols="100"
                            className="size-Textare"
                          ></textarea>
                        </div>
                        <br />
                        <br />

                        <div className="col-md-4 conceptoExtra">
                          <label>Notificar Vigencia</label>
                        </div>
                        <div className="col-md-8 conceptoExtra">
                          <select
                            type="text"
                            id="vigencia"
                            required
                            className=" tipoUser"
                            name="vigencia"
                            value={notificar_vigencia}
                            onChange={handleInputNotificarvigencia}
                          >
                            <option value="Si">Sí</option>
                          </select>
                        </div>

                        <div className="conceptoExtra-boton">
                          <button
                            onClick={fetchCrearConcepto}
                            type="button"
                            className="button btn-primary "
                            data-dismiss="modal"
                            id="actionCrearEmpresa"
                          >
                            CREAR
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Aqui termina */}

              {/* Aqui empieza */}
              <div className="col-lg-6 col-md-6">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">
                      Agregar Abonos
                    </h4>
                  </div>

                  <div className="card-body table-responsive">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-4 conceptoExtra">
                          <label>Tipo</label>
                        </div>

                        <div className="col-md-8">
                          <Autocomplete
                            className="style-roles"
                            disablePortal
                            id="combo-box-demo"
                            options={autocompleteTiposExtra}
                            sx={{ width: 300 }}
                            onChange={handleInputTipoId}
                            getOptionLabel={(options) => options.label}
                            value={tipo_id}
                            name="descuento"
                            required
                            renderInput={(params) => (
                              <TextField
                                id="standard-basic"
                                variant="standard"
                                {...params}
                              />
                            )}
                          />
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>Método</label>
                        </div>
                        <div className="col-md-8 ">
                          <Autocomplete
                            className="style-roles"
                            disablePortal
                            id="combo-box-demo"
                            value={metodo_id}
                            options={autocompleteTiposAbonosMetodo}
                            getOptionLabel={(options) => options.label}
                            sx={{ width: 300 }}
                            onChange={handleInputMetodoId}
                            name="descuento"
                            required
                            renderInput={(params) => (
                              <TextField
                                id="standard-basic"
                                variant="standard"
                                {...params}
                              />
                            )}
                          />
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>Monto</label>
                        </div>
                        <div className="col-md-8 conceptoExtra">
                          <input
                            type="number"
                            id="monto"
                            required
                            className=" tipoUser"
                            name="monto"
                            value={monto}
                            onChange={handleInputMonto}
                          />
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>IVA</label>
                        </div>
                        <div className="col-md-8 conceptoExtra">
                          <select
                            type="text"
                            id="iva"
                            required
                            className=" tipoUser"
                            name="iva"
                            value={aplica_iva}
                            onChange={handleInputIva}

                          >
                            <option value="DEFAULT" disabled>
                              Eliga Una Opción
                            </option>
                            <option value="Si">Sí</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                        <div className="col-md-4 conceptoExtra">
                          <label>Fecha del Movimiento</label>
                        </div>
                        <div className="col-md-8 conceptoExtra">
                          <input
                            type="datetime-local"
                            id="fecha"
                            required
                            className=" tipoUser"
                            name="fecha"
                            value={fecha_movimiento}
                            onChange={handleInputFecha}
                          />
                        </div>

                        <div className="conceptoExtra-boton">
                          <button
                            onClick={fetchAbono}
                            type="button"
                            className="button btn-primary "
                            data-dismiss="modal"
                            id="actionCrearEmpresa"
                          >
                            CREAR
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Aqui termina */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box scrollModal">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Editar Abono
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4 conceptoExtra">
                  <label>Tipo</label>
                </div>

                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteTiposExtra}
                    sx={{ width: 300 }}
                    onChange={handleInputTipoId}
                    getOptionLabel={(options) => options.label}
                    value={tipo_id}
                    name="descuento"
                    required
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Método</label>
                </div>
                <div className="col-md-8 ">
                  <Autocomplete
                    className="style-roles"
                    disablePortal
                    id="combo-box-demo"
                    value={metodo_id}
                    options={autocompleteTiposAbonosMetodo}
                    getOptionLabel={(options) => options.label}
                    sx={{ width: 300 }}
                    onChange={handleInputMetodoId}
                    name="descuento"
                    required
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Monto</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="number"
                    id="monto"
                    required
                    className=" tipoUser"
                    name="monto"
                    value={monto}
                    onChange={handleInputMonto}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>IVA</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <select
                    type="text"
                    id="iva"
                    required
                    className=" tipoUser"
                    name="iva"
                    value={aplica_iva}
                    onChange={handleInputIva}

                  >
                    <option value="DEFAULT" disabled>
                      Eliga Una Opción
                    </option>
                    <option value="Si">Sí</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Fecha del Movimiento</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="datetime-local"
                    id="fecha"
                    required
                    className=" tipoUser"
                    name="fecha"
                    value={fecha_movimiento}
                    onChange={handleInputFecha}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <div id="resultadocrear"></div>
                <button
                  onClick={handleClose}
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>

                <button
                  onClick={fetchEditAbono}
                  type="button"
                  className="button btn-primary"
                  data-dismiss="modal"
                  id="actionCrearUsuario"
                >
                  Editar
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box scrollModal">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Editar Concepto
            </h5>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4 conceptoExtra">
                  <label>Nombre del Concepto</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="text"
                    id="concepto_nombre"
                    required
                    className=" tipoUser"
                    name="concepto_nombre"
                    value={concepto_nombre}
                    onChange={handleInputConcepto_nombre}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Tipo</label>
                </div>

                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    disablePortal
                    id="combo-box-demo"
                    options={autocompleteTipoConceptos}
                    sx={{ width: 300 }}
                    onChange={handleInputTipo}
                    getOptionLabel={(options) => options.label}
                    value={tipos_id}
                    name="tipos"
                    required
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Estatus</label>
                </div>
                <div className="col-md-8 ">
                  <Autocomplete
                    className="style-roles"
                    disablePortal
                    id="combo-box-demo"
                    value={estatus_id}
                    options={autocompleteEstatus}
                    getOptionLabel={(options) => options.label}
                    sx={{ width: 300 }}
                    onChange={handleInputEstatus}
                    name="descuento"
                    required
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Importe</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="number"
                    id="importe"
                    required
                    className=" tipoUser"
                    name="importe"
                    value={importe}
                    onChange={handleInputImporte}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>IVA</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <select
                    type="text"
                    id="iva"
                    required
                    className=" tipoUser"
                    name="iva"
                    value={aplica_ivas}
                    onChange={handleInputIvas}

                  >
                    <option value="DEFAULT" disabled>
                      Eliga Una Opción
                    </option>
                    <option value="Si">Sí</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Fecha de Inicio</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="datetime-local"
                    id="fecha"
                    required
                    className=" tipoUser"
                    name="fecha"
                    value={fecha_inicio}
                    onChange={handleInputFechaInicio}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Fecha de Fin</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <input
                    type="datetime-local"
                    id="fechaf"
                    required
                    className=" tipoUser"
                    name="fechaf"
                    value={fecha_fin}
                    onChange={handleInputFechaFin}
                  />
                </div>

                <div className="col-md-4 conceptoExtra">
                  <label>Comentarios</label>
                </div>
                <div className="col-md-8 style-roles ">
                  <textarea
                    value={comentarios}
                    onChange={handleInputComentarios}
                    name="comentarios"
                    type="textarea"
                    id="comentarios"
                    placeholder="Escribe tus comentarios aquí..."
                    rows="5"
                    cols="100"
                    className="size-Textare"
                  ></textarea>
                </div>
                <br />
                <br />

                <div className="col-md-4 conceptoExtra">
                  <label>Notificar Vigencia</label>
                </div>
                <div className="col-md-8 conceptoExtra">
                  <select
                    type="text"
                    id="vigencia"
                    required
                    className=" tipoUser"
                    name="vigencia"
                    value={notificar_vigencia}
                    onChange={handleInputNotificarvigencia}
                  >
                    <option value="Si">Sí</option>
                  </select>
                </div>
              </div>

              <div className="modal-footer">
                <div id="resultadocrear"></div>
                <button
                  onClick={handleClose2}
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>

                <button
                  onClick={fetchEditConcepto}
                  type="button"
                  className="button btn-primary"
                  data-dismiss="modal"
                  id="actionCrearUsuario"
                >
                  Editar
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={open3}
        onClose={handleClose3}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box-proyectos2">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Aumentar Vigencia
            </h5>
          </div>

          <div className="modal-body">
            <form>
              <div className="row wrapper-extra">
                <div className="col-md-4">
                  <label>Añadir Años de Vigencia</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="number"
                    id="vigenciaAños"
                    name="vigenciaAños"
                    value={numero_anos}
                    required
                    onChange={handleInputVigenciaAnos}
                    className=" width-form"
                  />
                </div>
                <br />
                <br />

                <div className="modal-footer">
                  <div id="resultadocrear"></div>
                  <button
                    onClick={handleClose3}
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>

                  <button
                    onClick={fetchNotificarVigencia}
                    type="button"
                    className="button btn-primary"
                    data-dismiss="modal"
                    id="actionCrearUsuario"
                  >
                    Añadir
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        message={mensage}
        key={vertical + horizontal}
      />
    </>
  );
};

export default DetailRenovaciones;

if (document.getElementById("detailRenovaciones")) {
  ReactDOM.render(
    <DetailRenovaciones />,
    document.getElementById("detailRenovaciones")
  );
}
