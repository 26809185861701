import Modal from "../core/ui/Modal"
import { Form, Formik } from "formik"
import { Input } from "../core/input"
import { Select } from "../core/select"
import { Button } from "../core/button/Button"
import { format } from "date-fns"
import { TextArea } from "../core/textarea"
import CheckBox from "../core/checkbox/CheckBox"
import { useExpenditureTypes, usePaymentMethods } from "../../hooks/parameters"
import { useCompanies } from "../../hooks/company"
import { useQueryClient } from "@tanstack/react-query"
import { useEditExpenditure } from "../../hooks/operation"
import { getMutationOptions } from "../../utils/functions"


const EditExpenditure = ({ modal, setModal, expenditure }) => {

	const companiesQuery = useCompanies()
	const expenditureTypesQuery = useExpenditureTypes()
	const paymentMethodsQuery = usePaymentMethods()
	const queryClient = useQueryClient()
	const editExpenditure = useEditExpenditure(getMutationOptions({
		loadingMessage: 'Modificando Egreso',
		successMessage: 'Egreso Modificado',
		errorMessage: 'Error al Modificar el Egreso'
	}))

	const updateExpenditure = async data => {
		editExpenditure.mutate({ expenditureId: expenditure.id, data }, {
			onSuccess: () => {
				// Update active query
				queryClient.refetchQueries(['expenditure'], { type: 'active' })
				setModal(false)
			}
		})
	}

	return (
		<Modal
			open={modal}
			setModal={setModal}
			title="Editar Egreso"
		>
			<Formik
				initialValues={{
					receptor_id: expenditure?.receptor.id,
					tipo_id: expenditure?.tipo.id,
					metodo_id: expenditure?.metodo.id,
					comentarios: expenditure?.comentarios || '',
					monto: expenditure?.monto,
					fecha_movimiento: format(new Date(expenditure?.fecha_movimiento || '2000-12-25'), 'y-MM-dd'),
					aplica_iva: expenditure?.aplica_iva === 'Si' ? true : false
				}}
				onSubmit={updateExpenditure}
			>
				<Form>
					<Select
						label="Receptor"
						name="receptor_id"
						horizontal
						required
					>
						<option value="">Seleccione Receptor</option>
						{
							companiesQuery.data.map(company => (
								<option key={company.id} value={company.id}>{company.name}</option>
							))
						}
					</Select>

					<Select
						label="Tipo"
						name="tipo_id"
						horizontal
						required
					>
						<option value="">Seleccione Tipo</option>
						{
							expenditureTypesQuery.data.map(type => (
								<option key={type.id} value={type.id}>{type.value}</option>
							))
						}
					</Select>

					<Select
						label="Método"
						name="metodo_id"
						horizontal
						required
					>
						<option value="">Seleccione Método</option>
						{
							paymentMethodsQuery.data.map(method => (
								<option key={method.id} value={method.id}>{method.value}</option>
							))
						}
					</Select>

					<TextArea
						label="Comentarios"
						name="comentarios"
						placeholder="Escribe tus comentarios"
						horizontal
					/>

					<Input
						label="Monto"
						name="monto"
						type="number"
						placeholder="Monto del ingreso"
						horizontal
						required
					/>

					<Input
						label="Fecha"
						name="fecha_movimiento"
						type="date"
						placeholder="Fecha"
						horizontal
						required
					/>

					<CheckBox
						label="IVA"
						name="aplica_iva"
						horizontal
					/>

					<Button
						texto="Guardar Cambios"
						formulario
						disabled={editExpenditure.isLoading}
						estilo={{ margin: '40px auto 0px auto' }}
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default EditExpenditure