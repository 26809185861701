import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from './../utils/instances'


const getDateRevenue = async (year, month) => {
  const url = `/auth/estadisticas/saldo/ingresos/${[year, month].filter(Boolean).join('/')}`
  const { data: { summary } } = await axiosInstance.get(url)
  return summary.ingresos_total
}

const getDateExpenditure = async (year, month) => {
  const url = `/auth/estadisticas/saldo/egresos/${[year, month].filter(Boolean).join('/')}`
  const { data: { summary } } = await axiosInstance.get(url)
  return summary.egresos_total
}

const getOutstandingBalance = async () => {
  const { data: { data } } = await axiosInstance.get('/auth/estadisticas/saldo/pendiente')
  return data.global.pendiente
}

const queryOptions = {
  placeholderData: 0,
  staleTime: 1000 * 60
}

export const useDateRevenue = (year, month) => {
  return useQuery(
    ['dateRevenue', year, month],
    () => getDateRevenue(year, month),
    queryOptions
  )
}

export const useDateExpenditure = (year, month) => {
  return useQuery(
    ['dateExpenditure', year, month],
    () => getDateExpenditure(year, month),
    queryOptions
  )
}

export const useOutstandingBalance = () => {
  return useQuery(
    ['outstandingBalance'],
    getOutstandingBalance,
    queryOptions
  )
}