import { Form, Formik } from "formik"
import { Button } from "./../button/Button"
import { Input } from "./../input/Input"
import "./search.css"

const Search = ({ label, placeholder, name, submit }) => {

	return (
		<Formik
			initialValues={{
				[name]: ''
			}}
			onSubmit={submit}
		>
			<Form className="search--sf">
				<Input
					name={name}
					horizontal
					label={label}
					placeholder={placeholder}
					className="search-input--sf"
				/>
				<Button texto="Buscar" formulario />
			</Form>
		</Formik>
	)

}


export default Search