import './row.css'

const Row = ({ columns = 1, levelGap = 3, children }) => {

  const colClassName = `col-${columns}--sf`
  const gapClassName = `gap-${levelGap}--sf`

  return (
    <div className={`row--sf ${colClassName} ${gapClassName}`}>
      {children}
    </div>
  )

}

export default Row