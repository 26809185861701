import { Navigate, Outlet } from 'react-router-dom'
import Layout from './layout/Layout'

const ProtectedRoute = ({ children, withToken, redirectTo = '/login', header }) => {
	const token = localStorage.getItem('token')
	const data = localStorage.getItem('data')

	// Token and data required
	if (withToken) {
		if (token && data)
			return <Layout header={header}>{children ? children : <Outlet />}</Layout>

		return <Navigate to={redirectTo} />
	}

	// Token and data no required
	if (!(token && data))
		return children ? children : <Outlet />

	return <Navigate to={redirectTo} />
}

export default ProtectedRoute