import {
	getBarChartOptions,
	getPolarChartOptions,
	getDoughnutChartOptions,
	createDoughnutChartData,
	createBarChartData,
	createPolarChartData
} from "../../utils/functions"
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	Title,
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale
} from "chart.js"
import { Doughnut, Bar, PolarArea } from "react-chartjs-2"
import { currencyFormat } from "../../utils/format"
import Card from "../core/ui/Card"


ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement, RadialLinearScale)
ChartJS.defaults.plugins.tooltip.titleFont = { family: 'Poppins', weight: 'bold' }
ChartJS.defaults.plugins.tooltip.bodyFont = { family: 'Poppins' }
ChartJS.defaults.plugins.tooltip.padding = 8
ChartJS.defaults.plugins.tooltip.displayColors = false

ChartJS.defaults.plugins.tooltip.callbacks.label = context => {
	let label = context.dataset.label || ''

	if (label)
		label += ': '

	if (context.parsed.y !== undefined)
		label += currencyFormat(context.parsed.y)
	else if (context.parsed.r !== undefined)
		label += currencyFormat(context.parsed.r)
	else
		label += currencyFormat(context.parsed)

	return label
}

ChartJS.defaults.datasets.doughnut.cutout = '62%'

const textCenter = {
	id: 'textCenter',
	beforeDatasetsDraw(chart, args, pluginOptions) {
		const { ctx, data } = chart

		ctx.save()

		let [x, y] = [chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y]
		ctx.textAlign = 'center'
		ctx.textBaseline = 'middle'

		ctx.font = 'bold 16px Poppins'
		ctx.fillStyle = '#666'
		ctx.fillText('Total:', x, y - 10)

		ctx.font = 'bold 17px Poppins'
		ctx.fillStyle = '#000'
		const total = data.datasets[0].data.reduce((a, b) => a + b)
		ctx.fillText(currencyFormat(total), x, y + 10)
	}
}


const dataIngresos = createDoughnutChartData(['Con Iva', 'Sin Iva'], [10000, 900000], 'Subtotal')

const dataEgresos = createDoughnutChartData(['Con Iva', 'Sin Iva'], [15, 200], 'Subtotal')

const dataMeses = createBarChartData(
	['Enero', 'Febrero', 'Marzo'],
	['Ingresos', 'Egresos'],
	{ Ingresos: [100000, 200000, 150000], Egresos: [90000, 100000, 250000] }
)

const dataTipoIngresos = createPolarChartData(['Abonos', 'Inversiones', 'Otro'], [50000, 10000, 1000], 'Total')

const dataTipoEgresos = createPolarChartData(
	['Desarrollo', 'Publicidad', 'Insumos Oficina', 'Otro'],
	[50000, 30000, 10000, 20000],
	'Total'
)

const StatisticCharts = () => {

	return (
		<Card>
			<div id="statistic-charts--sf">
				<div id='main-chart'>
					<Bar options={getBarChartOptions('Operaciones del 2023 por Mes')} data={dataMeses} />
				</div>

				<div id='revenue-chart'>
					<Doughnut options={getDoughnutChartOptions('Ingresos 2023')} data={dataIngresos} plugins={[textCenter]} />
				</div>

				<div id='expenditure-chart'>
					<Doughnut options={getDoughnutChartOptions('Egresos 2023')} data={dataEgresos} plugins={[textCenter]} />
				</div>

				<div id="types-charts">
					<PolarArea options={getPolarChartOptions('Ingresos por Tipos 2023')} data={dataTipoIngresos} />
					<PolarArea options={getPolarChartOptions('Egresos por Tipo 2023')} data={dataTipoEgresos} />
				</div>
			</div>
		</Card>
	)

}

export default StatisticCharts