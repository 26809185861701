const LoginImage = () => {

  return (
    <div className="login-image">
      <img alt="Selfish" src="/assets/Collage.png" />
    </div>
  )

}

export default LoginImage