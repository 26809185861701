const Card = ({ children }) => {

  return (
    <div className="login-card">
      {children}
    </div>
  )

}

export default Card