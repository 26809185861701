import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import constants from "../utils/constants";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import Loading from "./Loading";
import "../sass/assets/css/style.css";

var moment = require("moment");

//estilos del filter
const TextFields = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
`;

//estilos del boton FIlter
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
`;

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#c1c1c1",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#c1c1c1",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#c1c1c1",
    },
    "&:hover fieldset": {
      borderColor: "#c1c1c1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#c1c1c1",
    },
  },
});

//componente del filtro
const FilterComponent = ({ filterText, onFilter, onClear, state, handleChangeStatus }) => (
  <>
    {/* <FormControl>
  <InputLabel id="demo-simple-select-label">Estatus:</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={state}
      label="Estatus"
      onChange={handleChangeStatus}
    >
      <MenuItem value={'Completado'}>Completado</MenuItem>
      <MenuItem value={'Cancelado'}>Cancelado</MenuItem>
      <MenuItem value={'Pausado'}>Pausado</MenuItem>
      <MenuItem value={'Procesando'}>Procesando</MenuItem>
      <MenuItem value={'Prospectando'}>Prospectando</MenuItem>
    </Select>
    </FormControl> */}
    <TextFields
      id="search"
      type="text"
      placeholder="Filtrar por empresa"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const Proyectos = () => {
  // Loading
  const [loading, setLoading] = useState(true);
  const changeLoading = () => {
    setTimeout(() => {
      // setLoading(false);
    }, 2000);
  };

  //MODAL DEL FORMULARIO
  const [open, setOpen] = React.useState(false);

  //MODAL DEL FORMULARIO editar
  const [open2, setOpen2] = React.useState(false);

  //MODAL DEL FORMULARIO editar
  const [open3, setOpen3] = React.useState(false);

  const [status, setStatus] = React.useState('');

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  //modal formulario abrir/cerrar
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    cleanData();
    setOpen(false);
  };

  //modal formulario abrir/cerrar EDITAR
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    cleanData();
    setOpen2(false);
  };

  //modal formulario abrir/cerrar EDITAR
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => {
    cleanData();
    setOpen3(false);
  };

  // ALERTAS
  const [openAlert, setOpenAlert] = React.useState(false);
  const [mensage, setMensage] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //Alerta
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //useState de los campos del form
  const [empresa, setEmpresa] = useState(null);
  const [responsable, setResponsable] = useState(null);
  const [proyecto_nombre, setProyecto_nombre] = useState("");
  const [cliente, setCliente] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [estatus, setEstatus] = useState(null);
  const [precio, setPrecio] = useState("");
  const [descuento, setDescuento] = useState(null);
  const [iva, setIva] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [moneda, setMoneda] = useState("");

  //EDITS
  const [projectid, setID] = useState("");
  const [projectid2, setID2] = useState("");

  //useStato para el .map
  const [proyectos, setProyectos] = useState([]);
  const [totalProyectos, setTotalProyectos] = useState(0);

  const [empresas, setEmpresas] = useState([]);
  const [autocompleteProject, setAutocompleteProject] = useState([]);
  const [autocompleteResponsable, setAutocompleteResponsable] = useState([]);
  const [autocompleteTipo, setAutocompleteTipo] = useState([]);
  const [autocompleteCliente, setAutocompleteCliente] = useState([]);
  const [autocompleteEstatus, setAutocompleteEstatus] = useState([]);
  const [autocompleteDescuento, setAutocompleteDescuento] = useState([]);
  const [rows, setRows] = useState(20);
  //RadioButtom
  const [selectedValue, setSelectedValue] = useState("");

  //Pagination
  const [page, setPage] = useState(1);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  //alerta para eliminar
  const ConfirmAlert = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
        Swal.fire("Eliminado!", "El Proyecto fue Eliminado.", "success");
      }
    });
  };

  //delete contactos
  const onDelete = async (proyectoID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/proyecto/" + proyectoID,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchProyectos();
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //Editar Proyectos
  const fetchEditProjects = async () => {
    if (proyecto_nombre == "") {
      setMensage("Añadir campo Nombre del Proyecto.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (empresa == null) {
      setMensage("Añadir campo Empresa.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (cliente == null) {
      setMensage("Añadir campo Cliente.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (responsable == null) {
      setMensage("Añadir campo Responsable.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (estatus == null) {
      setMensage("Añadir campo Estatus.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (fechaInicio == "") {
      setMensage("Añadir campo Fecha Inicio.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (fechaFinal == "") {
      setMensage("Añadir campo Fecha Final.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (comentarios == "") {
      setMensage("Añadir campo Comentarios.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/proyecto/" + projectid,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            proyecto_nombre: proyecto_nombre,
            empresa_id: empresa.id,
            cliente_id: cliente.id,
            responsable_id: responsable.id,
            estatus_id: estatus.id,
            comentarios: comentarios,
            fecha_inicio: fechaInicio,
            fecha_entrega: fechaFinal,
          }),
        }
      );
      const result = await response.json();
      //Alerta de crear usuario
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Proyecto se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchProyectos();
        cleanData();
        handleClose2();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar Proyecto",
          text: "Intente de nuevo",
        });
        handleClose2();
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Set elements on edit projects
  const setEdit = async (proyectosEdit) => {
    const fechaMoment = moment(proyectosEdit?.fecha_inicio).format(
      "YYYY-MM-DDThh:mm"
    );

    const fechaMomentFinal = moment(proyectosEdit?.fecha_entrega).format(
      "YYYY-MM-DDThh:mm"
    );

    handleOpen2();
    setEmpresa({
      label: proyectosEdit?.empresa?.nombre_comercial,
      id: proyectosEdit.empresa.id,
    });
    setCliente({
      label:
        proyectosEdit?.cliente?.person?.nombre +
        " " +
        proyectosEdit?.cliente?.person?.apellido,
      id: proyectosEdit.cliente.id,
    });
    setResponsable({
      label:
        proyectosEdit?.responsable?.person?.nombre +
        " " +
        proyectosEdit?.responsable?.person?.apellido,
      id: proyectosEdit.responsable.id,
    });
    setEstatus({
      label: proyectosEdit?.estatus?.valor,
      id: proyectosEdit.estatus.id,
    });
    setComentarios(proyectosEdit?.comentarios);
    setFechaInicio(fechaMoment);
    setFechaFinal(fechaMomentFinal);
    setProyecto_nombre(proyectosEdit?.proyecto_nombre);
    setID(proyectosEdit.id);
  };

  //Editar estatus Proyectos
  const fetchEditEstatus = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/proyecto/" + projectid2,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            estatus_id: estatus.id,
          }),
        }
      );
      const result = await response.json();

      //Alerta de ESditar Estatus
      if (result?.success) {
        fetchProyectos();
        cleanData();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Estatus se ha editado con exito",
          showConfirmButton: false,
          timer: 3000,
        });

        handleClose3();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar el Estatus",
          text: "Intente de nuevo",
        });
        handleClose();
      }
    } catch (error) {
      if (estatus == null) {
        setMensage("Añadir campo Estatus.");
        setOpenAlert(true);
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
    }
  };

  //Set elements on edit ESTATUS
  const setEdit3 = async (proyectosEdit) => {
    handleOpen3();
    setEstatus({
      label: proyectosEdit?.estatus?.valor,
      id: proyectosEdit.estatus.id,
    });
    setID2(proyectosEdit.id);
  };

  //DATA
  const columns = [
    {
      name: "Tipo",
      selector: (row) => row?.conceptos[0]?.tipo?.valor,
      sortable: true,
    },
    {
      name: "Empresa",
      selector: (row) => row?.empresa?.nombre_comercial,
      sortable: true,
    },
    {
      name: "Proyecto",
      selector: (row) => row?.proyecto_nombre,
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (row) =>
        row?.cliente?.person?.nombre + " " + row?.cliente?.person?.apellido,
      sortable: true,
    },
    {
      name: "Responsable",
      selector: (row) =>
        row?.responsable?.person?.nombre +
        " " +
        row?.responsable?.person?.apellido,
      sortable: true,
    },
    {
      name: "Estatus",
      selector: (row) => row?.estatus?.valor,
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-info edit-delete-buttons"
          onClick={() => setEdit(row)}
        >
          <i className="material-icons padding-aling-editdelete">edit</i>
        </button>
      ),
    },
    {
      name: "Eliminar",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-danger edit-delete-buttons"
          onClick={() => ConfirmAlert(row.id)}
          value="Eliminar"
        >
          <i className="material-icons padding-aling-editdelete">delete</i>
        </button>
      ),
    },
  ];

  //const para el filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const proyectoss = proyectos;
  //SubHeader del filtro
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //crear Proyecto
  const submitCreateProyecto = async () => {
    if (proyecto_nombre == "") {
      setMensage("Añadir campo Nombre Proyecto.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (empresa == null) {
      setMensage("Añadir campo Empresa.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (cliente == null) {
      setMensage("Añadir campo Cliente.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (responsable == null) {
      setMensage("Añadir campo Responsable.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (tipo == null) {
      setMensage("Añadir campo Tipo.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (estatus == null) {
      setMensage("Añadir campo Estatus.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (precio == "") {
      setMensage("Añadir campo Precio.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (iva == "") {
      setMensage("Añadir campo Iva.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else if (fechaInicio == "") {
      setMensage("Añadir campo Fecha Inicio.");
      setOpenAlert(true);
      handleClick({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const fechaIni = moment(fechaInicio).format("YYYY-MM-DD HH:mm:ss");
      const fechaFina =
        fechaFinal == ""
          ? ""
          : moment(fechaFinal).format("YYYY-MM-DD HH:mm:ss");
      const response = await fetch(constants.api + "auth/proyecto/store", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          empresa_id: empresa.id,
          cliente_id: cliente.id,
          proyecto_nombre: proyecto_nombre,
          responsable_id: responsable.id,
          tipo_id: tipo.id,
          estatus_id: estatus.id,
          importe: precio,
          descuento: descuento == null ? "0" : descuento.label,
          iva: iva,
          fecha_inicio: fechaIni,
          fecha_entrega: fechaFina,
          comentarios: comentarios,
          moneda: "MXN",
        }),
      });
      const result = await response.json();
      //Alerta de crear proyecto
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "El Proyecto se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchProyectos();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al registrar el Proyecto",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  useEffect(() => {
    fetchProyectos();
  }, [rows])


  //CONSULTAR Proyectos
  const fetchProyectos = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      constants.api + "auth/proyecto?columns[0][name]=fecha_inicio&order[0][column]=0&order[0][dir]=desc&search[value]=" + filterText,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          start: (page - 1) * rows,
          length: rows,
        }),
      }
    );
    const result = await response.json();

    if (result?.success) {
      setProyectos(result.data);
      setTotalProyectos(result.recordsTotal);
    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
    setLoading(false);
  };

  // Consultar proyecto1
  //CONSULTAR Proyectos
  const fetchProyectos1 = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      constants.api + "auth/proyecto?search[value]=" + filterText,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          start: (page - 1) * rows,
          length: rows,
        }),
      }
    );
    const result = await response.json();
    if (result?.success) {
      setProyectos(result.data);
      setTotalProyectos(result.recordsTotal);
    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
    setLoading(false);
  };

  // Limpiar form
  const cleanData = () => {
    setEmpresa(null);
    setResponsable(null);
    setCliente(null);
    setProyecto_nombre("");
    setTipo(null);
    setEstatus(null);
    setPrecio("");
    setDescuento(null);
    setIva("");
    setFechaInicio("");
    setFechaFinal("");
    setComentarios("");
    setMoneda("");
  };

  //CONSULTAR Empresas para el FORM
  const fetchEmpresas = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(constants.api + "auth/empresa", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const result = await response.json();
      setEmpresas(result.data);
      let arrayEmpresa = [];
      for (const key in result.data) {
        arrayEmpresa.push({
          label: result.data[key].nombre_comercial,
          id: result.data[key].id,
        });
      }
      setAutocompleteProject(arrayEmpresa);
    } catch (error) {
      alert("error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR Responsables para el FORM
  const fetchResponsables = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/contacto?search[tipo]=equipo",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      setEmpresas(result.data);
      let arrayResponsable = [];
      for (const key in result.data) {
        arrayResponsable.push({
          label:
            result.data[key].nombre +
            " " +
            result.data[key].apellido,
          id: result.data[key].id,
        });
      }
      setAutocompleteResponsable(arrayResponsable);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR Tipos para el FORM
  const fetchTipos = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=proyectos&search[sub_grupo]=Categorias",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      setEmpresas(result.data);
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteTipo(arrayTipo);
    } catch (error) {
      alert("error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR clientes para el FORM
  const fetchClientes = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/contacto?search[value]=" + empresa?.label,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      setEmpresas(result.data);
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: `${result.data[key].nombre} ${result.data[key].apellido}`,
          id: result.data[key].contactos[0].id
        });
      }
      setAutocompleteCliente(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR Estatus para el FORM
  const fetchEstatus = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=proyectos&search[sub_grupo]=Estatus",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      setEmpresas(result.data);
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteEstatus(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //CONSULTAR Descuento para el FORM
  const fetchDescuento = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api +
        "auth/parametro?search[grupo]=proyectos&search[sub_grupo]=Descuentos",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();

      setEmpresas(result.data);
      let arrayTipo = [];
      arrayTipo.push({ label: "0", id: 1 });
      for (const key in result.data) {
        arrayTipo.push({
          label: result.data[key].valor,
          id: result.data[key].id,
        });
      }
      setAutocompleteDescuento(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  // Boton para validar nombre
  //CONSULTAR clientes para el FORM
  const submmitValidarNombre = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/proyecto?search[value]=" + proyecto_nombre,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      if (result.data.length >= 1) {
        setMensage("El Nombre del Proyecto NO esta disponible");
        setOpenAlert(true);
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        return;
      } else {
        setMensage("El Nombre del Proyecto esta disponible");
        setOpenAlert(true);
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchEmpresas();
        fetchResponsables();
        fetchTipos();
        fetchEstatus();
        fetchDescuento();
      }
    }, 3000);
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchClientes();
      }
    }, 3000);
    return () => {
      unmounted = true;
    };
  }, [empresa]);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchProyectos1();
      }
    }, 3000);
    return () => {
      unmounted = true;
    };
  }, []);
  // [] Aqui va la variable del button

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchProyectos();
      }
    }, 3000);
    return () => {
      unmounted = true;
    };
  }, [page, filterText]);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        changeLoading();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  //onChanges
  const handleInputEmpresa = (event, values) => {
    setEmpresa(values);
  };

  const handleInputResponsable = (event, values) => {
    setResponsable(values);
  };

  const handleInputCliente = (event, values) => {
    setCliente(values);
  };

  const handleInputTipo = (event, values) => {
    setTipo(values);
  };

  const handleInputEstatus = (event, values) => {
    setEstatus(values);
  };

  const handleInputPrecio = (event) => {
    setPrecio(event.target.value);
  };

  const handleInputNombreProyecto = (event) => {
    setProyecto_nombre(event.target.value);
  };

  const handleInputDescuento = (event, values) => {
    setDescuento(values);
  };

  const handleInputIva = (event) => {
    setIva(event.target.value);
  };

  const handleInputFechaInicio = (event) => {
    setFechaInicio(event.target.value);
  };

  const handleInputFechaFinal = (event) => {
    setFechaFinal(event.target.value);
  };

  const handleInputComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const handleInputMoneda = (event) => {
    setMoneda(event.target.value);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="main-panel panel-main">
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
          <div className="container-fluid">
            <div className="navbar-wrapper z-index-card mediaContact ">
              <a className="navbar-brand" href="">
                SELFISH Control Proyectos
              </a>
            </div>
          </div>
        </nav>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">Proyectos</h4>
                    <p className="card-category button-add-user">
                      <Button
                        type="button"
                        className="btn btn-success buttonaddusers"
                        onClick={handleOpen}
                      >
                        <i className="material-icons padding-aling">add</i>
                        Añadir
                      </Button>
                    </p>
                  </div>

                  <div className="card-body table-responsive">
                    <DataTable
                      conditionalRowStyles={() => ''}
                      customStyles={{
                        rows: {
                          style: {
                            cursor: 'pointer',
                            userSelect: 'none'
                          }
                        }
                      }}
                      onRowClicked={({id}) => window.location.href = `/detailProjects/${id}`}
                      columns={columns}
                      data={proyectoss}
                      paginationTotalRows={totalProyectos}
                      paginationServer
                      noDataComponent={
                        loading ? (
                          <Loading />
                        ) : (
                          <p className="sin-datos">
                            No hay datos para mostrar
                          </p>
                        )
                      }
                      paginationPerPage={rows}
                      onChangeRowsPerPage={(row) => setRows(row)}
                      onChangePage={(page) => setPage(page)}
                      paginationComponentOptions={{
                        rowsPerPageText: 'Filas por página',
                        rangeSeparatorText: 'de',
                        selectAllRowsItem: true,
                        selectAllRowsItemText: 'Todos',
                      }}
                      pagination
                      highlightOnHover
                      paginationResetDefaultPage={resetPaginationToggle}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box-proyectos marg-espacio">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabelq">
              Crear Proyecto
            </h5>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4">
                  <label className="marg-espacio1">
                    Nombre del Proyecto
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="nombreProyectoq"
                    name="nombreProyecto"
                    value={proyecto_nombre}
                    required
                    /* className=" width-form" */
                    onChange={handleInputNombreProyecto}
                    className=" width-form marg-espacio"
                  />
                </div>

                <div className="col-md-4"></div>
                <div className="col-md-8 ">
                  <div
                    className="btn btn-success proyect-btn"
                    onClick={submmitValidarNombre}
                  >
                    Validar
                  </div>
                </div>

                <div className="col-md-4 label-forms">
                  <label className="labels-forms">Empresa</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputEmpresa}
                    name="empresa"
                    required
                    getOptionLabel={(options) => options.label || ""}
                    value={empresa}
                    disablePortal
                    id="combo-box-demoq"
                    options={autocompleteProject}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basicq"
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4 label-forms">
                  <label className="labels-forms">Cliente</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputCliente}
                    name="cliente"
                    required
                    disablePortal
                    id="combo-box-demow"
                    getOptionLabel={(options) => options.label || ""}
                    value={cliente}
                    options={autocompleteCliente}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basicw"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4 label-forms">
                  <label className="labels-forms">Responsable</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputResponsable}
                    name="responsable"
                    required
                    disablePortal
                    id="combo-box-demoe"
                    getOptionLabel={(options) => options.label || ""}
                    value={responsable}
                    options={autocompleteResponsable}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basicr"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4 label-forms">
                  <label className="labels-forms">Estatus</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputEstatus}
                    name="estatus"
                    required
                    disablePortal
                    id="combo-box-demot"
                    getOptionLabel={(options) => options.label || ""}
                    value={estatus}
                    options={autocompleteEstatus}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basict"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4  pad-Iva">
                  <label className="labels-forms">Comentarios</label>
                </div>
                <div className="col-md-8  pad-Iva">
                  <textarea
                    value={comentarios}
                    className="size-Textare"
                    onChange={handleInputComentarios}
                    name="comentarios"
                    type="textarea"
                    id="comentariosy"
                    placeholder="Escribe tus comentarios aquí..."
                    rows="5"
                    cols="100"
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4 pad-Iva">
                  <label className="marg-espacio1">Fecha de Inicio</label>
                </div>
                <div className="col-md-8 pad-Iva">
                  <input
                    type="datetime-local"
                    id="fechaInicioy"
                    name="fechaInicio"
                    value={fechaInicio}
                    required
                    /* className=" width-form" */
                    onChange={handleInputFechaInicio}
                    className=" width-form marg-espacio"
                  />
                </div>

                <div className="col-md-4 pad-Iva">
                  <label className="marg-espacio1">Fecha de Entrega</label>
                </div>
                <div className="col-md-8 pad-Iva">
                  <input
                    type="datetime-local"
                    id="fechaFinaly"
                    name="fechaFinal"
                    value={fechaFinal}
                    required
                    /* className=" width-form" */
                    onChange={handleInputFechaFinal}
                    className=" width-form"
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4 label-forms">
                  <label className="labels-forms">Tipo</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputTipo}
                    name="tipo"
                    required
                    disablePortal
                    id="combo-box-demou"
                    getOptionLabel={(options) => options.label || ""}
                    value={tipo}
                    options={autocompleteTipo}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basicu"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4 importe">
                  <label className="marg-espacio2 ">Importe</label>
                </div>
                <div className="col-md-8 importe">
                  <input
                    type="text"
                    id="precioi"
                    name="precio"
                    placeholder="000.00"
                    value={precio}
                    required
                    /* className=" width-form" */
                    onChange={handleInputPrecio}
                    className=" style-precio marg-espacio2"
                  />
                  <label className="marg-espacio2">MXN</label>
                </div>
                <br />
                <br />

                <div className="col-md-4 label-forms">
                  <label className="labels-forms">Descuento %</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    disablePortal
                    id="combo-box-demoi"
                    options={autocompleteDescuento}
                    getOptionLabel={(options) => options.label || ""}
                    defaultValue={{ label: "0", id: 1 }}
                    sx={{ width: 300 }}
                    onChange={handleInputDescuento}
                    name="descuento"
                    required
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basici"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4 conceptoExtra pad-Iva">
                  <label>IVA</label>
                </div>
                <div className="col-md-8 conceptoExtra pad-Iva">
                  <select
                    type="text"
                    id="ivai"
                    required
                    className=" tipoUser"
                    name="iva"
                    value={iva}
                    onChange={handleInputIva}
                  >
                    <option value="DEFAULT" defaultValue>
                      Elija Una Opción
                    </option>
                    <option value="Si">Sí</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <br />
                <br />
                <div className="col-md-4 conceptoExtra pad-Iva">
                  <label>Moneda</label>
                </div>

                <div className="col-md-8 conceptoExtra pad-Iva">
                  <label>MXN</label>
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div id="resultadocrearo"></div>
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>

            <button
              onClick={submitCreateProyecto}
              type="button"
              className="button btn-primary"
              data-dismiss="modal"
              id="actionCrearUsuarioo"
            >
              CREAR
            </button>
            {/*   )} */}
          </div>
        </Box>
      </Modal>

      {/* EDITAR*/}

      <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box-proyectos">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabelp">
              Editar Proyecto
            </h5>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4">
                  <label>Nombre del Proyecto</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="nombreProyectop"
                    name="nombreProyecto"
                    value={proyecto_nombre}
                    required
                    /* className=" width-form" */
                    onChange={handleInputNombreProyecto}
                    className=" width-form"
                  />
                </div>

                <div className="col-md-4">
                  <label>Empresa</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputEmpresa}
                    name="empresa"
                    required
                    getOptionLabel={(options) => options.label || ""}
                    value={empresa}
                    disablePortal
                    id="combo-box-demop"
                    options={autocompleteProject}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basicp"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4">
                  <label>Cliente</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputCliente}
                    name="cliente"
                    required
                    disablePortal
                    id="combo-box-demoa"
                    getOptionLabel={(options) => options.label || ""}
                    value={cliente}
                    options={autocompleteCliente}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basica"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4">
                  <label>Responsable</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputResponsable}
                    name="responsable"
                    required
                    disablePortal
                    id="combo-box-demos"
                    getOptionLabel={(options) => options.label || ""}
                    value={responsable}
                    options={autocompleteResponsable}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basics"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4">
                  <label>Estatus</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputEstatus}
                    name="estatus"
                    required
                    disablePortal
                    id="combo-box-demod"
                    getOptionLabel={(options) => options.label || ""}
                    value={estatus}
                    options={autocompleteEstatus}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basicd"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4 label-forms">
                  <label className="labels-forms">Comentarios</label>
                </div>
                <div className="col-md-8 style-roles margin-forms">
                  <textarea
                    value={comentarios}
                    className="size-Textare"
                    onChange={handleInputComentarios}
                    name="comentarios"
                    type="textarea"
                    id="comentariosd"
                    placeholder="Escribe tus comentarios aquí..."
                    rows="5"
                    cols="70"
                  />
                </div>
                <br />
                <br />

                <div className="col-md-4">
                  <label>Fecha de Inicio</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="datetime-local"
                    id="fechaIniciof"
                    name="fechaInicio"
                    value={fechaInicio}
                    required
                    /* className=" width-form" */
                    onChange={handleInputFechaInicio}
                    className=" width-form"
                  />
                </div>

                <div className="col-md-4">
                  <label>Fecha de Entrega</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="datetime-local"
                    id="fechaFinalf"
                    name="fechaFinal"
                    value={fechaFinal}
                    required
                    /* className=" width-form" */
                    onChange={handleInputFechaFinal}
                    className=" width-form"
                  />
                </div>
                <br />
                <br />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div id="resultadocrearf"></div>
            <button
              onClick={handleClose2}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>

            <button
              onClick={fetchEditProjects}
              type="button"
              className="button btn-primary"
              data-dismiss="modal"
              id="actionCrearUsuariog"
            >
              Editar
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={open3}
        onClose={handleClose3}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="boxstyles  responsive-box-proyectos2">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabelg">
              Editar Estatus
            </h5>
          </div>

          <div className="modal-body">
            <form>
              <div className="row wrapper-extra">
                <div className="col-md-4">
                  <label>Estatus</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles margin-forms"
                    onChange={handleInputEstatus}
                    name="estatus"
                    disablePortal
                    id="combo-box-demoh"
                    getOptionLabel={(options) => options.label || ""}
                    value={estatus}
                    options={autocompleteEstatus}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        id="standard-basich"
                        variant="standard"
                        {...params}
                        label="Estatus"
                        placeholder="Estatus"
                        required
                      />
                    )}
                  />
                </div>
                <br />
                <br />

                <div className="modal-footer">
                  <div id="resultadocrearj"></div>
                  <button
                    onClick={handleClose3}
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>

                  <button
                    onClick={fetchEditEstatus}
                    type="button"
                    className="button btn-primary"
                    data-dismiss="modal"
                    id="actionCrearUsuarioqj"
                  >
                    Editar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {/* EDITAR */}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        message={mensage}
        key={vertical + horizontal}
      />
    </>
  );
};

export default Proyectos;

if (document.getElementById("proyectos")) {
  ReactDOM.render(<Proyectos />, document.getElementById("proyectos"));
}
