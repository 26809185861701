import React, { useState } from "react";
import ReactDOM from "react-dom";
import Card from "@mui/material/Card";
import constants from "../utils/constants";
import "../sass/assets/css/style.css";
import logoSelfish from "../images/logoSelfish.png";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "Black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});
const Correopassword = () => {
  //SHOW PASSWORD
  const [shown, setShown] = React.useState(false);
  const switchShown = () => setShown(!shown);

  const [email, setEmail] = useState("");

  const resetPass = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch("https://api.selfish.com.mx/v1/reset", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      const result = await response.json();
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Token enviado Correctamente",
          showConfirmButton: false,
          timer: 3000,
        });
        window.location.replace("/tokenpassword");
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  const handleInputEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <Card className="login1">
        <div className="row-width">
          <div className="">
            <img className="logoLogin" src={logoSelfish} alt="logo" />
          </div>
        </div>

        <div className="logIN">
          <span className="correotoken">Recupera tu cuenta</span>

          <div className="row">
            <div className="col-md-12">
              <p className="correotokentext">
                Ingresa tu correo electrónico para mandar un código único.
              </p>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <CssTextField
                  className="xgrand dark"
                  id="standard-password-input"
                  label="Correo Electronico"
                  type={"text"}
                  variant="standard"
                  onChange={handleInputEmail}
                  value={email}
                  name="email"
                />
              </Box>
            </div>

            <button className="boton-login" onClick={resetPass}>
              Enviar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Correopassword;

if (document.getElementById("correopassword")) {
  ReactDOM.render(
    <Correopassword />,
    document.getElementById("correopassword")
  );
}
