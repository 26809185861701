import { useState } from "react"
import { useDeleteExpenditure, useGetExpenditure } from "../../hooks/operation"
import Row from "../core/layout/Row"
import Card from "../core/ui/Card"
import Search from "../core/ui/Search"
import { format } from "date-fns"
import { currencyFormat } from "../../utils/format"
import { IconButton } from "../core/button"
import notification from "../../utils/notification"
import EditExpenditure from "./EditExpenditure"
import ControlledTable from "../core/ui/ControlledTable"
import { useQueryClient } from "@tanstack/react-query"
import { getMutationOptions } from "../../utils/functions"
import { useStatisticFilters } from "../../store/statisticStore"
import { shallow } from "zustand/shallow"


const ExpenditureList = () => {

	const deleteExpenditure = useDeleteExpenditure(getMutationOptions({
		loadingMessage: 'Eliminando Egreso',
		successMessage: 'Egreso Eliminado Correctamente',
		errorMessage: 'Error al Eliminar Egreso'
	}))
	const globalFilters = useStatisticFilters(state => ({
		year: state.year,
		month: state.month,
		expenditureTypeId: state.expenditureTypeId
	}), shallow)
	const [searchValue, setSearchValue] = useState('')
	const [modal, setModal] = useState({ isOpen: false, expenditure: null })
	const queryClient = useQueryClient()

	const expenditureColumns = [
		{ header: 'Receptor', accessorKey: 'receptor.nombre_comercial' },
		{ header: 'Fecha', accessorFn: ({ fecha_movimiento }) => format(new Date(fecha_movimiento), 'dd-MM-y') },
		{ header: 'Tipo', accessorKey: 'tipo.valor' },
		{ header: 'Comentarios', accessorKey: 'comentarios' },
		{ header: 'Monto', accessorFn: ({ total }) => currencyFormat(total) },
		{ header: 'IVA', accessorFn: ({ iva }) => currencyFormat(iva) },
		{
			header: 'Acciones', accessorFn: row => row, cell: ({ getValue }) => {
				const expenditure = getValue()
				return <>
					<IconButton icon="edit" accion={() => setModal({ isOpen: true, expenditure })} />
					<IconButton icon="delete" accion={() => notification.confirm(
						`¿Estás seguro de eliminar el egreso de ${expenditure.receptor.nombre_comercial}?`,
						() => {
							deleteExpenditure.mutate(expenditure.id, {
								onSuccess: () => {
									// Update active query
									queryClient.refetchQueries(['expenditure'], { type: 'active' })
								}
							})
						}
					)} />
				</>
			}
		}
	]

	return (
		<>
			<Card title="Lista de Egresos">
				<Row>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Search
							label="Buscar:"
							name="value"
							placeholder="Nombre del Receptor"
							submit={({ value }) => setSearchValue(value)}
						/>
					</div>
				</Row>

				<ControlledTable
					columns={expenditureColumns}
					useQueryFetch={useGetExpenditure}
					customPageSize={25}
					filters={{ ...globalFilters, searchValue }}
				/>
			</Card>

			<EditExpenditure
				modal={modal.isOpen}
				setModal={setModal}
				expenditure={modal.expenditure}
			/>
		</>
	)

}

export default ExpenditureList