import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SideNav from "./sidenav/SideNav";
import constants from "../utils/constants";
import Button from "@mui/material/Button";
import { Autocomplete, MenuItem, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import "../sass/assets/css/style.css";
import Swal from "sweetalert2";
import Loading from "./Loading";
import { OutlinedInput, Select } from "@material-ui/core";

//estilos del filter
const TextFields = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

//estilos del boton FIlter
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
`;
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#c1c1c1",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#c1c1c1",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#c1c1c1",
    },
    "&:hover fieldset": {
      borderColor: "#c1c1c1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#c1c1c1",
    },
  },
});

const Error = styled.div`
  background-color: #ffb3b3;
  color: white;
  text-align: center;
  padding: 3px;
  margin-top: 0px;
  margin-bottom: 5px;
`;
//componente del filtro
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextFields
      id="search"
      type="text"
      placeholder="Filtrar por empresa"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const Empresas = () => {
  // Loading
  const [loading, setLoading] = useState(true);

  const [empresas, setEmpresas] = useState([]);
  // Traer Contactos

  //const para el filter
  const [filterText, setFilterText] = React.useState("");
  const [contactos, setContactos] = useState([]);
  const [autocompleteCliente, setAutocompleteCliente] = useState([]);
  const [rows, setRows] = useState(10);
  const [error, setError] = useState(false);
  // Const EDIT
  const [empresaID, setID] = useState("");

  //mensaje de crear usuarios
  const [mensage, setMensage] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  //cerrar alerta al crear
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //Snackbar alerta
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //Pagination
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  //Alerta Snackbar
  const { vertical, horizontal, message } = state;
  const handleClick = (newState) => () => {
    setState({ ...newState });
  };

  const [open, setOpen] = React.useState(false);

  //modal formulario abrir/cerrar
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    cleanData();
  };

  //useState para fomularios
  const [nombre_comercial, setNombre_comercial] = useState("");
  const [razon_social, setRazon_social] = useState("");
  const [direccion, setDireccion] = useState("");
  const [rfc, setRfc] = useState("");
  const [industria, setIndustria] = useState("");
  const [web, setWeb] = useState("");

  useEffect(() => {
    fetchEmpresasRead();
  }, [rows])

  //Consultar Empresas
  const fetchEmpresasRead = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      constants.api + "auth/empresa?search[value]=" + filterText,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          start: (page - 1) * rows,
          length: rows,
        }),
      }
    );
    const result = await response.json();
    setEmpresas(result.data);
    if (result?.success) {
      setEmpresas(result.data);
      setTotalData(result.recordsTotal);
    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchEmpresasRead();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, [page, filterText]);

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchContacto();
        console.log('fetch contacto')
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  const delayEdit = (id) => {
    setTimeout(() => {
      fetchEditEmpresas(id);
    }, 2000);
  };

  //alerta para eliminar
  const ConfirmAlert = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Este cambio será permanente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
      cancelButtonText: "Cancelar",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(id);
        Swal.fire("Eliminado!", "La Empresa fue Eliminada.", "success");
      }
    });
  };

  //alert 2
  const [openAlert1, setOpenAlert1] = React.useState(false);
  const [mensage1, setMensage1] = React.useState(false);
  const [state1, setState1] = React.useState({
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  //Alerta1
  const { vertical1, horizontal1, message1 } = state1;
  const handleClick1 = (newState) => () => {
    setState1({ ...newState });
  };

  //cerrar alerta al crear1
  const handleCloseAlert1 = () => {
    setOpenAlert1(false);
  };

  //Delete Empresas
  const onDelete = async (contactoID) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/empresa/" + contactoID,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      fetchEmpresasRead();
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Editar Empresas
  const fetchEditEmpresas = async (id, edit = 0) => {
    if (nombre_comercial == "") {
      setMensage1("Añadir campo Nombre Comercial.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    try {
      const token = localStorage.getItem("token");
      var urlencoded = new URLSearchParams();
      contactos.map((item, i) =>
        urlencoded.append(`contacts[add][${i}]`, item.id)
      );
      urlencoded.append("web", web);
      urlencoded.append("nombre_comercial", nombre_comercial);
      urlencoded.append("razon_social", razon_social);
      urlencoded.append("direccion", direccion);
      urlencoded.append("rfc", rfc);
      urlencoded.append("industria", industria);
      const response = await fetch(constants.api + "auth/empresa/" + id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
        body: urlencoded,
      });
      const result = await response.json();
      //Alerta de editar usuario
      if (result?.success) {
        if (edit == 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "La Empresa se ha editado con exito",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        fetchEmpresasRead();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al editar Empresa",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  // Boton para validar nombre
  //CONSULTAR clientes para el FORM
  const submmitValidarNombre = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/empresa?search[value]=" + nombre_comercial,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();

      if (result.data.length >= 1) {
        setMensage("El Nombre del Proyecto NO esta disponible");
        setOpenAlert(true);
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        return;
      } else {
        setMensage("El Nombre del Proyecto esta disponible");
        setOpenAlert(true);
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //PAGINACION

  const columns = [
    {
      name: "Nombre Comercial",
      selector: (row) => row?.nombre_comercial,
      sortable: true,
    },
    {
      name: "Industria",
      selector: (row) => row?.industria,
      sortable: true,
    },
    {
      name: "Contacto",
      selector: (row) =>
        row?.contactos[0]?.person?.nombre +
        " " +
        row?.contactos[0]?.person?.apellido,
      sortable: true,
    },
    {
      name: "Teléfono",
      selector: (row) => row?.contactos[0]?.person?.telefono_movil,
      sortable: true,
    },
    {
      name: "Correo",
      selector: (row) => row?.contactos[0]?.person?.email,
      sortable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-info edit-delete-buttons"
          onClick={() => setEdit(row)}
        >
          <i className="material-icons padding-aling-editdelete">edit</i>
        </button>
      ),
    },
    {
      name: "Delete",
      button: true,
      cell: (row) => (
        <button
          type="button"
          className="btn btn-danger edit-delete-buttons"
          onClick={() => ConfirmAlert(row.id)}
          value="Eliminar"
        >
          <i className="material-icons padding-aling-editdelete">delete</i>
        </button>
      ),
    },
  ];

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const empresass = empresas;
  //SubHeader del filtro
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => { }, [contactos]);

  //set elements on edit users
  const setEdit = async (empresas) => {
    handleOpen();
    setNombre_comercial(empresas.nombre_comercial);
    setRazon_social(empresas.razon_social);
    setDireccion(empresas.direccion);
    setIndustria(empresas.industria);
    setWeb(empresas.web);
    setRfc(empresas.rfc);
    setID(empresas.id);
  };
  //limpiar form
  const cleanData = () => {
    setNombre_comercial("");
    setRazon_social("");
    setDireccion("");
    setRfc("");
    setContactos([]);
    setIndustria("");
    setWeb("");
    setID("");
  };

  //CONSULTAR clientes para el FORM
  const fetchContacto = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        constants.api + "auth/contacto?search[value]=",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const result = await response.json();
      let arrayTipo = [];
      for (const key in result.data) {
        arrayTipo.push({
          label: `${result.data[key].nombre} ${result.data[key].apellido}`,
          id: result.data[key].contactos[0].id
        })
      }
      setAutocompleteCliente(arrayTipo);
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  //Crear Empresa
  const fetchEmpresasCreate = async () => {
    if (nombre_comercial == "") {
      setMensage1("Añadir campo Nombre Comercial.");
      setOpenAlert1(true);
      handleClick1({
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");
      var urlencoded = new URLSearchParams();
      contactos.map((item, i) =>
        urlencoded.append(`contacts[add][${i}]`, item.id)
      );
      urlencoded.append("web", web);
      urlencoded.append("nombre_comercial", nombre_comercial);
      urlencoded.append("razon_social", razon_social);
      urlencoded.append("direccion", direccion);
      urlencoded.append("rfc", rfc);
      urlencoded.append("industria", industria);
      const response = await fetch(constants.api + "auth/empresa/store", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
        body: urlencoded
      });
      const result = await response.json();

      //Alerta al crear usuario
      if (result?.success) {
        delayEdit(result.data.id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "La Empresa se ha creado con exito",
          showConfirmButton: false,
          timer: 3000,
        });
        fetchEmpresasRead();
        cleanData();
        handleClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error al ingresar Empresa",
          text: "Intente de nuevo",
        });
        handleClick({
          vertical: "top",
          horizontal: "center",
        });
        handleClose();
      }
    } catch (error) {
      alert("Error al iniciar sesión, intentelo de nuevo", error);
    }
  };

  //OnChanges
  const handleInputNombre_comercial = (event) => {
    setNombre_comercial(event.target.value);
  };

  const handleInputRazon_social = (event) => {
    setRazon_social(event.target.value);
  };

  const handleInputDireccion = (event) => {
    setDireccion(event.target.value);
  };

  const handleInputRfc = (event) => {
    setRfc(event.target.value);
  };

  const handleInputClientes = (event, values) => {
    setContactos(values);
  };

  const handleInputIndustria = (event) => {
    setIndustria(event.target.value);
  };

  const handleInputWeb = (event) => {
    setWeb(event.target.value);
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="main-panel panel-main">
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
          <div className="container-fluid">
            <div className="navbar-wrapper z-index-card mediaContact">
              <a className="navbar-brand" href="">
                SELFISH Control Empresas
              </a>
            </div>
          </div>
        </nav>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card z-index-card">
                  <div className="card-header card-header-warning header-card">
                    <h4 className="card-title inline-style">Empresas</h4>
                    <p className="card-category button-add-user">
                      <Button
                        type="button"
                        className="btn btn-success buttonaddusers"
                        onClick={handleOpen}
                      >
                        <i className="material-icons padding-aling">add</i>
                        Añadir
                      </Button>
                    </p>
                  </div>

                  <div className="card-body table-responsive">
                    <DataTable
                      columns={columns}
                      data={empresass}
                      paginationTotalRows={totalData}
                      paginationServer
                      noDataComponent={
                        loading ? (
                          <Loading />
                        ) : (
                          <p className="sin-datos">
                            No hay datos para mostrar
                          </p>
                        )
                      }
                      paginationPerPage={10}
                      onChangeRowsPerPage={(row) => setRows(row)}
                      onChangePage={(page) => setPage(page)}
                      paginationComponentOptions={{
                        rowsPerPageText: 'Filas por página',
                        rangeSeparatorText: 'de',
                        selectAllRowsItem: true,
                        selectAllRowsItemText: 'Todos',
                      }}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      selectableRows
                      persistTableHead
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="max-height"
      >
        <Box className="boxstyles responsive-box max-height">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Crear Empresas
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-4">
                  <label>Nombre Comercial *</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="nombre_comercial"
                    required
                    className=" tipoUser"
                    name="nombre_comercial"
                    value={nombre_comercial}
                    onChange={handleInputNombre_comercial}
                  />
                </div>

                <div className="col-md-4"></div>
                <div className="col-md-8 ">
                  <div
                    className="btn btn-success proyect-btn"
                    onClick={submmitValidarNombre}
                  >
                    Validar
                  </div>
                </div>

                <div className="col-md-4">
                  <label>Razón Social</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="razon_social"
                    required
                    className=" tipoUser"
                    name="razon_social"
                    value={razon_social}
                    onChange={handleInputRazon_social}
                  />
                </div>

                <div className="col-md-4">
                  <label>Dirección</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="direccion"
                    required
                    className="tipoUser"
                    name="direccion"
                    value={direccion}
                    onChange={handleInputDireccion}
                  />
                </div>

                <div className="col-md-4">
                  <label>Industria</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="industria"
                    required
                    className="tipoUser"
                    name="industria"
                    value={industria}
                    onChange={handleInputIndustria}
                  />
                </div>

                <div className="col-md-4">
                  <label>Web</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="web"
                    required
                    className="tipoUser"
                    name="web"
                    target="_blank"
                    value={web}
                    onChange={handleInputWeb}
                  />
                </div>

                <div className="col-md-4">
                  <label>RFC</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="rfc"
                    required
                    className=" tipoUser"
                    name="rfc"
                    value={rfc}
                    onChange={handleInputRfc}
                  />
                </div>

                <div className="col-md-4">
                  <label>Contactos</label>
                </div>
                <div className="col-md-8">
                  <Autocomplete
                    className="style-roles"
                    multiple
                    id="tags-outlined"
                    onChange={handleInputClientes}
                    options={autocompleteCliente}
                    value={contactos}
                    getOptionLabel={(option) => option.label}
                    filterSelectedOptions
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="standard-basic"
                        variant="standard"
                        label="Contactos"
                        placeholder="Contactos"
                      />
                    )}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <div id="resultadocrear"></div>
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            {empresaID ? (
              <button
                onClick={() => fetchEditEmpresas(empresaID, 1)}
                type="button"
                className="button btn-primary"
                data-dismiss="modal"
                id="actionEditarEmpresa"
              >
                Editar
              </button>
            ) : (
              <button
                onClick={fetchEmpresasCreate}
                type="button"
                className="button btn-primary"
                data-dismiss="modal"
                id="actionCrearEmpresa"
              >
                CREAR
              </button>
            )}
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        message={mensage}
        key={vertical + horizontal}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert1}
        onClose={handleCloseAlert1}
        message={mensage1}
        key={vertical1 + horizontal1}
      />
    </>
  );
};

export default Empresas;

if (document.getElementById("empresas")) {
  ReactDOM.render(<Empresas />, document.getElementById("empresas"));
}
