import React, { useEffect, useState } from "react";
import constants from "../utils/constants";
import SideNav from "./sidenav/SideNav";
import Button from "@mui/material/Button";
import ReactDOM from "react-dom";
import "../sass/assets/css/style.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import Loading from "./Loading";

const Reset = () => {
  //SHOW PASSWORD
  const [shown, setShown] = React.useState(false);
  const switchShown = () => setShown(!shown);

  //SHOW PASSWORD2
  const [shown2, setShown2] = React.useState(false);
  const switchShown2 = () => setShown2(!shown2);

  // Loading
  const [loading, setLoading] = useState(true);

  const changeLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  //UseState para consultar los roles
  const [infousers, setInfousers] = useState("");
  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setPassword] = useState("");
  const [newPasswordconfirm, setPasswordconfirm] = useState("");

  //Consultar INFO DE USUARIO
  const fetchDataUser = async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(constants.api + "auth/profile", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const result = await response.json();

    if (result?.success) {
      setInfousers(result.data);
    } else if (response.status === 401) {
      alert("no autenticado, favor de volverse a ingresar.");
      localStorage.clear();
      window.location.replace("/login");
    } else if (response?.message) {
      alert(response?.message);
    } else {
      alert(result?.error);
    }
  };

  //RESENTpassword
  const resetPass = async () => {
    if (actualPassword == "") {
      alert("Añadir campo Contraseña Actual.");
      return;
    } else if (newPassword == "") {
      alert("Añadir campo Nueva Contraseña");
      return;
    } else if (newPasswordconfirm == "") {
      alert("Añadir campo Confirmar Contraseña");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      if (newPassword === newPasswordconfirm) {
        const response = await fetch(constants.api + "auth/profile/update", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            password: actualPassword,
            new_password: newPassword,
            new_password_confirmation: newPasswordconfirm,
          }),
        });
        const result = await response.json();

        if (result?.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Password actualizado Correctamente",
            showConfirmButton: false,
            timer: 3000,
          });
          handleClose();
        } else {
          Swal.fire({
            icon: "error",
            title: "Ocurrio un error",
            text: "Intente de nuevo",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error",
          text: "Las contraseñas no coinciden",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        fetchDataUser();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);
  useEffect(() => {
    let unmounted = false;
    setTimeout(() => {
      if (!unmounted) {
        changeLoading();
      }
    }, 500);
    return () => {
      unmounted = true;
    };
  }, []);

  const handleInputActualPassword = (event) => {
    setActualPassword(event.target.value);
  };

  const handleInputPassword = (event) => {
    setPassword(event.target.value);
  };

  const handleInputPasswordConfirm = (event) => {
    setPasswordconfirm(event.target.value);
  };

  return (
    <div className="main-panel panel-main">
      <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top z-index-card">
        <div className="container-fluid">
          <div className="navbar-wrapper z-index-card">
            <a className="navbar-brand" href="">
              SELFISH Ajustes
            </a>
          </div>
        </div>
      </nav>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 marggin-top">
            <div className="card">
              <div className="card-header-warning mystyles-header z-index-card">
                <h4 className="card-title inline-style">
                  Informacón de Usuario
                </h4>
                <p className="card-category button-add-user newSizeP">
                  <Button
                    type="button"
                    className="btn btn-success buttonaddusers newSize"
                    onClick={handleOpen}
                  >
                    Cambiar Contraseña
                  </Button>
                </p>
              </div>

              <div className="card-body table-responsive">
                {loading ? (
                  <div className="loading-style col-auto">
                    <Loading />
                  </div>
                ) : (
                  <table className="table table-hover">
                    <thead className="center">
                      <tr>
                        <th>Nombre</th>
                        <th>Usuario</th>
                        <th>E-mail</th>
                        <th>Teléfono Móvil</th>
                        <th>Rol</th>
                      </tr>
                    </thead>
                    <tbody id="resultado">
                      <tr key={infousers.id}>
                        <td>
                          {infousers?.person?.nombre}
                          {infousers?.person?.apellido}
                        </td>
                        <td>{infousers?.username}</td>
                        <td>{infousers?.person?.email}</td>
                        <td>{infousers?.person?.telefono_movil}</td>
                        <td>{infousers?.role?.name}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>

              <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box className="boxstyles responsive-box">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Cambiar Contraseña
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-md-3 ">
                          <label>Contraseña Actual</label>
                        </div>
                        <div className="col-md-6 sinpadding">
                          <input
                            value={actualPassword}
                            type={shown ? "text" : "password"}
                            id="password"
                            required
                            className=" tipoUser1 style-precio "
                            name="password"
                            onChange={handleInputActualPassword}
                          />
                        </div>
                        <div className="col-md-3 sinpadding-left">
                          <button
                            className="seePassword"
                            onClick={switchShown}
                          >
                            {shown ? (
                              <i className="material-icons padding-aling1">
                                visibility_off
                              </i>
                            ) : (
                              <i className="material-icons padding-aling1">
                                visibility
                              </i>
                            )}
                          </button>
                        </div>

                        <div className="col-md-3 ">
                          <label>Nueva Contraseña</label>
                        </div>
                        <div className="col-md-6 sinpadding">
                          <input
                            value={newPassword}
                            type={shown2 ? "text" : "password"}
                            id="newpassword"
                            required
                            className=" tipoUser1 style-precio "
                            name="password"
                            onChange={handleInputPassword}
                          />
                        </div>
                        <div className="col-md-3 sinpadding-left">
                          <button
                            className="seePassword"
                            onClick={switchShown2}
                          >
                            {shown2 ? (
                              <i className="material-icons">visibility_off</i>
                            ) : (
                              <i className="material-icons padding-aling1">
                                visibility
                              </i>
                            )}
                          </button>
                        </div>

                        <div className="col-md-3">
                          <label>Confirmar Contraseña</label>
                        </div>
                        <div className="col-md-6 sinpadding">
                          <input
                            value={newPasswordconfirm}
                            id="password_confirmation"
                            required
                            className=" tipoUser1 style-precio "
                            name="password_confirmation"
                            onChange={handleInputPasswordConfirm}
                          />
                        </div>

                        <div></div>
                      </div>
                    </form>
                  </div>

                  <div className="modal-footer">
                    <div id="resultadocrear"></div>
                    <button
                      onClick={handleClose}
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>

                    <button
                      onClick={resetPass}
                      type="button"
                      className="button btn-primary"
                      data-dismiss="modal"
                      id="actionCrearUsuario"
                    >
                      Cambiar
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;

if (document.getElementById("reset")) {
  ReactDOM.render(<Reset />, document.getElementById("reset"));
}
