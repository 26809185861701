import { useDeleteContact, useGetContacts } from "../../hooks/contacts"
import { Button } from "../core/button/Button"
import Card from "../core/ui/Card"
import Search from "../core/ui/Search"
import Row from "./../core/layout/Row"
import Table from "../core/ui/Table"
import { IconButton } from "../core/button"
import { useState } from "react"
import CreateContact from "./CreateContact"
import EditContact from "./EditContact"
import notification from "../../utils/notification"
import { format } from "date-fns"
import { getMutationOptions } from "../../utils/functions"
import { useQueryClient } from "@tanstack/react-query"


const Contacts = () => {

	const [modal, setModal] = useState(false)
	const [searchValue, setSearchValue] = useState({ searchValue: '' })
	const [editModal, setEditModal] = useState({ showing: false, contact: null })
	const queryClient = useQueryClient()
	const contactsQuery = useGetContacts(searchValue)
	const deleteContact = useDeleteContact(getMutationOptions({
		loadingMessage: 'Eliminando Contacto',
		successMessage: 'Contacto Eliminado',
		errorMessage: 'Error al Eliminar el Contacto'
	}))


	const contactsColumns = [
		{ header: 'Nombre', accessorFn: ({ nombre, apellido }) => `${nombre} ${apellido}` },
		{ header: 'Fecha de Inicio', accessorFn: ({ fecha_nacimiento }) => format(new Date(fecha_nacimiento), 'dd-MM-y') },
		{ header: 'Tipo', accessorFn: ({ contactos }) => contactos[0].tipo },
		{
			header: 'Contacto en las Empresas',
			accessorFn: ({ empresas }) => empresas,
			cell: empresas => <ul>
				{empresas.getValue().map(empresa => <li key={empresa.id}>
					{empresa.nombre_comercial}
				</li>)}
			</ul>
		},
		{
			header: 'Acciones',
			accessorFn: row => row,
			cell: ({ row: { index }, getValue }) => {
				const contact = getValue()
				return <>
					<IconButton icon="edit" accion={() => setEditModal({ showing: true, contact })} />
					<IconButton icon="delete" accion={() => notification.confirm(
						`¿Estás seguro de eliminar a ${contact.nombre}?`,
						() => {
							deleteContact.mutate(contact.contactos[0].id, {
								onSuccess: () => {
									// Update current query
									queryClient.setQueryData(['contacts', searchValue], oldData => {
										const dataCopy = [...oldData]
										dataCopy.splice(index, 1)
										return dataCopy
									})

									// Update left queries
									queryClient.refetchQueries(['contacts'], { type: 'inactive' })
								}
							})
						}
					)} />
				</>
			}
		}
	]


	return (
		<>
			<div id="contacts--sf">
				<Row levelGap={2}>
					<Card>
						<Row columns={2}>
							<div>
								<Button texto="Agregar Contacto" accion={() => setModal(true)} />
							</div>

							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Search
									name="searchValue"
									label="Buscar:"
									placeholder="Nombre o Empresa"
									submit={setSearchValue}
								/>
							</div>
						</Row>
					</Card>

					<Card>
						<Table
							columns={contactsColumns}
							data={contactsQuery.data || []}
							loading={contactsQuery.isLoading}
							pageSize={25}
						/>
					</Card>
				</Row>
			</div>

			<CreateContact {...{ modal, setModal, searchValue }} />

			<EditContact
				modal={editModal.showing}
				setModal={setEditModal}
				contact={editModal.contact}
				searchValue={searchValue}
			/>
		</>
	)

}

export default Contacts