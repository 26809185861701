import { NavLink } from 'react-router-dom'
import './sidenav.css'

const SideNav = () => {

  const logout = () => localStorage.clear()

  const loggedUserData = JSON.parse(localStorage.getItem('data'))
  const models = loggedUserData.privileges
  const typeUser = loggedUserData.role.type
  const userRole = loggedUserData.role.name
  const userName = loggedUserData.person.nombre

  const privileges = models.reduce((privileges, privilege) => {
    const clave = privilege.model.split('\\').pop()
    return {
      ...privileges,
      [clave]: privilege.read === 1 ? true : false
    }
  }, {})

  return (
    <nav className="side-nav--sf">
      <img src="/assets/Selfish-logo.svg" alt="Logo" />

      <div className="info">
        <p>{userName}</p>
        <span>{userRole}</span>
      </div>

      <ul>
        {typeUser === 'admin' ? (
          <li>
            <NavLink to="/dashboard" className="nav-link">
              <i className="material-icons">dashboard</i>
              <p>Dashboard</p>
            </NavLink>
          </li>
        ) : null}

        {privileges.User ? (
          <li>
            <NavLink to="/usuarios" className="nav-link">
              <i className="material-icons">people</i>
              <p>Usuarios</p>
            </NavLink>
          </li>
        ) : null}

        {privileges.Contacto ? (
          <li>
            <NavLink to="/contactos" className="nav-link">
              <i className="material-icons">contacts</i>
              <p>Contactos</p>
            </NavLink>
          </li>
        ) : null}

        {privileges.Proyecto ? (
          <li>
            <NavLink to="/proyectos" className="nav-link">
              <i className="material-icons">business_center</i>
              <p>Proyectos</p>
            </NavLink>
          </li>
        ) : null}

        {privileges.Contacto ? (
          <li>
            <NavLink to="/empresas" className="nav-link">
              <i className="material-icons">work</i>
              <p>Empresas</p>
            </NavLink>
          </li>
        ) : null}

        {privileges.Role ? (
          <li>
            <NavLink to="/roles" className="nav-link">
              <i className="material-icons">assignment</i>
              <p>Roles</p>
            </NavLink>
          </li>
        ) : null}

        {privileges.Renovacion ? (
          <li>
            <NavLink to="/renovaciones" className="nav-link">
              <i className="material-icons">autorenew</i>
              <p>Renovaciones</p>
            </NavLink>
          </li>
        ) : null}

        {privileges.Parametro ? (
          <li>
            <NavLink to="/parametros" className="nav-link">
              <i className="material-icons">view_list</i>
              <p>Parámetros</p>
            </NavLink>
          </li>
        ) : null}

        {privileges.Operacion ? (
          <li>
            <NavLink to="/estadistica" className="nav-link">
              <i className="material-icons">equalizer</i>
              <p>Estadística</p>
            </NavLink>
          </li>
        ) : null}

        {privileges.Operacion ? (
          <li>
            <NavLink to="/operaciones" className="nav-link">
              <i className="material-icons">construction</i>
              <p>Operaciones</p>
            </NavLink>
          </li>
        ) : null}

        <li>
          <NavLink to="/reset" className="nav-link">
            <i className="material-icons">settings</i>
            <p>Ajustes</p>
          </NavLink>
        </li>
        <li>
          <NavLink to="/login" className="nav-link" onClick={logout}>
            <i className="material-icons">bubble_chart</i>
            <p>Cerrar Sesión</p>
          </NavLink>
        </li>
      </ul>
    </nav>
  );

}

export default SideNav