import { useState } from "react"
import { useDeleteRevenue } from "../../hooks/operation"
import Row from "../core/layout/Row"
import Card from "../core/ui/Card"
import Search from "../core/ui/Search"
import { format } from "date-fns"
import { currencyFormat } from "../../utils/format"
import { IconButton } from "../core/button"
import notification from "../../utils/notification"
import EditRevenue from "./EditRevenue"
import ControlledTable from "../core/ui/ControlledTable"
import { useGetRevenue } from "../../hooks/operation"
import { getMutationOptions } from "../../utils/functions"
import { useQueryClient } from "@tanstack/react-query"
import { useStatisticFilters } from "../../store/statisticStore"
import { shallow } from "zustand/shallow"


const RevenueList = () => {

	const deleteRevenue = useDeleteRevenue(getMutationOptions({
		loadingMessage: 'Eliminando Ingreso',
		successMessage: 'Ingreso Eliminado Correctamente',
		errorMessage: 'Error al Eliminar Ingreso'
	}))
	const globalFilters = useStatisticFilters(state => ({
		year: state.year,
		month: state.month,
		revenueTypeId: state.revenueTypeId
	}), shallow)
	const [searchValue, setSearchValue] = useState('')
	const [modal, setModal] = useState({ isOpen: false, revenue: null })
	const queryClient = useQueryClient()

	const revenueColumns = [
		{ header: 'Emisor', accessorKey: 'emisor.nombre_comercial' },
		{ header: 'Fecha', accessorFn: ({ fecha_movimiento }) => format(new Date(fecha_movimiento), 'dd-MM-y') },
		{ header: 'Tipo', accessorKey: 'tipo.valor' },
		{ header: 'Comentarios', accessorKey: 'comentarios' },
		{ header: 'Monto', accessorFn: ({ total }) => currencyFormat(total) },
		{ header: 'IVA', accessorFn: ({ iva }) => currencyFormat(iva) },
		{
			header: 'Acciones', accessorFn: row => row, cell: ({ getValue }) => {
				const revenue = getValue()
				return <>
					<IconButton icon="edit" accion={() => setModal({ isOpen: true, revenue })} />
					<IconButton icon="delete" accion={() => notification.confirm(
						`¿Estás seguro de eliminar el ingreso de ${revenue.emisor.nombre_comercial}?`,
						() => {
							deleteRevenue.mutate(revenue.id, {
								onSuccess: () => {
									// Update active query
									queryClient.refetchQueries(['revenue'], { type: 'active' })
								}
							})
						}
					)} />
				</>
			}
		}
	]

	return (
		<>
			<Card title="Lista de Ingresos">
				<Row>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Search
							label="Buscar:"
							name="value"
							placeholder="Nombre del Emisor"
							submit={({ value }) => setSearchValue(value)}
						/>
					</div>
				</Row>

				<ControlledTable
					columns={revenueColumns}
					useQueryFetch={useGetRevenue}
					customPageSize={25}
					filters={{ ...globalFilters, searchValue }}
				/>
			</Card>

			<EditRevenue
				modal={modal.isOpen}
				setModal={setModal}
				revenue={modal.revenue}
			/>
		</>

	)

}

export default RevenueList