import { Field } from 'formik'
import './checkbox.css'
import _uniqueId from 'lodash/uniqueId'

const CheckBox = ({ name, label, readonly, horizontal }) => {

  const id = _uniqueId('sf-id-')

  return (
    <div className={`checkbox--sf ${horizontal ? 'horizontal' : ''}`}>
      <label>{label}</label>
      <Field
        type="checkbox"
        id={id}
        readOnly={readonly}
        name={name}
      />
      <label htmlFor={id} />
    </div>
  )

}

export default CheckBox