import { axiosInstance } from '../utils/instances'
import { useMutation, useQuery } from '@tanstack/react-query'


const queryOptions = {
	refetchOnWindowFocus: false,
	staleTime: 1000 * 60 * 60,
}

/* API requests */
const getContacts = async ({ searchValue }) => {
	const { data: { data } } = await axiosInstance.post('/auth/contacto', {
		'columns[0][name]': 'id',
		'order[0][column]': '0',
		'order[0][dir]': 'desc',
		'search[value]': searchValue || ''
	})
	return data
}

const createContact = async contact => {
	const { data: { data } } = await axiosInstance.post('/auth/contacto/store', contact)
	return data
}

const deleteContact = async contactId => {
	const { data: { success } } = await axiosInstance.delete(`/auth/contacto/${contactId}`)
	return success
}

const editContact = async (contactId, data) => {
	const { data: { success } } = await axiosInstance.put(`/auth/contacto/${contactId}`, data)
	return success
}


/* Contacts custom hooks */
export const useGetContacts = searchValue => {
	return useQuery(
		['contacts', { ...searchValue }],
		() => getContacts(searchValue),
		queryOptions
	)
}

export const useCreateContact = (options = {}) => {
	return useMutation(
		['createContact'],
		createContact,
		options
	)
}

export const useDeleteContact = (options = {}) => {
	return useMutation(
		['deleteContact'],
		deleteContact,
		options
	)
}

export const useEditContact = (options = {}) => {
	return useMutation(
		['editContact'],
		async data => await editContact(data.contactId, data.data),
		options
	)
}
