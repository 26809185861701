const base = process.env.NODE_ENV === 'development' ? 'https://old-api.selfish.com.mx/' : 'https://old-api.selfish.com.mx/'

export default {
	api: base + 'v1/'
}

export const chartColours = [
	'#DECC8D',
	'#DEA78D',
	'#C7DE8D',
	'#8DC7DE',
	'#A48DDE'
]

export const months = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre'
]

export const queryOptions = {
	refetchOnWindowFocus: false,
	staleTime: 1000 * 60 * 60,
	placeholderData: []
}