import { Form, Formik } from 'formik'
import Card from '../core/ui/Card'
import { Select } from '../core/select'
import { TextArea } from '../core/textarea'
import { Input } from '../core/input'
import { Button } from '../core/button'
import { useCompanies } from '../../hooks/company'
import { useCreateExpenditure, useCreateRevenue } from '../../hooks/operation'
import { useExpenditureTypes, usePaymentMethods, useRevenueTypes } from '../../hooks/parameters'
import CheckBox from '../core/checkbox/CheckBox'
import { useQueryClient } from '@tanstack/react-query'
import { getMutationOptions } from '../../utils/functions'


const CreationForms = () => {
  const companiesQuery = useCompanies()
  const expenditureTypesQuery = useExpenditureTypes()
  const paymentMethodsQuery = usePaymentMethods()
  const revenueTypesQuery = useRevenueTypes()
  const queryClient = useQueryClient()

  const createRevenue = useCreateRevenue(getMutationOptions({
    loadingMessage: 'Registrando Nuevo Ingreso',
    successMessage: 'Nuevo Ingreso Registrado',
    errorMessage: 'Error al Registrar el Ingreso'
  }))

  const createExpenditure = useCreateExpenditure(getMutationOptions({
    loadingMessage: 'Registrando Nuevo Egreso',
    successMessage: 'Nuevo Egreso Registrado',
    errorMessage: 'Error al Registrar el Egreso'
  }))


  const saveRevenue = async (revenue, { resetForm }) => {
    createRevenue.mutate(revenue, {
      onSuccess: () => {
        resetForm()

        // Update recent revenue
        queryClient.invalidateQueries(['recentRevenue'])

        // Update revenue balance
        queryClient.invalidateQueries(['dateRevenue'])
      }
    })
  }

  const saveExpenditure = async (expenditure, { resetForm }) => {
    createExpenditure.mutate(expenditure, {
      onSuccess: () => {
        resetForm()

        // Update recent expenditure
        queryClient.invalidateQueries(['recentExpenditure'])

        // Update expenditure balance
        queryClient.invalidateQueries(['dateExpenditure'])
      }
    })
  }

  return (
    <>
      <Card title="Nuevo Ingreso">
        <Formik
          initialValues={{
            emisor_id: '',
            tipo_id: '',
            metodo_id: '',
            comentarios: '',
            monto: '',
            fecha_movimiento: '',
            aplica_iva: false
          }}
          onSubmit={saveRevenue}
        >
          <Form>
            <Select
              label="Emisor"
              name="emisor_id"
              horizontal
              required
            >
              <option value="">Seleccione Emisor</option>
              {
                companiesQuery.data.map(company => (
                  <option key={company.id} value={company.id}>{company.name}</option>
                ))
              }
            </Select>

            <Select
              label="Tipo"
              name="tipo_id"
              horizontal
              required
            >
              <option value="">Seleccione Tipo</option>
              {
                revenueTypesQuery.data.map(type => (
                  <option key={type.id} value={type.id}>{type.value}</option>
                ))
              }
            </Select>

            <Select
              label="Método"
              name="metodo_id"
              horizontal
              required
            >
              <option value="">Seleccione Método</option>
              {
                paymentMethodsQuery.data.map(method => (
                  <option key={method.id} value={method.id}>{method.value}</option>
                ))
              }
            </Select>

            <TextArea
              label="Comentarios"
              name="comentarios"
              placeholder="Escribe tus comentarios"
              horizontal
            />

            <Input
              label="Monto"
              name="monto"
              type="number"
              placeholder="Monto del ingreso"
              horizontal
              required
            />

            <Input
              label="Fecha"
              name="fecha_movimiento"
              type="date"
              placeholder="Fecha"
              horizontal
              required
            />

            <CheckBox
              label="IVA"
              name="aplica_iva"
              horizontal
            />

            <Button texto="Agregar" formulario disabled={createRevenue.isLoading} />
          </Form>
        </Formik>
      </Card>

      <Card title="Nuevo Egreso">
        <Formik
          initialValues={{
            receptor_id: '',
            tipo_id: '',
            metodo_id: '',
            comentarios: '',
            monto: '',
            fecha_movimiento: '',
            aplica_iva: false
          }}
          onSubmit={saveExpenditure}
        >
          <Form>
            <Select
              label="Receptor"
              name="receptor_id"
              horizontal
              required
            >
              <option value="">Seleccione Receptor</option>
              {
                companiesQuery.data.map(company => (
                  <option key={company.id} value={company.id}>{company.name}</option>
                ))
              }
            </Select>

            <Select
              label="Tipo"
              name="tipo_id"
              horizontal
              required
            >
              <option value="">Seleccione Tipo</option>
              {
                expenditureTypesQuery.data.map(type => (
                  <option key={type.id} value={type.id}>{type.value}</option>
                ))
              }
            </Select>

            <Select
              label="Método"
              name="metodo_id"
              horizontal
              required
            >
              <option value="">Seleccione Método</option>
              {
                paymentMethodsQuery.data.map(method => (
                  <option key={method.id} value={method.id}>{method.value}</option>
                ))
              }
            </Select>

            <TextArea
              label="Comentarios"
              name="comentarios"
              placeholder="Escribe tus comentarios"
              horizontal
            />

            <Input
              label="Monto"
              name="monto"
              type="number"
              placeholder="Monto del egreso"
              horizontal
              required
            />

            <Input
              label="Fecha"
              name="fecha_movimiento"
              type="date"
              placeholder="Fecha"
              horizontal
              required
            />

            <CheckBox
              label="IVA"
              name="aplica_iva"
              horizontal
            />

            <Button texto="Agregar" formulario disabled={createExpenditure.isLoading} />
          </Form>
        </Formik>
      </Card>
    </>
  )

}

export default CreationForms