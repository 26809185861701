import Modal from "../core/ui/Modal"
import { Form, Formik } from "formik"
import { Input } from "../core/input"
import { Select } from "./../core/select"
import { Button } from "../core/button/Button"
import { useCreateContact } from "../../hooks/contacts"
import { getMutationOptions } from "../../utils/functions"
import { useQueryClient } from "@tanstack/react-query"

const CreateContact = ({ modal, setModal, searchValue }) => {

	const queryClient = useQueryClient()
	const createContact = useCreateContact(getMutationOptions({
		loadingMessage: 'Registrando Nuevo Contacto',
		successMessage: 'Nuevo Contacto Registrado',
		errorMessage: 'Error al Registrar el Contacto'
	}))

	const saveContact = (data, { resetForm }) => {
		createContact.mutate(data, {
			onSuccess: contactCreated => {
				resetForm()

				// Update current query
				queryClient.setQueryData(['contacts', searchValue], oldData => [contactCreated, ...oldData])

				// Update left queries
				queryClient.refetchQueries(['contacts'], { type: 'inactive' })
			}
		})
	}

	return (
		<Modal
			open={modal}
			setModal={setModal}
			title="Agregar Contacto"
		>
			<Formik
				initialValues={{
					nombre: '',
					apellido: '',
					telefono_movil: '',
					email: '',
					fecha_nacimiento: '',
					tipo: ''
				}}
				onSubmit={saveContact}
			>
				<Form>
					<Input
						label="Nombre"
						name="nombre"
						placeholder="Nombre del Contacto"
						horizontal
						required
					/>
					<Input
						label="Apellidos"
						name="apellido"
						placeholder="Apellidos del Contacto"
						horizontal
						required
					/>
					<Input
						label="Teléfono"
						name="telefono_movil"
						type="number"
						placeholder="Teléfono de Contacto"
						horizontal
						required
					/>
					<Input
						label="Correo"
						name="email"
						type="email"
						placeholder="Correo del Contacto"
						horizontal
						required
					/>
					<Input
						label="Fecha de Inicio"
						name="fecha_nacimiento"
						type="date"
						horizontal
						required
					/>
					<Select
						label="Tipo"
						name="tipo"
						horizontal
						required
					>
						<option value="">Tipo de Contacto</option>
						<option value="Proveedor">Proveedor</option>
						<option value="Cliente">Cliente</option>
						<option value="Otro">Otro</option>
					</Select>

					<Button
						formulario
						texto="Registrar Contacto"
						disabled={createContact.isLoading}
						estilo={{ margin: '40px auto 0px auto' }}
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default CreateContact