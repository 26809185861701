const Circle = ({ position }) => {

  return (
    <div className="circle" style={position}>
      <div className="banner-circle"></div>
    </div>
  )

}

export default Circle