import './table.css'
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { IconButton } from '../button'

const Table = ({
  columns,
  data,
  loading,
  pageSize,
  resultsInfo = true
}) => {

  const table = useReactTable({
    data,
    columns,
    initialState: { pagination: { pageSize } },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  return (
    <div className='table--sf'>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {
                    header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )
                  }
                </th>
              ))}
            </tr>
          ))}

        </thead>

        <tbody>
          {loading ? (
            <tr>
              <td className='loading' colSpan={1000}>
                <span className="loader"></span>
              </td>
            </tr>
          ) : (
            <>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          )}

          {resultsInfo ? (
            <tr className='pagination--sf'>
              <td colSpan={1000}>
                <div>
                  <p>Mostrando {table.getRowModel().rows.length} de {data.length} filas</p>
                  <p>Página {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}</p>
                  <EnterInput
                    initialValue={table.getState().pagination.pageIndex + 1}
                    onEnter={page => table.setPageIndex(page - 1)}
                    disabled={loading}
                    min="1"
                    step="1"
                    max={table.getPageCount()}
                    className="input-pagination--sf"
                  />
                  <IconButton
                    accion={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage() || loading}
                    icon="keyboard_double_arrow_left"
                  />
                  <IconButton
                    accion={table.previousPage}
                    disabled={!table.getCanPreviousPage() || loading}
                    icon="keyboard_arrow_left"
                  />
                  <IconButton
                    accion={table.nextPage}
                    disabled={!table.getCanNextPage() || loading}
                    icon="keyboard_arrow_right"
                  />
                  <IconButton
                    accion={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage() || loading}
                    icon="keyboard_double_arrow_right"
                  />
                </div>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  )

}


const EnterInput = ({ initialValue, onEnter, ...props }) => {
  const [value, setValue] = useState(initialValue)

  const handleKeyPress = (event) => {
    if (event.key === 'Enter')
      onEnter(value)
  }

  const handleChange = (event) => setValue(event.target.value)

  useEffect(() => setValue(initialValue), [initialValue])

  return (
    <input
      type="number"
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      {...props}
    />
  )
}


export default Table