import { Navigate, Route, Routes } from "react-router-dom"
import Login from "./components/login/Login"
import Usuarios from "./components/Usuarios"
import Proyectos from "./components/Proyectos"
import Renovaciones from "./components/Renovaciones"
import Empresas from "./components/Empresas"
import People from "./components/People"
import Roles from "./components/Roles"
import Parametros from "./components/Parametros"
import DetailProjects from "./components/DetailProjects"
import Module from "./components/Module"
import Privilege from "./components/Privilege"
import DetailRenovaciones from "./components/DetailRenovaciones"
import Reset from "./components/Reset"
import Forgot from "./components/Forgot"
import Correopassword from "./components/Correopassword"
import Tokenpassword from "./components/Tokenpassword"
import ProtectedRoute from "./components/ProtectedRoute"
// Toastify styles
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from "react-toastify"
import Dashboard from "./components/dashboard/Dashboard"
import Contacts from "./components/contacts/Contacts"
import Statistic from "./components/statistic/Statistic"


const App = () => {

  return (
    <>
      <Routes>
        {/* Access without token */}
        <Route path="/login" element={
          <ProtectedRoute redirectTo="/dashboard">
            <Login />
          </ProtectedRoute>
        } />
        <Route path="/correopassword" element={
          <ProtectedRoute redirectTo="/dashboard">
            <Correopassword />
          </ProtectedRoute>
        } />
        <Route path="/tokenpassword" element={
          <ProtectedRoute redirectTo="/dashboard">
            <Tokenpassword />
          </ProtectedRoute>
        } />
        <Route path="/forgotPassword" element={
          <ProtectedRoute redirectTo="/dashboard">
            <Forgot />
          </ProtectedRoute>
        } />


        {/* Access with token */}
        <Route path="/contactos" element={
          <ProtectedRoute withToken header="Contactos">
            <Contacts />
          </ProtectedRoute>
        } />

        <Route path="/usuarios" element={
          <ProtectedRoute withToken>
            <Usuarios />
          </ProtectedRoute>
        } />

        <Route path="/renovaciones" element={
          <ProtectedRoute withToken>
            <Renovaciones />
          </ProtectedRoute>
        } />

        <Route path="/proyectos" element={
          <ProtectedRoute withToken>
            <Proyectos />
          </ProtectedRoute>
        } />

        <Route path="/empresas" element={
          <ProtectedRoute withToken>
            <Empresas />
          </ProtectedRoute>
        } />

        <Route path="/people" element={
          <ProtectedRoute withToken>
            <People />
          </ProtectedRoute>
        } />

        <Route path="/roles" element={
          <ProtectedRoute withToken>
            <Roles />
          </ProtectedRoute>
        } />

        <Route path="/parametros" element={
          <ProtectedRoute withToken>
            <Parametros />
          </ProtectedRoute>
        } />

        <Route path="/module" element={
          <ProtectedRoute withToken>
            <Module />
          </ProtectedRoute>
        } />

        <Route path="/estadistica" element={
          <ProtectedRoute withToken header="Estadística">
            <Statistic />
          </ProtectedRoute>
        } />

        <Route path="/privilege" element={
          <ProtectedRoute withToken>
            <Privilege />
          </ProtectedRoute>
        } />

        <Route path="/detailProjects/:proyect_id" element={
          <ProtectedRoute withToken>
            <DetailProjects />
          </ProtectedRoute>
        } />

        <Route path="/detailRenovaciones/:renovaciones_id" element={
          <ProtectedRoute withToken>
            <DetailRenovaciones />
          </ProtectedRoute>
        } />

        <Route path="/reset" element={
          <ProtectedRoute withToken>
            <Reset />
          </ProtectedRoute>
        } />

        <Route path="/dashboard" element={
          <ProtectedRoute withToken header="Dashboard">
            <Dashboard />
          </ProtectedRoute>
        } />

        <Route path="/*" element={<Navigate to="/dashboard" />} />
      </Routes>
      <ToastContainer />
    </>
  )
}

export default App