import Modal from "../core/ui/Modal"
import { Form, Formik } from "formik"
import { Input } from "../core/input"
import { Select } from "../core/select"
import { Button } from "../core/button/Button"
import { useEditContact } from "../../hooks/contacts"
import { format } from "date-fns"
import { useQueryClient } from "@tanstack/react-query"
import { getMutationOptions } from "../../utils/functions"

const EditContact = ({ modal, setModal, contact, searchValue }) => {

	const queryClient = useQueryClient()
	const editContact = useEditContact(getMutationOptions({
		loadingMessage: 'Modificando Contacto',
		successMessage: 'Información de Contacto Modificada',
		errorMessage: 'Error al Modificar Información'
	}))

	const saveContact = data => {
		editContact.mutate({ contactId: contact?.contactos[0].id, data }, {
			onSuccess: () => {
				queryClient.setQueryData(['contacts', searchValue], oldData => {
					const copyData = [...oldData]
					const index = copyData.findIndex(queryContact => queryContact.contactos[0].id === contact.contactos[0].id)
					copyData[index] = {
						...copyData[index],
						...data
					}
					return copyData
				})

				// Update left queries
				queryClient.refetchQueries(['contacts'], { type: 'inactive' })

				setModal(false)
			}
		})
	}

	return (
		<Modal
			open={modal}
			setModal={setModal}
			title="Editar Contacto"
		>
			<Formik
				initialValues={{
					nombre: contact?.nombre,
					apellido: contact?.apellido,
					fecha_nacimiento: format(new Date(contact?.fecha_nacimiento || '2000-12-25'), 'y-MM-dd'),
					tipo: contact?.contactos[0].tipo
				}}
				onSubmit={saveContact}
			>
				<Form>
					<Input
						label="Nombre"
						name="nombre"
						placeholder="Nombre del Contacto"
						horizontal
						required
					/>
					<Input
						label="Apellidos"
						name="apellido"
						placeholder="Apellidos del Contacto"
						horizontal
						required
					/>
					<Input
						label="Fecha de Inicio"
						name="fecha_nacimiento"
						type="date"
						horizontal
						required
					/>
					<Select
						label="Tipo"
						name="tipo"
						horizontal
						required
					>
						<option value="">Tipo de Contacto</option>
						<option value="Proveedor">Proveedor</option>
						<option value="Cliente">Cliente</option>
						<option value="Otro">Otro</option>
					</Select>

					<Button
						formulario
						texto="Guardar Cambios"
						disabled={editContact.isLoading}
						estilo={{ margin: '40px auto 0px auto' }}
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default EditContact