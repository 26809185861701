import React, { useState } from "react";
import ReactDOM from "react-dom";
import Card from "@mui/material/Card";
import constants from "../utils/constants";
import "../sass/assets/css/style.css";
import logoSelfish from "../images/logoSelfish.png";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Box from "@mui/material/Box";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "Black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});
const Forgot = () => {
  //SHOW PASSWORD
  const [shown, setShown] = React.useState(false);
  const switchShown = () => setShown(!shown);

  /*   const handleInputUser = (event) => {
    setUser(event.target.value);
  };
  const handleInputPass = (event) => {
    setPassword(event.target.value);
  }; */

  return (
    <div>
      <Card className="login1">
        <div className="row-width">
          <div className="">
            <img className="logoLogin" src={logoSelfish} alt="logo" />
          </div>
        </div>

        <div className="logIN">
          <span className="correotoken">Cambiar Contraseña</span>

          <div className="row">
            <div className="col-md-12">
              <p className="correotokentext">Ingrese una nueva contraseña.</p>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <button className="seePassword" onClick={switchShown}>
                  {shown ? (
                    <i className="material-icons padding-aling">
                      visibility_off
                    </i>
                  ) : (
                    <i className="material-icons padding-aling">visibility</i>
                  )}
                </button>
                <CssTextField
                  className="xgrand dark"
                  id="standard-password-input"
                  label="Nueva contraseña"
                  type={shown ? "text" : "password"}
                  variant="standard"
                  /* onChange={handleInputPass}
                  value={password} */
                  name="password"
                />
              </Box>
            </div>

            <div className="col-md-12">
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <button className="seePassword" onClick={switchShown}>
                  {shown ? (
                    <i className="material-icons padding-aling">
                      visibility_off
                    </i>
                  ) : (
                    <i className="material-icons padding-aling">visibility</i>
                  )}
                </button>
                <CssTextField
                  className="xgrand dark"
                  id="standard-password-input"
                  label="Confirmar Contraseña"
                  type={shown ? "text" : "password"}
                  variant="standard"
                  /* onChange={handleInputPass}
                  value={password} */
                  name="password"
                />
              </Box>
            </div>

            <div className="col-md-1"></div>

            <button className="boton-login" /* onClick={submit} */>
              Confirmar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Forgot;

if (document.getElementById("forgot")) {
  ReactDOM.render(<Forgot />, document.getElementById("forgot"));
}
