import React, { useState } from "react";
import ReactDOM from "react-dom";
import Card from "@mui/material/Card";
import constants from "../utils/constants";
import "../sass/assets/css/style.css";
import logoSelfish from "../images/logoSelfish.png";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "Black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});
const Tokenpassword = () => {


  //SHOW PASSWORD
  const [shown, setShown] = React.useState(false);
  const switchShown = () => setShown(!shown);

/*   const handleInputUser = (event) => {
    setUser(event.target.value);
  };
  const handleInputPass = (event) => {
    setPassword(event.target.value);
  }; */

  const [token, setToken] = useState("");


  const resetPass = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch("https://api.selfish.com.mx/v1/reset", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          token: token,
        }),
      });
      const result = await response.json();
      if (result?.success) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Token enviado Correctamente",
          showConfirmButton: false,
          timer: 3000,
        });
        window.location.replace("/forgotPassword");
      } else {
        Swal.fire({
          icon: "error",
          title: "Ocurrio un error",
          text: "Intente de nuevo",
        });
      }
    } catch (error) {
      alert("Error en el servidor, intentelo de nuevo", error);
    }
  };

  const handleInputToken = (event) => {
    setToken(event.target.value);
  };

  return (
    <div>
      <Card className="login1">
        <div className="row-width">
          <div className="">
            <img className="logoLogin" src={logoSelfish} alt="logo" />
          </div>
        </div>

        <div className="logIN">
          <span className="correotoken">Ingresa el código de seguridad.</span>

          <div className="row">
            <div className="col-md-12">
              <p className="correotokentext">
              Comprueba si recibiste un correo electrónico con tu código.
              </p>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <CssTextField
                  className="xgrand dark"
                  id="standard-password-input"
                  label="Ingresa el código"
                  type={"text"}
                  variant="standard"
                  onChange={handleInputToken}
                  value={token}
                  name="token"
                />
              </Box>
            </div>

            <button className="boton-login"  onClick={resetPass} >
              Enviar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Tokenpassword;

if (document.getElementById("tokenpassword")) {
  ReactDOM.render(<Tokenpassword />, document.getElementById("tokenpassword"));
}
